import { defineMessages } from 'react-intl';

const messages = defineMessages({
  alfrescoListBlock: {
    id: 'AfrescoList',
    defaultMessage: 'Lista de Diretórios e Documentos',
  },
  nodeId: {
    id: 'nodeId',
    defaultMessage: 'Id Diretório',
  },
  text: {
    id: 'Text',
    defaultMessage: 'Título',
  },
  order: {
    id: 'Order',
    defaultMessage: 'Ordenar',
  },
});

export const alfrescoListSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.alfrescoListBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['nodeId', 'text', 'order'],
      },
    ],
    properties: {
      nodeId: {
        title: props.intl.formatMessage(messages.nodeId),
      },
      text: {
        title: props.intl.formatMessage(messages.text),
      },
      order: {
        title: props.intl.formatMessage(messages.order),
      },
    },
    required: ['nodeId'],
  };
};
