import { useState, useEffect } from 'react';

const useAcceptedCookieBar = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const acceptedCookieBar = !!localStorage?.getItem('AcceptedCookieBar');
      setAccepted(acceptedCookieBar);
    }
  }, []);

  return accepted;
};

export { useAcceptedCookieBar };
