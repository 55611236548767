import React from 'react';
import View from './View';
import Sidebar from './Sidebar';
import { withBlockExtensions } from '@plone/volto/helpers';

const Edit = (props) => {
  return (
    <>
      <View {...props} mode="edit" />
      <Sidebar {...props} />
    </>
  );
};

export default withBlockExtensions(Edit);
