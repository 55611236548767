import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { v4 as uuid } from 'uuid';
import View from './View';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';

export const PessoasContext = React.createContext({
  pessoaIndex: 0,
  setPessoaIndex: () => {},
});

const ProfileEdit = (props) => {
  const { onChangeBlock, block, selected } = props;
  const data = {
    pessoas: [{ '@id': uuid() }],
    ...props.data,
  };
  if (!props.data.pessoas) {
    onChangeBlock(block, data);
  }
  const [pessoaIndex, setPessoaIndex] = React.useState(0);

  const contextValue = React.useMemo(() => ({ pessoaIndex, setPessoaIndex }), [pessoaIndex]);

  return (
    <PessoasContext.Provider value={contextValue}>
      <View {...props} isEditMode pessoaIndex={pessoaIndex} setPessoaIndex={setPessoaIndex} />
      <SidebarPortal selected={selected}>
        <Sidebar {...props} data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </PessoasContext.Provider>
  );
};

export default withBlockExtensions(ProfileEdit);
