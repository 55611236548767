import React, { useEffect } from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import './mostvisitedservicesblock.less';
import GridServices from './GridServices/BlockGridServices';
import { listServices } from '@package/actions/govServices/service';
import { useDispatch, useSelector } from 'react-redux';

const MostVisitedServicesBlockView = (props) => {
  const dispatch = useDispatch();
  const services = useSelector(({ govService }) => govService?.services);

  useEffect(() => {
    if (!services) dispatch(listServices(52702));
  }, [dispatch, services]);

  const mockServicos = services
    ?.sort((a, b) => b.avaliacoes.positivas + b.avaliacoes.negativas - a.avaliacoes.positivas + a.avaliacoes.negativas)
    .map((service) => ({
      icon: 'fas fa-desktop',
      path: service.url,
      title: service.nome,
    }));
  return (
    <>
      <div className="block-mais">
        <div className="most-visited-services">
          <div className="ui container">
            <div className="most-visited-services-title">Serviços mais acessados</div>
            <GridServices services={mockServicos} />

            <div className="most-visited-services-content-footer">
              <a href="/#" className="event-show-more br-button secondary">
                Veja todos os serviços
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withBlockExtensions(MostVisitedServicesBlockView);
