import React from 'react';

import { MagicButtonStyle } from './style';
import PropTypes from 'prop-types';

function MagicButton({ button, label, ...rest }) {
  return (
    <MagicButtonStyle>
      <DefaultButton button={button} label={label} {...rest} />
    </MagicButtonStyle>
  );
}

function DefaultButton({ label, button, ...rest }) {
  return (
    <div className={`br-magic-gov-button  ${button || ''}`}>
      <button className="br-button-gov" type="button" {...rest}>
        {label}
      </button>
    </div>
  );
}

MagicButton.propTypes = {
  label: PropTypes.string,
  button: PropTypes.string,
};

DefaultButton.propTypes = {
  label: PropTypes.string,
  button: PropTypes.string,
};

export default MagicButton;
