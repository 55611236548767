import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
const ContactInfoUpdate = ({ content }) => {
  return (
    <div className="contact-update">
      {content.modified && (
        <p className="documentDescription">
          Atualizado por {content.creators} em {Moment(content?.modified).format('DD/MM/YYYY')}
        </p>
      )}
    </div>
  );
};

ContactInfoUpdate.propTypes = {
  content: PropTypes.shape({
    modified: PropTypes.string,
  }),
};
export default ContactInfoUpdate;
