/**
 * ServiceView component.
 * @module components/View/ServiceView
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Accordion, Image } from 'semantic-ui-react';
import ShareButtons from '@package/components/ShareButtons';
import Moment from 'moment';
import { Tags } from '@plone/volto/components';
import 'moment/locale/pt-br';
import MagicButton from '../MagicButton';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { searchContent, resetSearchContent } from '@plone/volto/actions/search/search';

/**
 * List view component class.
 * @function ServiceView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ServiceView = ({ content }) => {
  const serviceContatKey = 'service_contact';
  const serviceKey = 'serviceBlock';
  const services = useSelector((state) => state.search.subrequests?.[`${serviceKey}`]?.items);
  const contact = useSelector((state) => state.search.subrequests[`${serviceContatKey}`]?.items.find((e) => e));
  const [state, setState] = useState({ activeIndex: 0 });

  const { activeIndex } = state;

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = state;
    const newIndex = activeIndex === index ? -1 : index;

    setState({ activeIndex: newIndex });
  };

  const dt = content.modified ?? content.created;

  const dispatch = useDispatch();

  const button_txt = content.url_button ? content.url_button : 'Iniciar';
  const searchServicesCallback = useCallback(
    (data) => {
      const ids = data?.reduce((arr, s) => (s ? [...arr, flattenToAppURL(s)] : arr), []);

      if (ids.length > 0) {
        dispatch(searchContent('', { path: ids, metadata_fields: ['icon', 'url', 'title'] }, serviceKey));
      }
    },
    [dispatch],
  );

  const searchContactCallback = useCallback(
    (data) => {
      const id = flattenToAppURL(data['@id']);
      dispatch(
        searchContent(
          id,
          {
            metadata_fields: ['title', 'email', 'phone'],
          },
          serviceContatKey,
        ),
      );
    },
    [dispatch],
  );
  useEffect(() => {
    if (content.contact) {
      searchContactCallback(content.contact);
    }
    if (content?.related_services?.length > 0) {
      searchServicesCallback(content.related_services);
    }
    return () => {
      resetSearchContent(serviceKey);
      resetSearchContent(serviceContatKey);
    };
  }, [content.contact, content.related_services]);

  return (
    <div>
      <Container id="page-home">
        <section id="content-core">
          <div className="service-header">
            <div>
              <i className={'fa ' + content.icon} style={{ fontSize: 32, color: '#1351B4', fontWeight: 900 }}></i>
            </div>
            <div className="service-header-title">{content.category?.title}</div>
          </div>
          <div className="service-sub-header">
            <div className="service-sub-header-title">
              <div>
                {content.title} {content.abbreviation ? ' - ' + content.abbreviation : ''}{' '}
              </div>
              <div className="service-sub-header-subtitle">{content.short_title_sr}</div>
            </div>
            {content.url && <MagicButton label={button_txt} onClick={() => window.open(content.url, '_blank')} />}
          </div>

          <div className="service-info">
            <div className="service-author">
              Atualizado por {content.creators[0]} em {Moment(dt).format('DD[/]MM[/]YYYY')}
            </div>
            <div className="service-share">
              <ShareButtons shareKey={'share-service'} />
            </div>
          </div>
          <div className="service-content">
            <div className="service-content-left">
              <Accordion className="service-accordeon">
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                  className="service-accordeon-title"
                >
                  <i
                    className={(activeIndex === 0 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                  ></i>{' '}
                  Sobre o Serviço
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0} className="service-accordeon-content">
                  <div
                    className="service-accordeon-text"
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.description_sr.data),
                    }}
                  />
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                  className="service-accordeon-title"
                >
                  <i
                    className={(activeIndex === 1 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                  ></i>{' '}
                  Quem pode utilizar este serviço?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1} className="service-accordeon-content">
                  <div className="service-accordeon-text">
                    <b>Público-alvo</b>
                    <br />
                    {content.target_checklist.map((tc) => (
                      <span>
                        {tc.title}
                        <br />
                      </span>
                    ))}
                    <br />
                    <b>Requisitos necessários</b>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: flattenHTMLToAppURL(content.target_requirements.data),
                      }}
                    />
                  </div>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleClick}
                  className="service-accordeon-title"
                >
                  <i
                    className={(activeIndex === 2 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                  ></i>{' '}
                  Etapas para a realização deste serviço
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2} className="service-accordeon-content">
                  <div
                    className="service-accordeon-text"
                    dangerouslySetInnerHTML={{
                      __html: flattenHTMLToAppURL(content.workflow.data),
                    }}
                  />
                  {content.workflow_image && (
                    <div>
                      <Image className="service-workflow_image" src={content.workflow_image} size={'massive'} />
                    </div>
                  )}
                </Accordion.Content>

                {content.department && (
                  <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={handleClick}
                    className="service-accordeon-title"
                  >
                    <i
                      className={(activeIndex === 3 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                    ></i>{' '}
                    Contatos
                  </Accordion.Title>
                )}
                {content.department && (
                  <Accordion.Content active={activeIndex === 3} className="service-accordeon-content">
                    <div className="service-accordeon-text">
                      <div>{contact?.title}</div>
                      <div>{contact?.email}</div>
                      <div>{contact?.phone}</div>
                    </div>
                  </Accordion.Content>
                )}
                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={handleClick}
                  className="service-accordeon-title"
                >
                  <i
                    className={(activeIndex === 4 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                  ></i>{' '}
                  Outras Informações
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4} className="service-accordeon-content">
                  <div className="service-accordeon-text">
                    <div>
                      <b>Tempo Estimado</b>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: flattenHTMLToAppURL(content.estimated_time?.data),
                      }}
                    />
                    {content.info && (
                      <span>
                        <br />
                        <div>
                          <b>Informações adicionais ao Tempo Estimado</b>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: flattenHTMLToAppURL(content.info?.data),
                          }}
                        />
                      </span>
                    )}
                    {content.additional_info && (
                      <span>
                        <br />
                        <div>
                          <b>Informações adicionais</b>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: flattenHTMLToAppURL(content.additional_info?.data),
                          }}
                        />
                      </span>
                    )}
                  </div>
                </Accordion.Content>

                {content.legal_info && (
                  <Accordion.Title
                    active={activeIndex === 5}
                    index={5}
                    onClick={handleClick}
                    className="service-accordeon-title"
                  >
                    <i
                      className={(activeIndex === 5 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                    ></i>{' '}
                    Legislação
                  </Accordion.Title>
                )}
                {content.legal_info && (
                  <Accordion.Content active={activeIndex === 5} className="service-accordeon-content">
                    <div className="service-accordeon-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flattenHTMLToAppURL(content.legal_info.data),
                        }}
                      />
                    </div>
                  </Accordion.Content>
                )}

                {content.reports && (
                  <Accordion.Title
                    active={activeIndex === 6}
                    index={6}
                    onClick={handleClick}
                    className="service-accordeon-title"
                  >
                    <i
                      className={(activeIndex === 6 ? 'fa fa-chevron-down' : 'fa fa-chevron-right') + ' service-icon'}
                    ></i>{' '}
                    Avaliação, Indicadores e Relatórios
                  </Accordion.Title>
                )}
                {content.reports && (
                  <Accordion.Content active={activeIndex === 6} className="service-accordeon-content">
                    <div className="service-accordeon-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flattenHTMLToAppURL(content.reports.data),
                        }}
                      />
                    </div>
                  </Accordion.Content>
                )}
              </Accordion>
            </div>

            {services && services?.length > 0 && (
              <div className="service-content-right">
                <p className="related-services-title">SERVIÇOS RELACIONADOS</p>
                {services.map((service) => (
                  <div className="related-service">
                    <a href={flattenToAppURL(service.url)} className="related-service-link">
                      <div className="related-service-icon">
                        <i className={'fa ' + service.icon}></i>
                      </div>
                      <div className="related-service-text">{service.title}</div>
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      </Container>
      <Tags tags={content.service_tags} />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ServiceView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ServiceView;
