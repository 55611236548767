import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { getNode, requestTicket, getChildren } from '@package/actions/alfresco/alfresco';
import { useDispatch, useSelector } from 'react-redux';
import AlfrescoAuthUtil from '@package/util/AlfrescoAuthUtil';
import { Link } from 'react-router-dom';
import { clearSharedLink, createRendition, createSharedLink } from '@package/actions/alfresco/alfresco';
import { Dimmer, Loader } from 'semantic-ui-react';
import './style.less';
import PropTypes from 'prop-types';
import gallerySVG from '@package/icons/gallery.svg';
import { Icon } from '@plone/volto/components';

/**
 * Component for navigation in alfresco folders
 * @param onChangeDocument
 * @param mimeTypes
 * @param defaultPath
 * @param title
 * @param placeholder
 * @returns {JSX.Element}
 * @constructor
 */
const ExploreDocs = ({ onChangeDocument, mimeTypes, defaultPath, title, placeholder }) => {
  const alfresco = useSelector((state) => state && state.alfresco);
  const dispatch = useDispatch();
  const [history, setHistory] = useState([{ path: defaultPath, name: 'Home' }]);
  const [slides, setSlides] = useState([]);
  const [count, setCount] = useState(0);
  const [galleryName, setGalleryName] = useState('');

  const mountBreadcrumb = (nodeId, name) => {
    let breakCondition = false;
    const newHistory = history.reduce((acc, curr) => {
      if (breakCondition) {
        return acc;
      }
      if (curr.path === nodeId) breakCondition = true;
      return [...acc, curr];
    }, []);
    if (!breakCondition) {
      setHistory([...newHistory, { path: nodeId, name }]);
    } else {
      setHistory(newHistory);
    }
  };
  const retrieveNode = (nodeId, name) => {
    dispatch(
      getNode({
        path: AlfrescoAuthUtil.getNodePath(nodeId),
      }),
    );
    mountBreadcrumb(nodeId, name);
  };

  const checkMimeType = (entry) => {
    if (mimeTypes.length && entry.isFile) {
      return mimeTypes.includes(entry.content.mimeType);
    }
    return true;
  };

  const entryIsImage = (entry) => {
    return entry.content?.mimeType.includes('image');
  };

  const onClickGallery = async (entry) => {
    if (entry.isFolder) {
      setGalleryName(entry.name);
      dispatch(getChildren(entry.id)).then((res) => {
        if (res.list?.entries) {
          setCount(res.list.entries.length);
          // eslint-disable-next-line array-callback-return
          res.list.entries.map((item) => {
            dispatch(createRendition(item.entry.id)).then(() => {
              dispatch(createSharedLink(item.entry.id)).then((response) => {
                const url = AlfrescoAuthUtil.getPublicSharedlinkRendition(
                  response.entry.id,
                  response.entry.name?.split('/').pop(),
                );
                const thumb = AlfrescoAuthUtil.getPublicSharedlinkRendition(
                  response.entry.id,
                  response.entry.name?.split('/').pop(),
                  'doclib',
                );
                const title =
                  typeof item.entry.properties['cm:title'] !== 'undefined' &&
                  item.entry.properties['cm:title'] !== null &&
                  item.entry.properties['cm:title'].length !== 0
                    ? item.entry.properties['cm:title']
                    : item.entry.name;
                setSlides((slides) => [
                  ...slides,
                  {
                    '@id': item.entry.id,
                    title: title,
                    name: item.entry.name,
                    href: url,
                    createdBy: item.entry.createdByUser.displayName,
                    original: url,
                    thumbnail: thumb,
                    description: title,
                  },
                ]);
              });
            });
          });
        }
      });
    }
  };
  //
  useEffect(() => {
    if (count === slides.length) {
      onChangeDocument({ galleryName: galleryName, slides: slides });
      setCount(0);
      setGalleryName('');
      dispatch(clearSharedLink());
    }
  }, [slides]);

  const onClickPath = (entry) => {
    if (entry.isFolder) {
      retrieveNode(entry.id, entry.name);
    } else {
      entryIsImage(entry)
        ? dispatch(createRendition(entry.id)).then(() => dispatch(createSharedLink(entry.id)))
        : dispatch(createSharedLink(entry.id));
    }
  };
  const retriveHome = useCallback(() => {
    dispatch(
      getNode({
        path: AlfrescoAuthUtil.getNodePath(defaultPath),
      }),
    );
    setHistory([{ path: defaultPath, name: 'Home' }]);
  }, [defaultPath, dispatch]);

  useEffect(() => {
    if (alfresco?.token) {
      retriveHome();
    }
  }, [alfresco?.token, retriveHome]);

  useLayoutEffect(() => {
    dispatch(requestTicket());
  }, [dispatch]);

  return (
    <div>
      <Dimmer active={alfresco.alfresco.loading}>
        <Loader>Carregando</Loader>
      </Dimmer>
      <div className="ui raised segments">
        <header className="header pulled">
          <div className="vertical divider"></div>
          <h2>{title}</h2>
        </header>
        <div className="ui secondary vertical segment breadcrumbs">
          {history.map((item) => (
            <Link
              key={`history-${item.name}`}
              to={'#/'}
              onClick={() => retrieveNode(item.path, item.name)}
              className="section"
            >
              {item.path !== defaultPath && <span className="divider"> / </span>}
              {item.path === defaultPath ? <i className="fas fa-home"></i> : item.name}
            </Link>
          ))}
        </div>
        <ul className="ui segment object-listing">
          {alfresco?.list?.entries &&
            alfresco?.list?.entries.map(
              ({ entry }) =>
                checkMimeType(entry) && (
                  <li key={`li-${entry.id}`} role="presentation" aria-label="Procurar Images">
                    <span title={entry.name} role="presentation" onClick={() => onClickPath(entry)}>
                      <i className={entry.isFolder ? 'fas fa-folder' : 'fas fa-file'} />
                      {entry.name}
                    </span>

                    <span title={entry.name} role="presentation" onClick={() => onClickGallery(entry)}>
                      <Icon name={gallerySVG} />
                      usar como galeria
                    </span>
                  </li>
                ),
            )}
          {alfresco?.list?.entries.length <= 0 && (
            <div>
              <p>Não foram encontradas imagens, favor selecione outra pasta.</p>
              <button onClick={retriveHome}>
                <i className="fas fa-home" /> voltar para Ínicio
              </button>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
ExploreDocs.propTypes = {
  onChangeDocument: PropTypes.func.isRequired,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  defaultPath: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};
/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ExploreDocs.defaultProps = {
  mimeTypes: [],
  defaultPath: '-my-',
  title: 'Escolha uma Imagem',
  placeholder: 'Pesquisar por nome da imagem',
};

export default ExploreDocs;
