import React, { useEffect } from 'react';
import './Style.css';

import usePaginedNavigation from '@package/components/PaginedNavigation/usePaginedNavigation';

import LogoHeader from '@package/images/logos/LogoHeader.svg';
import ListItem from './ListItem';
import { flattenToAppURL } from '@plone/volto/helpers';
const PaginedNavigation = ({ pathname, showMenu, toggleMenu, menu_info, density }) => {
  const { paginedMenu, setPathMenu } = usePaginedNavigation();
  let default_density = density ? density : 'medium';
  let default_menu_info = menu_info ? (
    menu_info
  ) : (
    <div className="text-center text-down-01">
      Todo o conteúdo deste site está publicado sob a licença{' '}
      <strong>Creative Commons Atribuição-SemDerivações 3.0</strong>
    </div>
  );

  const onClickNavigation = (path) => {
    setPathMenu(path === '' ? '/' : path);
  };
  useEffect(() => {
    if (!!pathname) setPathMenu(pathname);
    else setPathMenu('/');
  }, [pathname]);

  return (
    <div className="col-sm">
      <div className="d-flex align-items-center">
        <div className={`br-menu ${showMenu ? 'active' : ''} ${default_density}`}>
          <div className="menu-container">
            <div className="menu-panel col-sm-4 col-lg-3">
              <div className="menu-header">
                <div className="menu-title">
                  <img src={LogoHeader} alt="Logo UFT" />
                  <span>{'Universidade Federal do Tocantins'}</span>
                </div>
                <div className="menu-close">
                  <button
                    className="br-button circle"
                    type="button"
                    aria-label="Fechar o menu"
                    data-dismiss="menu"
                    onClick={() => {
                      toggleMenu();
                    }}
                  >
                    <i className="fas fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <nav className="menu-body">
                {paginedMenu && (
                  <div
                    id={`${paginedMenu.parent?.UID ?? 'root'}`}
                    key={paginedMenu.parent?.UID}
                    className={`${pathname === paginedMenu.parent?.path ? 'menu-panel_hidden' : 'menu-panel_active'}`}
                  >
                    <ul className="menu-listview">
                      {flattenToAppURL(paginedMenu?.parent?.['@id']) !== undefined && (
                        <li className="menu-listitem side-menu active">
                          <a
                            href={'/#'}
                            className="menu-item "
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <span className="content">
                              <span>{paginedMenu?.parent?.label}</span>
                            </span>
                            <span
                              role="button"
                              tabIndex={0}
                              className="support"
                              onKeyDown={() => {}}
                              onClick={() => {
                                onClickNavigation(flattenToAppURL(paginedMenu?.parent?.prev));
                              }}
                            >
                              <i className="fas fa-angle-right" aria-hidden="true"></i>
                            </span>
                          </a>
                        </li>
                      )}
                      {paginedMenu?.menu?.map((panel) => (
                        <ListItem
                          key={panel?.UID}
                          uid={panel?.UID}
                          label={panel?.label}
                          path={flattenToAppURL(panel?.['@id'])}
                          activeLink={pathname}
                          child={panel?.child}
                          parentPath={paginedMenu.parent?.['@id']}
                          onClickNavigation={onClickNavigation}
                          toggleMenu={toggleMenu}
                          sub={!!paginedMenu.parent?.UID}
                        />
                      ))}
                    </ul>
                  </div>
                )}
              </nav>
              <div
                className="menu-footer"
                style={{
                  borderTop: '1px solid #CCCCCC',
                }}
              >
                <div className="menu-info">{default_menu_info}</div>
              </div>
            </div>
            <div className="menu-scrim" data-dismiss="menu"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaginedNavigation;
