export const defaultCategories = [
  {
    name: 'CÂMPUS',
    data: [
      { name: 'Arraias', link: '/campus/arraias' },
      { name: 'Gurupi', link: '/campus/gurupi' },
      { name: 'Miracema', link: '/campus/miracema' },
      { name: 'Palmas', link: '/campus/palmas' },
      { name: 'Porto Nacional', link: '/campus/portonacional' },
    ],
    link: '/campus',
  },
  {
    name: 'CURSOS',
    data: [
      { name: 'Cursos de Graduação', link: '/graduacao/cursos' },
      { name: 'Mestrados e Doutorados', link: '/pesquisa-pos-graduacao-e-inovacao/mestrados-e-doutorados' },
      { name: 'Especializações e MBAs', link: '/pesquisa-pos-graduacao-e-inovacao/especializacoes-e-mbas' },
      { name: 'Educação a Distância (EaD)', link: '/ead/cursos-ead' },
    ],
    link: '/cursos',
  },
  {
    name: 'GESTÃO',
    data: [
      { name: 'Reitoria', link: '/gestao/gabinete' },
      { name: 'Avaliação e Planejamento', link: '/gestao/avaliacao-e-planejamento' },
      { name: 'Administração e Finanças', link: '/gestao/administracao-e-financas' },
      { name: 'Gestão e Desenvolvimento de Pessoas', link: '/gestao/pessoas' },
      { name: 'Assistência Estudantil ', link: '/assistencia-estudantil' },
      { name: 'Graduação', link: '/graduacao' },
      { name: 'Pesquisa e Pós-Graduação', link: '/pesquisa-e-pos-graduacao' },
      { name: 'Extensão', link: '/extensao' },
      { name: 'Tecnologia da Informação e Comunicação', link: '/gestao/tic' },
      { name: 'Prefeitura Universitária', link: '/gestao/prefeitura-universitaria' },
    ],
    link: '/gestao',
  },
];

export const loadCategories = () => {
  const processedCategories = [];

  defaultCategories.forEach((category) => {
    if (category.data.length > 5) {
      const firstHalf = category.data.slice(0, Math.ceil(category.data.length / 2));
      const secondHalf = category.data.slice(Math.ceil(category.data.length / 2));

      processedCategories.push({ ...category, data: firstHalf });
      processedCategories.push({ ...category, name: '', data: secondHalf });
    } else {
      processedCategories.push(category);
    }
  });

  return processedCategories;
};
