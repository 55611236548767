import React from 'react';
import { BlockDataForm } from '@plone/volto/components';
import { eventSchema } from './schema';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  privatePageError: {
    id: 'Página Privado',
    defaultMessage: 'A página selecionada está privada. Por favor, selecione uma página publicada.',
  },
});
const EventBlockData = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();
  const schema = eventSchema({ ...props, intl });
  const handleFieldChange = (id, value) => {
    const isEditableAndUnpublished = id === 'href' && value?.length > 0 && value[0]?.review_state !== 'published';
    let updatedData = { ...data, [id]: value };

    if (isEditableAndUnpublished) {
      toast.error(intl.formatMessage(messages.privatePageError), {});
      updatedData = { ...data, [id]: [] };
    }

    onChangeBlock(block, updatedData);
  };

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={handleFieldChange}
      formData={data}
      block={block}
    />
  );
};

export default EventBlockData;
