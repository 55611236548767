/**
 * Service actions.
 * @module actions/apiUft/apiUft
 */

import { APIUFT_LIST_DISCIPLINES, APIUFT_PRINT_CERTIFICATE } from '@package/constants/ActionTypes';

/**
 * Request service function.
 * @function getService
 * @returns {Object} Entry service.
 */
export function listUftDisciplines(year, period, idCourse, subrequest) {
  return {
    type: APIUFT_LIST_DISCIPLINES,
    subrequest,
    request: {
      op: 'get',
      path: `@api_uft_disciplines`,
      params: {
        year,
        period,
        idCourse,
      },
    },
  };
}

export function printCertificate(auth) {
  return {
    type: APIUFT_PRINT_CERTIFICATE,
    request: {
      op: 'get',
      path: `@api_uft_print_certificate`,
      params: {
        AUTH: auth,
      },
    },
  };
}
