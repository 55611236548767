import _ from 'lodash';
import { domainEmailRegex, emailRegex, phoneRegex } from './regex';
import Moment from 'moment';

export async function generateHash(url) {
  const encoder = new TextEncoder();
  const data = encoder.encode(url);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

//
export function setMaskTelephone(tel) {
  if (tel === null) return null;
  let mTel = tel.replace(/\D/g, ''); // Remove tudo o que não é dígito
  mTel = mTel.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
  mTel = mTel.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
  return mTel;
}
// mask for international telephone format: (+99 99) 99999-9999
// parenteses nos 4 primeiros digitos, espaço entre o 4 e o 5, hifen entre o 9 e o 10
export function setMaskTelephoneInternational(tel) {
  if (!tel) return tel;
  if (tel.replace(/\D/g, '').length < 5) return tel;

  return tel
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{2})/, '(+$1 $2) ')
    .replace(/(\d)(\d{4})$/, '$1-$2');
}
export function maskCPF(cpf) {
  if (cpf === null || cpf === '') return cpf;

  if (cpf.length < 11) return cpf;
  return cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
}

export function cleanTelephone(tel) {
  return _.replace(tel, /[()\-\s_]/g, '');
}

// CPF utils
export function cleanCPF(strCPF) {
  return _.replace(strCPF, /[.|\-_]/g, '');
}

export function emailTest(email) {
  return emailRegex.test(email) && domainEmailRegex.test(email);
}
export function phoneTest(phone) {
  return phoneRegex.test(cleanTelephone(phone));
}

export function validateCPF(strCPF) {
  const rawCPF = cleanCPF(strCPF);
  let sum;
  let rest;
  sum = 0;
  if (!Array.from(rawCPF).filter((e) => e !== rawCPF[0]).length) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(rawCPF.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(rawCPF.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(rawCPF.substring(i - 1, i), 10) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  return rest === parseInt(rawCPF.substring(10, 11), 10);
}

export const blobToBase64 = async (imageBlob, callback) => {
  const reader = new FileReader();
  reader.onload = () => {
    callback(reader.result);
  };
  reader.onerror = () => {
    callback('error');
  };
  reader.readAsDataURL(imageBlob);
};

export const stringToHour = (string) => Moment(string).format('HH:mm');

export const onlyHour = (momentDate) => momentDate.format('HH:mm');

export const capitalizaFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeLastPath = (url) => {
  return url.substring(0, url.lastIndexOf('/'));
};
export const extractMaptSrc = (embed) => {
  if (typeof DOMParser !== 'undefined' && window.DOMParser.prototype.parseFromString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(embed, 'text/html');
    const iframe = doc.getElementsByTagName('iframe');
    if (iframe.length === 0) {
      return '';
    }
    return iframe[0].src;
  }
};

export function getStartOfDay(date) {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
}

export function getEndOfDay(date) {
  const d = new Date(date);
  d.setHours(23, 59, 59, 999);
  return d.toISOString();
}
