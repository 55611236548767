import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import './main.less';
import DefaultProfilePic from '../../../images/noprofilepic.png';
import Mail from './icons/email.png';
import Lattes from './icons/lattes.png';
import Popup from '@plone/volto/components/theme/Popup/Popup';

// import Icon from '../../../../omelette/src/components/theme/Icon/Icon';

const ProfileView = (props) => {
  const { data, isEditMode } = props;

  if (data?.pessoas) {
    if (data?.ordenar) {
      data?.pessoas?.sort((a, b) => {
        if (a.nome && b.nome) {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
        }
        return 0;
      });
    }
  }

  const stylepopup = {
    backgroundColor: '#155BCB',
    color: '#FFF',
    border: '1px #155BCB',
  };

  return (
    <div>
      <div className="profile-row">
        {data?.pessoas?.map((pessoa, index) => {
          return (
            <div className="profile-card" key={'profile_' + index}>
              <div>
                {pessoa?.lattes && isEditMode && (
                  <Popup
                    style={stylepopup}
                    content="Acessar currículo lattes"
                    trigger={
                      <img
                        src={pessoa.imagem ? pessoa.imagem : DefaultProfilePic}
                        alt={pessoa.nome}
                        className="profile-img"
                      />
                    }
                    position="top center"
                    className="profile-popup"
                  />
                )}
                {pessoa?.lattes && !isEditMode && (
                  <a href={pessoa.lattes} target="_blank" rel="noreferrer">
                    <Popup
                      style={stylepopup}
                      content="Acessar currículo lattes"
                      trigger={
                        <img
                          src={pessoa.imagem ? pessoa.imagem : DefaultProfilePic}
                          alt={pessoa.nome}
                          className="profile-img"
                        />
                      }
                      position="top center"
                      className="profile-popup"
                    />
                  </a>
                )}
                {!pessoa?.lattes && (
                  <img
                    src={pessoa.imagem ? pessoa.imagem : DefaultProfilePic}
                    alt={pessoa.nome}
                    className="profile-img"
                  />
                )}
              </div>
              <div className="profile-info">
                <div className="profile-nome">{pessoa?.nome}</div>
                <div className="profile-cargo">{pessoa?.cargo}</div>
                {pessoa?.email && (
                  <div className="profile-email">
                    <img src={Mail} style={{ marginTop: '-6px' }} alt={'email'} />
                    &nbsp;{pessoa?.email}
                  </div>
                )}
                {pessoa?.lattes && (
                  <div className="profile-lattes">
                    <a href={pessoa.lattes} target="_blank" rel="noreferrer">
                      <img src={Lattes} style={{ marginTop: '-3px' }} alt={'lattes'} />
                      &nbsp;Currículo Lattes
                    </a>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withBlockExtensions(ProfileView);
