/**
 * GovServices reducer.
 * @module reducers/govServices/service
 */

import { GOVSERVICE_GET_SERVICE, GOVSERVICE_LIST_SERVICES } from '@package/constants/ActionTypes';

export const initialState = {
  services: null,
  service: null,
  subrequests: {},
  govservice: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request Key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * GovServices reducer.
 * @function service
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function govService(state = initialState, action = {}) {
  switch (action.type) {
    case `${GOVSERVICE_GET_SERVICE}_PENDING`:
    case `${GOVSERVICE_LIST_SERVICES}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GOVSERVICE_GET_SERVICE}_SUCCESS`:
      return {
        ...state,
        service: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GOVSERVICE_GET_SERVICE}_FAIL`:
      return {
        ...state,
        service: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${GOVSERVICE_LIST_SERVICES}_SUCCESS`:
      return {
        ...state,
        services: action.result?.resposta,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GOVSERVICE_LIST_SERVICES}_FAIL`:
      return {
        ...state,
        services: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
