import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export const SocialMedia = () => {
  const sizeitem = '1.125rem';
  return (
    <div className="col px-0">
      <div className="social-network">
        <div className="d-flex social-network-title">Redes Sociais</div>
        <div className="social-network-icons">
          <UniversalLink href="https://www.facebook.com/UFToficial">
            <FontAwesomeIcon icon={faFacebook} style={{ fontSize: sizeitem }} className="social-icon" />
          </UniversalLink>
          <UniversalLink href="https://www.instagram.com/UFToficial">
            <FontAwesomeIcon icon={faInstagram} style={{ fontSize: sizeitem }} className="social-icon" />
          </UniversalLink>
          <UniversalLink href="https://www.youtube.com/UFToficial">
            <FontAwesomeIcon icon={faYoutube} style={{ fontSize: sizeitem }} className="social-icon" />
          </UniversalLink>
          <UniversalLink href="https://www.linkedin.com/school/uftoficial/">
            <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: sizeitem }} className="social-icon" />
          </UniversalLink>
          <UniversalLink href="https://twitter.com/UFToficial">
            <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: sizeitem }} className="social-icon" />
          </UniversalLink>
          <UniversalLink href="https://www.tiktok.com/@oficialuft">
            <FontAwesomeIcon icon={faTiktok} style={{ fontSize: sizeitem }} className="social-icon" />
          </UniversalLink>
        </div>
      </div>
    </div>
  );
};
