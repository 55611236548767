/**
 * Service actions.
 * @module actions/govServices/service
 */

import { GOVSERVICE_GET_SERVICE, GOVSERVICE_LIST_SERVICES } from '@package/constants/ActionTypes';

/**
 * Request service function.
 * @function getService
 * @returns {Object} Entry service.
 */
export function getService(id, path = 'servicos') {
  return {
    type: GOVSERVICE_GET_SERVICE,
    request: {
      op: 'get',
      path: `@gov_services`,
      params: {
        id,
        path,
      },
    },
  };
}

/**
 * Request list of service function.
 * @function listServices
 * @returns {Object} Entry service.
 */
export function listServices(orgaoId, path = 'servicos/orgao') {
  return {
    type: GOVSERVICE_LIST_SERVICES,
    request: {
      op: 'get',
      path: `@gov_services`,
      params: {
        id: orgaoId,
        path,
      },
    },
  };
}
