import fileSVG from '@plone/volto/icons/file.svg';
import chunkSVG from '@plone/volto/icons/chunk.svg';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import alfrescoSVG from '@package/icons/alfresco.svg';
import gallerySVG from '@package/icons/gallery.svg';
import addressCardSvg from '@package/icons/address-card.svg';
import calendarClockSvg from '@package/icons/calendar-clock.svg';
import circleBottomSvg from '@plone/volto/icons/circle-bottom.svg';
import sliderSvg from '@plone/volto/icons/slider.svg';
import codeSVG from '@plone/volto/icons/code.svg';
import NewsBlockViewBlock from './NewsBlock/View';
import NewsBlockEditBlock from './NewsBlock/Edit';
import ButtonBlockViewBlock from './ButtonBlock/View';
import ButtonBlockEditBlock from './ButtonBlock/Edit';
import SwiperBlockViewBlock from './SwiperBlock/View';
import SwiperBlockEditBlock from './SwiperBlock/Edit';
import MostVisitedServicesBlockViewBlock from './MostVisitedServicesBlock/View';
import MostVisitedServicesBlockEditBlock from './MostVisitedServicesBlock/Edit';
import SliderViewBlock from './CarouselBlock/View';
import SliderEditBlock from './CarouselBlock/Edit';
import { SliderBlockDataAdapter } from './CarouselBlock/adapter';
import ProfileViewBlock from './ProfileBlock/View';
import ProfileEditBlock from './ProfileBlock/Edit';
import ServicesViewBlock from './ServicesBlock/View';
import ServicesEditBlock from './ServicesBlock/Edit';
import EventBlockViewBlock from './EventBlock/View';
import EventBlockEditBlock from './EventBlock/Edit';
import AlfrescoImageEditBlock from './AlfrescoImageBlock/Edit';
import AlfrescoImageViewBlock from './AlfrescoImageBlock/View';
import AlfrescoListViewBlock from './AlfrescoListBlock/View';
import AlfrescoListEditBlock from './AlfrescoListBlock/Edit';
import AlfrescoGalleryViewBlock from './AlfrescoGalleryBlock/View';
import AlfrescoGalleryEditBlock from './AlfrescoGalleryBlock/Edit';
import UFTHeroViewBlock from './UFTHeroBlock/View';
import UFTHeroEditBlock from './UFTHeroBlock/Edit';
import MainContactBlockViewBlock from './MainContactBlock/View';
import MainContactBlockEditBlock from './MainContactBlock/Edit';
import DisciplinesBlockViewBlock from './DisciplinesBlock/View';
import DisciplinesBlockEditBlock from './DisciplinesBlock/Edit';
import CardBlockViewBlock from './CardBlock/View';
import CardBlockEditBlock from './CardBlock/Edit';
// import Edit from "./CardsBlock/Edit";
// import View from "./CardsBlock/View";
// import variations from "./CardsBlock/variations";

const blocks = {
  newsBlock: {
    id: 'newsBlock',
    title: 'Destaque',
    icon: fileSVG,
    group: 'uft',
    view: NewsBlockViewBlock,
    edit: NewsBlockEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  },
  buttonBlock: {
    id: 'buttonBlock',
    title: 'Botão UFT',
    icon: circleBottomSvg,
    group: 'uft',
    view: ButtonBlockViewBlock,
    edit: ButtonBlockEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  },
  swiperBlock: {
    id: 'swiperBlock',
    title: 'Swiper UFT',
    icon: fileSVG,
    group: 'uft',
    view: SwiperBlockViewBlock,
    edit: SwiperBlockEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  },
  mostVisitedServicesBlock: {
    id: 'mostVisitedServicesBlock',
    title: 'Serviços mais acessados',
    icon: chunkSVG,
    group: 'uft',
    view: MostVisitedServicesBlockViewBlock,
    edit: MostVisitedServicesBlockEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 0,
  },
  carouselBlock: {
    id: 'carouselBlock',
    title: 'Carousel UFT',
    icon: sliderSvg,
    group: 'uft',
    view: SliderViewBlock,
    edit: SliderEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    referenceContainerQuery: '.container .header',
    dataAdapter: SliderBlockDataAdapter,
  },
  profileBlock: {
    id: 'profileBlock',
    title: 'Perfil UFT',
    icon: fileSVG,
    group: 'uft',
    view: ProfileViewBlock,
    edit: ProfileEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  },
  servicesBlock: {
    id: 'servicesBlock',
    title: 'Serviços UFT',
    icon: fileSVG,
    group: 'uft',
    view: ServicesViewBlock,
    edit: ServicesEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  },
  eventBlock: {
    id: 'eventBlock',
    title: 'Eventos UFT',
    icon: calendarSVG,
    group: 'uft',
    view: EventBlockViewBlock,
    edit: EventBlockEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  alfrescoImageBlock: {
    id: 'alfrescoImageBlock',
    title: 'Imagem do UFTDocs',
    icon: alfrescoSVG,
    group: 'media',
    view: AlfrescoImageViewBlock,
    edit: AlfrescoImageEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  alfrescoListBlock: {
    id: 'alfrescoListBlock',
    title: 'Lista de Diretórios e Documentos do UFTDocs',
    icon: alfrescoSVG,
    group: 'media',
    view: AlfrescoListViewBlock,
    edit: AlfrescoListEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  alfrescoGalleryBlock: {
    id: 'alfrescoGalleryBlock',
    title: 'Galeria de imagens do UFTDocs',
    icon: gallerySVG,
    group: 'uft',
    view: AlfrescoGalleryViewBlock,
    edit: AlfrescoGalleryEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  uftHeroBlock: {
    id: 'uftHeroBlock',
    title: 'UFT Hero',
    icon: fileSVG,
    group: 'uft',
    view: UFTHeroViewBlock,
    edit: UFTHeroEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  mainContactBlock: {
    id: 'mainContactBlock',
    title: 'Contato',
    icon: addressCardSvg,
    group: 'uft',
    view: MainContactBlockViewBlock,
    edit: MainContactBlockEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  disciplinesBlock: {
    id: 'disciplinesBlock',
    title: 'Disciplinas da UFT',
    icon: calendarClockSvg,
    group: 'uft',
    view: DisciplinesBlockViewBlock,
    edit: DisciplinesBlockEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  cardBlock: {
    id: 'cardBlock',
    title: 'Card UFT',
    icon: codeSVG,
    group: 'uft',
    view: CardBlockViewBlock,
    edit: CardBlockEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
  },
  // cardsBlock: {
  //   id: 'cardsBlock',
  //   title: 'Cards',
  //   icon: codeSVG,
  //   group: 'uft',
  //   edit: Edit,
  //   view: View,
  //   restricted: false,
  //   mostUsed: true,
  //   sidebarTab: 1,
  //   themes: [
  //     ['primary', 'Primary'],
  //     ['secondary', 'Secondary'],
  //     ['tertiary', 'Tertiary'],
  //   ],
  //   variations,
  // }
};

export default blocks;
