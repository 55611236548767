/**
 * View title/description block.
 * @module volto-slate/blocks/Title/TitleBlockView
 */

import React from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment';
import 'moment/locale/pt-br';
import ShareButtons from '@package/components/ShareButtons';
/**
 * View title block component.
 * @class View
 * @extends Component
 */
const TitleBlockView = ({ properties, metadata }) => {
  const getDataShow = (properties, metadata) => {
    const dateImported = (metadata || properties)['created_import'];
    if (!!dateImported && Moment(dateImported).isValid()) return dateImported;
    return (metadata || properties)['review_state'] === 'published'
      ? (metadata || properties)['effective']
      : (metadata || properties)['created'];
  };
  const data = getDataShow(properties, metadata);
  return (
    <div>
      {(properties['@type'] === 'News Item' || properties['@type'] === 'comunicado') && (
        <div>
          <div className="title-chapeu">{(metadata || properties)['chapeu'] || ''}</div>
          <h1 className="documentFirstHeading">{(metadata || properties)['title'] || ''}</h1>
          <div className="title-description">{(metadata || properties)['description'] || ''}</div>
          <div>
            <hr className="title-hr" />
            <div className="title-metadados">
              <div className="title-txt">
                Por&nbsp;
                {(metadata || properties)['creators'].map((autor, index) => (
                  <span key={index}>
                    {index === 0 && ' ' + autor}
                    {index > 0 && ' e ' + autor}
                  </span>
                ))}
                {(metadata || properties)['review_state'] === 'published' && (
                  <span>&nbsp; | Publicado em {Moment(data).format('DD [de] MMMM [de] YYYY')}</span>
                )}
                {(metadata || properties)['review_state'] !== 'published' && (
                  <span>&nbsp; | Criado em {Moment(data).format('DD [de] MMMM [de] YYYY')}</span>
                )}
              </div>
              <div className="share">
                <ShareButtons shareKey={'share-header'} />
              </div>
            </div>
            <hr className="title-hr-after" />
          </div>
        </div>
      )}
      {properties['@type'] !== 'News Item' && properties['@type'] !== 'comunicado' && (
        <h1 className="documentFirstHeading">{(metadata || properties)['title'] || ''}</h1>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
TitleBlockView.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  metadata: PropTypes.objectOf(PropTypes.any),
};

export default TitleBlockView;
