import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@package/components/PaginedNavigation/MenuItem';

/**
 * Represents a list item in a menu.
 *
 * @param {Object} ListItem - The properties of the list item.
 * @param {String} ListItem.label - The label of the list item.
 * @param {String} ListItem.path - The path of the list item.
 * @param {String} ListItem.uid - The unique identifier of the list item.
 * @param {Boolean} ListItem.activeLink - Indicates if the list item is currently active.
 * @param {String} ListItem.icon - The icon of the list item.
 * @param {Object[]} ListItem.child - The child list items of the list item.
 * @param {String} ListItem.parentPath - The parent path of the list item.
 * @param {Function} ListItem.onClickNavigation - The function to be called when the list item is clicked.
 * @param {Function} ListItem.toggleMenu - The function to be called to toggle the menu.
 * @param {Boolean} ListItem.sub - Indicates if the list item is a sub-menu item.
 * @returns {ReactElement} The rendered list item component.
 */
const ListItem = ({ label, path, uid, activeLink, icon, child, parentPath, onClickNavigation, toggleMenu, sub }) => {
  if (child) {
    return (
      <li className={`menu-listitem ${sub ? 'sub' : ''}`}>
        <div className="menu-folder drop-menu">
          <MenuItem
            label={label}
            path={path}
            uid={uid}
            activeLink={activeLink}
            icon={icon}
            child={child}
            parentPath={parentPath}
            onClickNavigation={onClickNavigation}
            toggleMenu={toggleMenu}
          />
        </div>
      </li>
    );
  }
  return (
    <li className={`menu-listitem ${sub ? 'sub' : ''}`}>
      <MenuItem path={path} label={label} uid={uid} activeLink={activeLink} icon={icon} toggleMenu={toggleMenu} />
    </li>
  );
};

ListItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  uid: PropTypes.string,
  activeLink: PropTypes.string,
  icon: PropTypes.string,
  child: PropTypes.bool,
  parentPath: PropTypes.string,
  onClickNavigation: PropTypes.func,
  toggleMenu: PropTypes.func,
  sub: PropTypes.bool,
};

export default ListItem;
