import React, { useEffect, useState } from 'react';

import './style.css';
/**
 * Componente de Avatar da header
 * @returns {JSX.Element}
 * @constructor
 */

import BRCookiebar from './Cookiebar';

const BRCookiebarComponent = (props) => {
  const [accept, setAccept] = useState(false);

  let text = props.text ? props.text : 'Esse site faz uso de cookies';
  let title = props.title ? props.title : 'Atenção!';
  let cookiebarData = props.cookiebarData
    ? props.cookiebarData
    : [
        {
          lang: 'pt-br',
          allOptOut: false,
          acceptButton: '',
          optInButton: '',
          optOutButton: '',
          infoText: 'Esse site utiliza cookies',
          mainTitle: 'Atenção!',
          lastUpdate: '',
          entryText: '',
          selectAll: true,
          cookieGroups: [
            {
              groupId: '',
              groupName: '',
              groupOptOut: true,
              groupSelected: false,
              groupText: '',
              cookieList: [
                {
                  cookieId: '',
                  cookieOptOut: true,
                  cookieSelected: false,
                  alertMessage: '',
                  cookieName: '',
                  expires: '',
                  domain: '',
                  entreprise: '',
                  purpose: '',
                  description: '',
                },
              ],
            },
          ],
          noteTitle: '',
          noteList: [
            {
              question: '',
              answer: '',
            },
          ],
        },
      ];
  const cookiebarList = [];
  useEffect(() => {
    for (const brCookiebar of window.document.querySelectorAll('.br-cookiebar')) {
      cookiebarList.push(
        new BRCookiebar('br-cookiebar', brCookiebar, JSON.stringify(cookiebarData), 'pt-br', callback),
      );
    }
  }, [cookiebarData, cookiebarList]);

  function callback() {
    localStorage.setItem('AcceptedCookieBar', true);
    setAccept(true);
  }

  return (
    !accept && (
      <div className="br-cookiebar default">
        <div className="br-modal">
          <div className="br-card">
            <div className="container-fluid p-1 p-sm-4">
              <div className="wrapper">
                <div className="br-modal-header entry-content">
                  <div className="br-modal-title">{title}</div>
                  <button
                    className="br-button close circle small"
                    type="button"
                    data-dismiss="br-modal"
                    aria-label="Close"
                  >
                    <i className="fas fa-times" aria-hidden="true"></i>
                  </button>
                  <p className="last-update"></p>
                  <p className="entry-text">Esse site utiliza cookies</p>
                </div>
                <div className="br-modal-body" tabIndex="-1">
                  <p className="info-text">{text}</p>
                  <div className="br-list main-content"></div>
                </div>
                <div className="br-modal-footer actions justify-content-end">
                  <button className="br-button secondary small" type="button"></button>
                  <button className="br-button primary small" type="button"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
BRCookiebarComponent.propTypes = {};

export default BRCookiebarComponent;
