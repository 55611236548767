import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { defineMessages, useIntl, injectIntl } from 'react-intl';
import { stringToHour } from '@package/util/generalUtil';

const ContactOpeningHours = ({ content }) => {
  const messages = defineMessages({
    sunday: {
      id: 'sunday',
      defaultMessage: 'Dom',
    },
    monday: {
      id: 'monday',
      defaultMessage: 'Seg',
    },
    tuesday: {
      id: 'tuesday',
      defaultMessage: 'Ter',
    },
    wednesday: {
      id: 'wednesday',
      defaultMessage: 'Qua',
    },
    thursday: {
      id: 'thursday',
      defaultMessage: 'Qui',
    },
    friday: {
      id: 'friday',
      defaultMessage: 'Sex',
    },
    saturday: {
      id: 'saturday',
      defaultMessage: 'Sab',
    },
  });

  const intl = useIntl();

  return (
    <>
      {JSON.parse(content.opening_hours) && (
        <div>
          <p style={{ fontWeight: 700, marginBottom: '0px' }}>ATENDIMENTO :</p>
          {_.map(
            JSON.parse(content.opening_hours),
            (v, key) =>
              v.open && (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <div className="open-date" style={{ fontWeight: 500, marginBottom: '0px' }}>
                    {intl.formatMessage(messages[key])}:
                  </div>
                  <div className="open-hour">
                    {stringToHour(v.hours.one.open)} - {stringToHour(v.hours.one.close)}
                    {v.plusHour && `, ${stringToHour(v.hours.two.open)}-${stringToHour(v.hours.two.close)}`}
                  </div>
                </div>
              ),
          )}
        </div>
      )}
    </>
  );
};

ContactOpeningHours.propTypes = {
  content: PropTypes.shape({
    opening_hours: PropTypes.string,
  }),
};
export default compose(injectIntl)(ContactOpeningHours);
