import React from 'react';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from 'react-semantic-ui-range';
import { SecondaryButton } from '../GovBr/button';

const ImageCrop = ({ imageSrc, onCrop, setEditorRef, scaleValue, onScaleChange }) => (
  <div>
    <div className="editorOverlayInner">
      <div className="editorModalContent clearfix">
        <div className="cropCnt">
          <AvatarEditor
            image={imageSrc}
            border={50}
            scale={scaleValue / 10}
            color={[0, 0, 0, 0.72]}
            rotate={0}
            ref={setEditorRef}
            width={230}
            height={180}
            className="cropCanvas"
            crossOrigin="anonymous"
          />

          <Slider
            value={scaleValue}
            color="red"
            settings={{
              start: 10,
              min: 10,
              max: 50,
              step: 1,
              onChange: onScaleChange,
            }}
          />

          <SecondaryButton
            onClick={onCrop}
            style={{
              width: '20%',
              height: '30px',
              minWidth: '140px',
              margin: '10px 0 32px 0',
            }}
          >
            Cortar
          </SecondaryButton>
        </div>
      </div>
    </div>
  </div>
);

ImageCrop.propTypes = {
  setEditorRef: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  scaleValue: PropTypes.number.isRequired,
  onScaleChange: PropTypes.func.isRequired,
};

export default ImageCrop;
