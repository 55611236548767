import { defineMessages } from 'react-intl'; // , defineMessages
import fulllinkSVG from '@package/icons/link_advanced.svg';
import { makeInlineElementPlugin } from '@plone/volto-slate/elementEditor';

import { FULLLINK } from './constants';
import { FulllinkElement } from './render';
import { withFulllink } from './extensions';
import { fulllinkDeserializer } from './deserialize';
import FulllinkEditSchema from './schema';

const messages = defineMessages({
  edit: {
    id: 'Edit link',
    defaultMessage: 'Editar link',
  },
  delete: {
    id: 'Remove link',
    defaultMessage: 'Remover link',
  },
});

export default function installFullLinkPlugin(config) {
  const { slate } = config.settings;
  slate.htmlTagsToSlate.A = fulllinkDeserializer;
  const opts = {
    title: 'Link Avançado',
    pluginId: FULLLINK,
    elementType: FULLLINK,
    element: FulllinkElement,
    isInlineElement: true,
    editSchema: FulllinkEditSchema,
    extensions: [withFulllink],
    hasValue: (formData) => !!formData.link,
    toolbarButtonIcon: fulllinkSVG,
    messages,
  };

  const [installEditor] = makeInlineElementPlugin(opts);
  config = installEditor(config);

  return config;
}
