import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import './styles.less';

export const FulllinkElement = ({ attributes, children, element, mode }) => {
  // TODO: handle title on internal links
  let url = element.url;
  const { link } = element.data || {};

  const internal_link = link?.internal?.internal_link?.[0]?.['@id'];
  const external_link = link?.external?.external_link;
  const email = link?.email;
  const target_internal_link = internal_link && link?.internal?.openLinkInNewPage ? '_blank' : null;
  const target_external_link = link?.external?.target && link?.external?.target ? link?.external?.target : null;
  const target_link = target_internal_link || target_external_link;

  const href = email
    ? `mailto:${email.email_address}${email.email_subject ? `?subject=${email.email_subject}` : ''}`
    : external_link || internal_link || url;

  const { title } = element?.data || {};

  return mode === 'view' ? (
    <>
      <UniversalLink
        href={href || '#'}
        // openLinkInNewTab={link?.external?.target === '_blank'}
        target={target_link ? target_link : '_self'}
        title={title}
      >
        {children}
      </UniversalLink>
    </>
  ) : (
    <span {...attributes} className="slate-editor-link">
      {children}
    </span>
  );
};
