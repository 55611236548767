/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Image } from 'semantic-ui-react';

import logoFooter from '@package/images/logos/LogoFooter.svg';

import { injectIntl } from 'react-intl';
import { SocialMedia } from './SocialMedia/FooterSocialMedia';
import GridCategory from './GridCategory/FooterGridCategory';
// import noImage from '@package/images/logo-assign-negative.png';
import { siteVersion } from '@package/util/version';
import './footer.less';
import { useGoogleAnalytics } from 'volto-google-analytics';
import { useAcceptedCookieBar } from '@package/components/GovBr/BRCookiebar/useAcceptedCookieBar';

/**
 * Component to display the footer.
 * @function Footer
 * @returns {string} Markup of the component
 */
const Footer = () => {
  const accepted = useAcceptedCookieBar();
  useGoogleAnalytics(accepted);
  return (
    <footer className="br-footer">
      <div className="ui container py-lg-5 py-sm-2">
        <div className="logo d-flex p-0 ">
          <Image src={logoFooter} />
        </div>
        <div className="col py-3">
          <span className="br-divider d-md-none"></span>
        </div>
        <GridCategory />
        <div className="redes-assina">
          <div className="col">
            <SocialMedia />
          </div>
          <div className="col assinauft">
            <h4>Universidade Federal do Tocantins</h4>
          </div>
        </div>
        <div>
          <div className="col-lg py-4 info">
            <div className="br-divider pb-2"></div>
            <p className="text-align-center">
              Todo conteúdo do site está publicado sob a licença <b>Creative Commons</b> - {`${siteVersion}`}.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
