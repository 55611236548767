import { Tab } from 'semantic-ui-react';
import _ from 'lodash';
import React from 'react';

const DayTabs = ({ days }) => {
  const panes = _.reduce(
    Object.keys(days),
    (retorno, key) => {
      const row = days?.[key];
      if (Object.keys(row).length === 0) return retorno;
      if (
        Object.keys(row.MANHA).length === 0 &&
        Object.keys(row.TARDE).length === 0 &&
        Object.keys(row.NOITE).length === 0
      ) {
        return retorno;
      }
      retorno.push({
        menuItem: row.LABEL,
        render: () => (
          <Tab.Pane attached={false}>
            <table className="ui celled table fixed slate-table-block">
              <tbody className="">
                {_.map(
                  ['MANHA', 'TARDE', 'NOITE'],
                  (turno) =>
                    row?.[turno] &&
                    Object.keys(row?.[turno]).length > 0 && (
                      <tr className="">
                        <td className="left aligned middle aligned">
                          <p style={{ fontWeight: 600 }}>
                            Das {row?.[turno]?.HR_INICIO} às {row?.[turno]?.HR_FIM}
                          </p>
                        </td>
                        <td className="left aligned middle aligned">
                          <p>{row?.[turno]?.['NOME_DISCIPLINA']}</p>
                        </td>
                        <td className="left aligned middle aligned">
                          <p>{row?.[turno]?.['NOME_DOCENTE']}</p>
                        </td>
                        <td className="left aligned middle aligned">
                          <p>{row?.[turno]?.['LOCAL']}</p>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </Tab.Pane>
        ),
      });
      return retorno;
    },
    [],
  );

  return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
};

export default DayTabs;
