/**
 * Menu reducer.
 * @module reducers/menu/index
 */
import { map } from 'lodash';

import { flattenToAppURL } from '@plone/volto/helpers';

import { GET_STRUCTURE } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  structure: [],
  root: null,
  loaded: false,
  loading: false,
};

/**
 * Menu reducer.
 * @function menu itens
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function structure(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_STRUCTURE}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case `${GET_STRUCTURE}_SUCCESS`:
      return {
        ...state,
        structure: map(action.result.structure, (item, index) => ({
          level: index,
          label: item.title,
          path: flattenToAppURL(item['@id']),
        })),
        loading: false,
        loaded: true,
        error: null,
      };

    case `${GET_STRUCTURE}_FAIL`:
      return {
        ...state,
        structure: null,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}
