import React from 'react';

import { StyledCircleButton, StyledDownloadLinkButton, StyledPrimaryButton, StyledSecondaryButton } from './style';

function buttonFactory(Button, props) {
  const { children, ...rest } = props;
  return (
    <Button {...rest} type="button">
      {children}
    </Button>
  );
}

export const CircleButton = (props) => buttonFactory(StyledCircleButton, props);

export const PrimaryButton = (props) => buttonFactory(StyledPrimaryButton, props);

export const SecondaryButton = (props) => buttonFactory(StyledSecondaryButton, props);

export const DownloadLinkButton = (props) => buttonFactory(StyledDownloadLinkButton, props);

export const nope = null;
