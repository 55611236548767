/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import ExternalImageWidget from '@package/components/Widgets/ExternalImage';
import ExternalFileWidget from '@package/components/Widgets/ExternalFile';
import ImageInputWidget from '@package/components/Widgets/ImageInputWidget';
// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import blocks from '@package/components/Blocks';
import Libras from '@plonegovbr/volto-vlibras/components/Libras';
import PessoasObjectListWidget from './components/Blocks/ProfileBlock/ObjectListWidget';
import ServicesObjectListWidget from './components/Blocks/ServicesBlock/ObjectListWidget';
import { ALFRESCOLINK } from './editor/plugins/constants';
import installAlfrescolinkPlugin from './editor/plugins/AlfrescolinkPlugin';
import {
  ContactView,
  ServiceView,
  MapWidget,
  OpeningHoursWidget,
  WhatsappAvatarWidget,
  ProfileCropWidget,
} from './components';
import ServiceTemplate from './customizations/volto/components/manage/Blocks/Listing/ServiceTemplate';
import GridTemplate from './customizations/volto/components/manage/Blocks/Listing/GridTemplate';
import { FULLLINK } from './editor/plugins/FullLinkPlugin/constants';
import installFullLinkPlugin from './editor/plugins/FullLinkPlugin';
import PhoneMaskWidget from './components/Widgets/PhoneMask';
import AlignWidget from './components/Widgets/AlignWidget';
import SelectIconWidget from './components/Widgets/SelectIcon';
import IconSelectorWidget from './components/Widgets/IconSelectorWidget';
import CarouselTemplate from './customizations/volto/components/manage/Blocks/Listing/CarouselTemplate';
import InformeTemplate from './customizations/volto/components/manage/Blocks/Listing/InformeTemplate';
import ChoiceMapWidget from './components/Widgets/ChoiceMapWidget';
import NewsItemsTemplate from './customizations/volto/components/manage/Blocks/Listing/NewsItemsTemplate';

export default function applyConfig(config) {
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      contact: ContactView,
      service: ServiceView,
    },
  };

  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: Libras,
      },
    ],
  };

  config.blocks.blocksConfig['listing'].variations = [
    ...config.blocks.blocksConfig['listing'].variations,
    {
      id: 'carousel',
      title: 'Carousel UFT',
      template: CarouselTemplate,
    },
    {
      id: 'informe',
      title: 'Comunicado',
      template: InformeTemplate,
    },
    {
      id: 'service',
      title: 'Serviço',
      template: ServiceTemplate,
    },
    {
      id: 'grid_template',
      title: 'Grid',
      template: GridTemplate,
    },
    {
      id: 'news_items',
      title: 'Notícias',
      template: NewsItemsTemplate,
    },
  ];

  config.blocks.groupBlocksOrder = [
    { id: 'mostUsed', title: 'Most used' },
    { id: 'uft', title: 'UFT' },
    { id: 'text', title: 'Text' },
    { id: 'media', title: 'Media' },
    { id: 'common', title: 'Common' },
  ];
  // remover bloco de imagem padrao do volto
  let defaultBlocks = { ...config.blocks.blocksConfig };
  delete defaultBlocks.image;
  defaultBlocks.imagesGrid = {
    ...defaultBlocks.imagesGrid,
    gridAllowedBlocks: ['alfrescoImageBlock'],
  };
  config.blocks.blocksConfig = {
    ...defaultBlocks,
    ...blocks,
  };
  config.blocks.blocksConfig.__grid.gridAllowedBlocks = [
    ...config.blocks.blocksConfig.__grid.gridAllowedBlocks,
    'newsBlock',
    'buttonBlock',
    'alfrescoImageBlock',
    'cardBlock',
  ];

  config.blocks.blocksConfig.group.schema.properties.allowedBlocks.items.choices = [
    ...config.blocks.blocksConfig.group.schema.properties.allowedBlocks.items.choices,
    ['alfrescoImageBlock', 'Imagem UFTDocs'],
    ['alfrescoListBlock', 'Arquivos UFTDocs'],
  ];

  config.widgets = {
    ...config.widgets,
    widget: {
      ...config.widgets.widget,
      choiceMapWidget: ChoiceMapWidget,
      external_image: ExternalImageWidget,
      profileBlock_list: PessoasObjectListWidget,
      servicesBlock_list: ServicesObjectListWidget,
      avatar: WhatsappAvatarWidget,
      profile: ProfileCropWidget,
      phone_mask: PhoneMaskWidget,
      inner_align: AlignWidget,
    },
    id: {
      ...config.widgets.id,
      external_file: ExternalFileWidget,
      location_map: MapWidget,
      opening_hours: OpeningHoursWidget,
      workflow_image: ImageInputWidget,
      icon: SelectIconWidget,
    },
  };

  config.settings.slate.toolbarButtons = [
    ...(config.settings.slate.toolbarButtons || []),
    ALFRESCOLINK,
    'underline',
    'styleMenu',
  ];
  config.settings.slate.expandedToolbarButtons = [
    ...(config.settings.slate.expandedToolbarButtons || []),
    ALFRESCOLINK,
  ];
  config = installAlfrescolinkPlugin(config);

  config.settings.slate.toolbarButtons = [...(config.settings.slate.toolbarButtons || []), FULLLINK];
  config.settings.slate.expandedToolbarButtons = [...(config.settings.slate.expandedToolbarButtons || []), FULLLINK];
  config = installFullLinkPlugin(config);

  config.settings.apiExpanders = [
    ...config.settings.apiExpanders,
    {
      match: '',
      GET_CONTENT: ['settings'],
    },
  ];

  // Sobrescrever o título do bloco texto Draft-js
  config.blocks.blocksConfig.text = {
    ...config.blocks.blocksConfig.text,
    title: 'Texto Draft-js',
  };

  // Sobrescrever o título do bloco slate
  config.blocks.blocksConfig.slate = {
    ...config.blocks.blocksConfig.slate,
    title: 'Texto',
  };

  config.blocks.requiredBlocks = [];

  config = IconSelectorWidget(config);

  if (__SERVER__) {
    const express = require('express');
    const middleware = express.Router();

    middleware.all('/test-middleware7', function (req, res, next) {
      let envVariables = '';
      for (const [key, value] of Object.entries(process.env)) {
        envVariables += `---------${key}: ${value}----------\n`;
      }
      res.set('Content-Type', 'text/plain');
      res.send('Hello world\n' + envVariables);
      // res.send('Hello world\n');
    });
    middleware.id = 'test-middleware';
    config.settings.expressMiddleware = [middleware, ...config.settings.expressMiddleware];
  }

  return config;
}
