const HeroSchema = () => ({
  title: 'UFT Hero',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [
        'preHeadingText',
        'headingText',
        'subHeadingText',
        'showActionButton',
        'actionButtonText',
        'actionButtonUrl',
        'external_link',
        'openLinkInNewPage',
        'foregroundImage',
        'margin',
      ],
    },
  ],
  properties: {
    preHeadingText: {
      title: 'Antes do Título',
      type: 'string',
    },
    headingText: {
      title: 'Título',
      type: 'string',
    },
    subHeadingText: {
      title: 'Abaixo do Título',
      type: 'string',
    },
    showActionButton: {
      title: 'Ativar Botão',
      type: 'boolean',
    },
    actionButtonText: {
      title: 'Texto do botão',
      type: 'string',
    },
    actionButtonUrl: {
      title: 'Link interno',
      widget: 'object_browser',
      mode: 'link',
      selectedItemAttrs: ['title', 'description', 'image_scales', 'effective'],
      allowExternals: true,
    },
    external_link: {
      title: 'URL Externa',
      description: 'A URL deve começar com http:// ou https://',
    },
    openLinkInNewPage: {
      title: 'Abrir em nova janela',
      type: 'boolean',
    },
    foregroundImage: {
      title: 'Imagem',
      widget: 'external_image',
    },
    margin: {
      title: 'Margens',
      type: 'string',
      defaultMessage: '45px 0px 85px 0px',
    },
  },
  required: [],
});

export default HeroSchema;
