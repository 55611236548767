import React, { useState, useEffect } from 'react';
import { defaultCategories, loadCategories } from '../footerData';
import { useHistory } from 'react-router-dom';

const GridCategory = () => {
  const [openPanels, setOpenPanels] = useState([]);
  const [isDesktop, setIsDesktop] = useState(false);

  const history = useHistory();
  const categories = isDesktop ? loadCategories() : defaultCategories;

  const togglePanel = (e, index, link) => {
    e.preventDefault();
    if (isDesktop) {
      history.replace(link);
    } else {
      const newOpenPanels = [...openPanels];
      newOpenPanels[index] = !newOpenPanels[index];
      setOpenPanels(newOpenPanels);
    }
  };

  let col = Math.round(12 / categories.length);
  col = col > 4 ? 4 : col < 1 ? 1 : col;

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window?.innerWidth >= 991);
    };

    if (typeof window !== 'undefined') {
      handleResize(); // Set initial state
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useEffect(() => {
    if (categories) {
      setOpenPanels(categories.map(() => isDesktop));
    }
  }, [isDesktop]);

  if (!categories || categories.length === 0) return <div></div>;

  return (
    <div className="br-list horizontal" data-toggle="data-toggle" data-sub="data-sub">
      {categories.map((category, index) => (
        <div className={`col-${col}`} key={index}>
          <a
            href={'#/'}
            data-link={category.link}
            onClick={(e) => {
              togglePanel(e, index, category.link);
            }}
            className="br-item header"
          >
            <div className="content text-down-01 text-bold text-uppercase">{category.name}</div>
            <div className="support">
              <i className={`fas ${openPanels[index] ? 'fa-angle-down' : 'fa-angle-up'}`} aria-hidden="true"></i>
            </div>
          </a>
          {openPanels[index] && (
            <div className="br-list">
              {category.data.map((row, subIndex) => (
                <a className="br-item" key={`${category.name}-${subIndex}`} href={row.link || '#/'}>
                  <div className="content">{row.name}</div>
                </a>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GridCategory;
