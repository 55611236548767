import React from 'react';

const steps = [
  {
    selector: '[class="header-menu-trigger"]',
    content: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '6',
        }}
      >
        <span aria-label="Para abrir o Menu">Para abrir o Menu</span>

        <div style={{ flexDirection: 'row' }}>
          <span>Clique no ícone sanduiche </span>
          <i className="fa-solid fa-bars" style={{ padding: '10px 10px 5px 10px' }}></i>
        </div>
      </div>
    ),
    styles: {
      popover: (base) => ({
        ...base,
        fontSize: 18,
        margin: 0,
        color: '#ffffff',
        backgroundColor: 'rgb(21,91,203)',
      }),
    },
  },
];

export default steps;
