import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { difference } from '@plone/volto/helpers';
import { usePrevious /* , replaceItemOfArray */ } from '@plone/volto/helpers';

import { servicesSchema } from './schema';

export const replaceItemOfArray = (array, index, value) => Object.assign([...array], { [index]: value });

const ServicesData = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();
  const { services } = props.data;
  const previous = usePrevious(services);
  const schema = servicesSchema({ ...props, intl });

  React.useEffect(() => {
    if (previous) {
      const diff = difference(services, previous);
      const index = diff.findIndex((val) => val);
      if (diff[index]?.href?.[0]) {
        onChangeBlock(block, {
          ...data,
          services: replaceItemOfArray(data.services, index, {
            ...data.services[index],
          }),
        });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [services]);

  return (
    <BlockDataForm
      schema={schema}
      title={schema.services}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      onChangeBlock={onChangeBlock}
      block={block}
    />
  );
};

ServicesData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default ServicesData;
