import { defineMessages } from 'react-intl';

const messages = defineMessages({
  eventBlock: {
    id: 'Destaques',
    defaultMessage: 'Eventos UFT',
  },
  event: {
    id: 'Ref',
    defaultMessage: 'Caminho das Notícias',
  },
  tags: {
    id: 'Tags',
    defaultMessage: 'Tags',
  },
  text: {
    id: 'Text',
    defaultMessage: 'Título',
  },
  LinkTo: {
    id: 'Link to complete',
    defaultMessage: 'Link agenda completa',
  },
});

export const eventSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.eventBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['text', 'href', 'tags', 'linkTo'],
      },
    ],
    properties: {
      href: {
        title: props.intl.formatMessage(messages.event),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'image_scales', 'effective', 'review_state'],
        widgetOptions: {
          pattern_options: { selectableTypes: ['Document'] },
        },
        allowExternals: false,
      },
      text: {
        title: props.intl.formatMessage(messages.text),
      },
      tags: {
        type: 'array',
        widget: 'token',
        title: props.intl.formatMessage(messages.tags),
        items: {
          vocabulary: { '@id': 'plone.app.vocabularies.Keywords' },
        },
      },
      linkTo: {
        title: props.intl.formatMessage(messages.LinkTo),
      },
    },
    required: ['text'],
  };
};
