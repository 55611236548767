import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import Slider from 'react-slick';
import Moment from 'moment';
import './carousel.less';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

const CarouselTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  const PrevArrow = ({ className, style, onClick }) => '';

  const NextArrow = ({ className, style, onClick }) => '';

  const sliderRef = React.useRef();

  return (
    <>
      <div className="items py-4">
        <div className="listing carousel">
          <Slider
            ref={sliderRef}
            dots
            infinite
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            autoplay={true}
            draggable={false}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
          >
            {items.map((item) => (
              <div className="listing-item" key={item['@id']}>
                <ConditionalLink item={item} condition={!isEditMode}>
                  <div className="listing-body flex-grow-1">
                    <div key={item['@id']}>
                      <div className="listing-carousel-item">
                        <div className="highlight-image-wrapper">
                          <img
                            src={
                              item.image_scales?.image?.[0]?.scales?.preview?.download
                                ? item.image_scales.image[0].scales.preview.download
                                : DefaultImageSVG
                            }
                            alt={item.title}
                            className="listing-img"
                          />
                        </div>
                        <div className="listing-carousel-item-title">
                          <div className="listing-carousel-title">
                            <h3 className="text-up-02">{item?.title}</h3>
                            {item?.title && (
                              <span className="listing-carousel-item-created">
                                {Moment(item?.created_import ?? item?.created).format('DD/MM/YYYY - HH:mm')}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ConditionalLink>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/*<div className="items">*/}
      {/*    <div className="listing-item">*/}
      {/*        <div className="listing-body">*/}
      {/*           <img*/}
      {/*              src="https://docs.uft.edu.br/proxy/alfresco-noauth/api/internal/shared/node/HJ_Vx6hXRUeobif4lJWtuQ/content/thumbnails/imgpreview?c=force&lastModified=imgpreview%3A1698682706469"*/}
      {/*              alt="Carousel"*/}
      {/*              className="listing-img-carousel"*/}
      {/*            />*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}

      {link && <div className="footer">{link}</div>}
    </>
  );
};
CarouselTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default CarouselTemplate;
