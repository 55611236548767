import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { listUftDisciplines } from '../../../actions/apiUft/apiUft';
import _ from 'lodash';
import PeriodAccordion from './PeriodAccordion';
import './styles.css';
const DisciplinesBlockView = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const disciplines = useSelector((state) => state.apiUft.subrequests?.[data.idCourse]?.disciplines);

  React.useEffect(() => {
    if (!!data.period && !!data.year && !!data.idCourse) {
      dispatch(listUftDisciplines(data.year, data.period, data.idCourse, data.idCourse));
    }
  }, [data, dispatch]);

  if (!disciplines || !data.period || !data.year || !data.idCourse) {
    return 'Aguardando seleção dos parâmetros ...';
  }
  if (disciplines.length === 0) {
    return 'Nenhuma disciplina encontrada para os parâmetros selecionados.';
  }
  let ordenado = {};

  if (disciplines.length > 0) {
    ordenado = _.reduce(
      disciplines,
      (acc, d) => {
        const periodo = d['PERIODO'] || 0;
        const diaSemana = d['DIA_SEMANA_ITEM'] || 0;
        // se nao tiver a chave do periodo no acc criar a chave
        if (!acc[periodo]) {
          acc[periodo] = {
            2: {
              LABEL: 'Segunda-feira',
              MANHA: {},
              TARDE: {},
              NOITE: {},
            },
            3: {
              LABEL: 'Terça-feira',
              MANHA: {},
              TARDE: {},
              NOITE: {},
            },
            4: {
              LABEL: 'Quarta-feira',
              MANHA: {},
              TARDE: {},
              NOITE: {},
            },
            5: {
              LABEL: 'Quinta-feira',
              MANHA: {},
              TARDE: {},
              NOITE: {},
            },
            6: {
              LABEL: 'Sexta-feira',
              MANHA: {},
              TARDE: {},
              NOITE: {},
            },
            7: {
              LABEL: 'Sábado',
              MANHA: {},
              TARDE: {},
              NOITE: {},
            },
          };
        }

        const inicio = parseInt(d['HR_INICIO'].split(':')[0]);
        const fim = parseInt(d['HR_FIM'].split(':')[0]);
        const turno = inicio >= 7 && fim <= 12 ? 'MANHA' : inicio >= 13 && fim <= 18 ? 'TARDE' : 'NOITE';
        // se nao tiver a chave do dia da semana no acc criar a chave
        acc[periodo][diaSemana][turno] = d;
        return acc;
      },
      {},
    );
  }

  return (
    <div>
      <div>
        <h3 className="disciplineHeading">
          {disciplines[0]?.['NOME_UNIDADE']} - {data.year}/{data.period === '201' ? '1' : '2'}
        </h3>
      </div>
      <div className="ui divider"></div>
      <PeriodAccordion periods={ordenado} />
    </div>
  );
};

export default withBlockExtensions(DisciplinesBlockView);
