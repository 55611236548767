import React, { useState } from 'react';
import { withBlockExtensions, expandToBackendURL } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import './event.less';
import DatePicker from '../../GovBr/DatePicker';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { getQueryStringResults } from '@plone/volto/actions/querystringsearch/querystringsearch';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const messages = defineMessages({
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Download do Evento',
  },
});

const EventBlockView = (props) => {
  const { data } = props;
  const [dateSelected, setDateSelected] = useState(Moment(new Date()).format('YYYY-MM-DD'));
  const events = useSelector((state) => state.querystringsearch.subrequests.events?.items);
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const eventProps = data.href !== undefined && data.href.length === 1 ? data.href[0] : null;

  const title = data.text || 'Agenda';

  const redirectToEvent = (event) => {
    history.replace(event['@id']);
  };
  const sevenDays = (value) => {
    const daysAfter = [];
    const daysBefore = [];
    let nowDate = value;
    for (let i = 0; i <= 3; i++) {
      nowDate = Moment(value).add(i, 'days');
      daysAfter.push(nowDate);
    }
    nowDate = value;
    for (let i = 3; i > 0; i--) {
      nowDate = Moment(value).subtract(i, 'days');
      daysBefore.push(nowDate);
    }
    return daysBefore.concat(daysAfter);
  };

  const retriveEvents = (id, tags) => {
    const path = id ? flattenToAppURL(id) : '/';
    const params = {
      metadata_fields: ['start', 'end', 'location'],
      query: [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['Event'],
        },
        {
          i: 'path',
          o: 'plone.app.querystring.operation.string.absolutePath',
          v: path,
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['published'],
        },
        {
          i: 'start',
          o: 'plone.app.querystring.operation.date.lessThan',
          v: Moment(dateSelected).add('1', 'days').format('YYYY-MM-DD'),
        },
        {
          i: 'end',
          o: 'plone.app.querystring.operation.date.largerThan',
          v: Moment(dateSelected).format('YYYY-MM-DD'),
        },
        ...(tags && tags.length > 0
          ? [
              {
                i: 'Subject',
                o: 'plone.app.querystring.operation.selection.any',
                v: tags,
              },
            ]
          : []),
      ],
    };
    return getQueryStringResults(path, params, 'events');
  };

  React.useEffect(() => {
    if ((eventProps && eventProps?.['@id']) || data.tags) {
      dispatch(retriveEvents(eventProps?.['@id'], data.tags));
    }
  }, [dateSelected, dispatch, eventProps]);

  const onChangeDate = (value) => {
    setDateSelected(Moment(value[0]).format('YYYY-MM-DD'));
  };
  const listDate = sevenDays(dateSelected);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    appendDots: (dots) => (
      <div className="slider-dots">
        <ul> {dots} </ul>
      </div>
    ),
  };

  const renderEventList = () => {
    if (!events || events.length === 0) {
      return <div className="event-detail empty">Sem compromissos</div>;
    } else {
      return (
        <>
          {events.length > 3 ? (
            <Slider className="event-detail" {...settings}>
              {renderEvents(events)}
            </Slider>
          ) : (
            <div className="event-detail">{renderEvents(events)}</div>
          )}
        </>
      );
    }
  };
  const renderEvents = (events) =>
    events.map((e, i) => (
      <div className="event-detail-item" key={i} role={'presentation'} onClick={() => redirectToEvent(e)}>
        <Popup
          trigger={<div className="event-detail-item-title">{e.title}</div>}
          content={e.title}
          position="top center"
        />

        <div className="event-detail-item-date">
          <div className="event-time">
            <i
              className="far fa-clock"
              style={{
                display: 'inline-block',
              }}
            ></i>
            <time className="event-time-start">{Moment(e.start).format('HH:mm')}</time>-
            <time className="event-time-end">{Moment(e.end).format('HH:mm')}</time>
          </div>
        </div>
        <div className="event-detail-item-footer">
          {e.location && (
            <div className="event-detail-item-location">
              <i className="fas fa-map-marker-alt fa-lg"></i>
              <span>{e.location}</span>
            </div>
          )}
          <div className="download-event">
            <i className="fas fa-calendar fa-lg"></i>
            <a
              className="ics-download"
              target="_blank"
              rel="noreferrer"
              href={`${expandToBackendURL(e['@id'])}/ics_view`}
            >
              {intl.formatMessage(messages.downloadEvent)}
            </a>
          </div>
        </div>
      </div>
    ));
  if ((!eventProps || !eventProps['@id']) && !data.tags) {
    return 'Aguardando seleção da fonte de eventos ...';
  }
  return (
    <div className="event-container">
      <span className="event-title">{title}</span>
      <div className="event-date hidden-mobile">
        <DatePicker
          id="dateSelected"
          name="dateSelected"
          value={Moment(dateSelected).format('YYYY-MM-DD')}
          placeholder="Escolha uma data"
          onChange={onChangeDate}
        />
      </div>
      <div className="event-list">
        {listDate &&
          listDate.map((date, index) => {
            const daysHiddenMobile = [0, 1, 5, 6];
            return (
              <div
                aria-hidden="true"
                className={`event-day ${Moment(dateSelected).format('DD') === Moment(date).format('DD') && 'active'} ${
                  daysHiddenMobile.includes(index) && 'hidden-mobile'
                }`}
                onClick={() => setDateSelected(Moment(date).format('YYYY-MM-DD'))}
                key={index}
              >
                <div className="event-day-number">{Moment(date).format('DD')}</div>
                <span className="event-day-string">{Moment(date).format('ddd')}</span>
              </div>
            );
          })}
      </div>
      {renderEventList()}
      <a href={data.linkTo} className="event-show-more">
        Agenda Completa
      </a>
    </div>
  );
};

export default withBlockExtensions(EventBlockView);
