import { defineMessages } from 'react-intl';
import Moment from 'moment';

const messages = defineMessages({
  mainDisciplines: {
    id: 'Horario',
    defaultMessage: 'Horário das Disciplinas',
  },
  year: {
    id: 'Year',
    defaultMessage: 'Ano',
  },
  period: {
    id: 'Period',
    defaultMessage: 'Período',
  },
  idCourse: {
    id: 'IdCourse',
    defaultMessage: 'Curso',
  },
});

export const disciplinesSchema = (props) => {
  const year = Moment().year();
  return {
    title: props.intl.formatMessage(messages.mainDisciplines),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['idCourse', 'year', 'period'],
      },
    ],
    properties: {
      idCourse: {
        title: props.intl.formatMessage(messages.idCourse),
        vocabulary: { '@id': 'portal_uft.disciplines.courses' },
      },
      year: {
        type: 'choices',
        title: props.intl.formatMessage(messages.year),
        choices: [
          [year - 1, year - 1],
          [year, year],
        ],
        noValueOption: false,
      },
      period: {
        type: 'choices',
        title: props.intl.formatMessage(messages.period),
        choices: [
          ['201', '1º semestre'],
          ['202', '2º semestre'],
        ],
        noValueOption: false,
      },
    },
    required: ['idCourse', 'year', 'period'],
  };
};
