/**
 * ExternalFileWidget component.
 * @module components/Widgets/ExternalFile
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { addAppURL, isInternalURL, flattenToAppURL, URLUtils } from '@plone/volto/helpers';
import clearSVG from '@plone/volto/icons/clear.svg';
import fileSVG from '@plone/volto/icons/file.svg';
import ExploreDocs from '@package/components/ExploreDocs';

/** Widget to edit urls
 *
 * This is the default widget used for the `remoteUrl` field. You can also use
 * it by declaring a field like:
 *
 * ```jsx
 * {
 *  title: "URL",
 *  widget: 'url',
 * }
 * ```
 */
const ExternalFileWidget = (props) => {
  const { id, onChange } = props;
  const rawValue = props.value ? props.value.download : '';
  const [value, setValue] = useState(flattenToAppURL(rawValue));
  const [isInvalid, setIsInvalid] = useState(false);
  /**
   * Clear handler
   * @method clear
   * @param {Object} value Value
   * @returns {undefined}
   */
  const clear = () => {
    setValue('');
    onChange(id, undefined);
  };

  const onChangeValue = (_value) => {
    let newValue = _value.url ? _value.url : _value;
    if (newValue?.length > 0) {
      if (isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(newValue))) {
        setIsInvalid(false);
      }

      if (isInternalURL(newValue)) {
        newValue = flattenToAppURL(newValue);
      }
    }

    setValue(newValue);

    newValue = isInternalURL(newValue) ? addAppURL(newValue) : newValue;

    if (!isInternalURL(newValue) && newValue.length > 0) {
      const checkedURL = URLUtils.checkAndNormalizeUrl(newValue);
      newValue = checkedURL.url;
      if (!checkedURL.isValid) {
        setIsInvalid(true);
      }
    }

    onChange(id, newValue === '' ? undefined : newValue);
  };

  return (
    <FormFieldWrapper {...props} className="url wide">
      {value ? (
        <Button.Group>
          <Icon name={fileSVG} size="30px" alt={value} />
          <Button
            basic
            className="cancel"
            aria-label="clearUrlBrowser"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clear();
            }}
          >
            <Icon name={clearSVG} size="30px" />
          </Button>
        </Button.Group>
      ) : (
        <ExploreDocs onChangeDocument={onChangeValue} mimeTypes={['application/pdf']} />
      )}
    </FormFieldWrapper>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
ExternalFileWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ExternalFileWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  minLength: null,
  maxLength: null,
};

export default ExternalFileWidget;
