import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import { mergeSchemas } from './mergeSchema';

const messages = defineMessages({
  Profile: {
    id: 'Perfil',
    defaultMessage: 'Perfil',
  },
  nome: {
    id: 'Nome',
    defaultMessage: 'Nome',
  },
  cargo: {
    id: 'Cargo',
    defaultMessage: 'Cargo',
  },
  email: {
    id: 'Email',
    defaultMessage: 'E-mail',
  },
  lattes: {
    id: 'Lattes',
    defaultMessage: 'Lattes',
  },
  imagem: {
    id: 'Imagem',
    defaultMessage: 'Imagem',
  },
  pessoa: {
    id: 'Pessoa',
    defaultMessage: 'Pessoa',
  },
  pessoas: {
    id: 'Pessoas',
    defaultMessage: 'Pessoas',
  },
  addPessoa: {
    id: 'Adicionar Pessoa',
    defaultMessage: 'Adicionar Pessoa',
  },
});

export const pessoaSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.pessoa),
      addMessage: props.intl.formatMessage(messages.addPessoa),
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['nome', 'cargo', 'email', 'lattes', 'imagem'],
        },
      ],

      properties: {
        nome: {
          title: props.intl.formatMessage(messages.nome),
        },
        cargo: {
          title: props.intl.formatMessage(messages.cargo),
        },
        email: {
          title: props.intl.formatMessage(messages.email),
        },
        lattes: {
          title: props.intl.formatMessage(messages.lattes),
        },
        imagem: {
          title: props.intl.formatMessage(messages.imagem),
          widget: 'profile',
        },
      },
      required: ['nome', 'cargo'],
    },
    config.blocks.blocksConfig.profileBlock.extensions?.blockSchema || {},
  );

export const pessoasSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.Profile),
      block: 'profileBlock',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['ordenar', 'pessoas'],
        },
      ],
      properties: {
        ordenar: {
          title: 'ordenar A-Z',
          type: 'boolean',
        },
        pessoas: {
          widget: 'profileBlock_list',
          title: props.intl.formatMessage(messages.pessoas),
          schema: pessoaSchema(props),
        },
      },
      required: [],
    },
    config.blocks.blocksConfig.profileBlock.extensions?.blockSchema || {},
  );
