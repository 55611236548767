import React, { useEffect, useState } from 'react';

import { Dropdown } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser, logout } from '@plone/volto/actions';
import './style.css';
/**
 * Componente de Avatar da header
 * @returns {JSX.Element}
 * @constructor
 */
const AvatarComponent = ({ token }) => {
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const userId = token ? jwtDecode(token).sub : '';
  const isLogged = userId && 'fullname' in user;

  const pushToLogout = () => dispatch(logout());

  useEffect(() => {
    if (userId && !isLogged) {
      dispatch(getUser(userId));
    }
  }, [dispatch, isLogged, userId]);
  const name = user?.fullname || user?.username;

  const trigger = () => {
    const firstName = name.split(' ')[0] || '';
    const firstLetterName = firstName[0] || '';
    return (
      <div className="br-sign-in" style={{ minHeight: 40 }}>
        <span className="br-avatar" title={name}>
          <span className="content bg-orange-vivid-30 text-pure-0" style={{ height: 35, width: 35 }}>
            {firstLetterName}
          </span>
        </span>
        <span className="ml-2 text-gray-80 text-weight-regular">
          Olá, <span className="text-weight-semi-bold">{firstName}</span>
        </span>

        <i className={`fas fa-caret-${selected ? 'up' : 'down'}`} />
      </div>
    );
  };

  if (!isLogged)
    return (
      <div className="header-login">
        <div className="header-sign-in">
          <a href="/login" className="br-sign-in small" type="button" data-trigger="login">
            <i className="fas fa-user" size="12px" />
            &nbsp;Entrar
          </a>
        </div>
      </div>
    );

  return (
    <div>
      <Dropdown trigger={trigger()} onOpen={() => setSelected(true)} onClose={() => setSelected(false)}>
        <Dropdown.Menu className="br-list" style={{ minWidth: '200px' }}>
          <Dropdown.Header
            content={
              <div>
                {name}
                <br />
                {user?.email}
              </div>
            }
          />

          <Dropdown.Item className="br-item" onClick={pushToLogout} text={'Sair'} />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
AvatarComponent.propTypes = {};

export default AvatarComponent;
