import PropTypes from 'prop-types';
import React from 'react';
import CardService from '../../../Card';

const GridServices = ({ services }) => {
  if (!services || services.length === 0) return <div></div>;

  return (
    <div className="br-list horizontal" data-toggle="data-toggle" data-sub="data-sub">
      {services.map((service, index) => (
        <div className="col-3" key={index}>
          <CardService card={service} />
        </div>
      ))}
    </div>
  );
};
GridServices.defaultProps = {
  services: [],
};
GridServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
      link: PropTypes.string,
    }),
  ).isRequired,
};

export default GridServices;
