export const iconList = {
  accessibility: {
    title: 'Accessibility',
    list: [
      { name: 'address-card' },
      { name: 'audio-description' },
      { name: 'braille' },
      { name: 'circle-info' },
      { name: 'circle-question' },
      { name: 'closed-captioning' },
      { name: 'ear-deaf' },
      { name: 'ear-listen' },
      { name: 'eye' },
      { name: 'eye-low-vision' },
      { name: 'fingerprint' },
      { name: 'hands' },
      { name: 'hands-asl-interpreting' },
      { name: 'handshake-angle' },
      { name: 'person-cane' },
      { name: 'person-walking-with-cane' },
      { name: 'phone-volume' },
      { name: 'question' },
      { name: 'tty' },
      { name: 'universal-access' },
      { name: 'wheelchair' },
      { name: 'wheelchair-move' },
    ],
  },
  alert: {
    title: 'Alert',
    list: [
      { name: 'bell' },
      { name: 'bell-slash' },
      { name: 'circle-exclamation' },
      { name: 'circle-radiation' },
      { name: 'exclamation' },
      { name: 'question' },
      { name: 'radiation' },
      { name: 'skull-crossbones' },
      { name: 'triangle-exclamation' },
    ],
  },
  alphabet: {
    title: 'Alphabet',
    list: [
      { name: 'a' },
      { name: 'address-card' },
      { name: 'b' },
      { name: 'c' },
      { name: 'circle-h' },
      { name: 'd' },
      { name: 'e' },
      { name: 'f' },
      { name: 'g' },
      { name: 'h' },
      { name: 'i' },
      { name: 'j' },
      { name: 'k' },
      { name: 'l' },
      { name: 'm' },
      { name: 'n' },
      { name: 'o' },
      { name: 'p' },
      { name: 'q' },
      { name: 'r' },
      { name: 's' },
      { name: 'square-h' },
      { name: 't' },
      { name: 'u' },
      { name: 'v' },
      { name: 'w' },
      { name: 'x' },
      { name: 'y' },
      { name: 'z' },
    ],
  },
  animals: {
    title: 'Animals',
    list: [
      { name: 'bugs' },
      { name: 'cat' },
      { name: 'cow' },
      { name: 'crow' },
      { name: 'dog' },
      { name: 'dove' },
      { name: 'dragon' },
      { name: 'feather' },
      { name: 'feather-pointed' },
      { name: 'fish' },
      { name: 'fish-fins' },
      { name: 'frog' },
      { name: 'hippo' },
      { name: 'horse' },
      { name: 'horse-head' },
      { name: 'kiwi-bird' },
      { name: 'locust' },
      { name: 'mosquito' },
      { name: 'otter' },
      { name: 'paw' },
      { name: 'shield-cat' },
      { name: 'shield-dog' },
      { name: 'shrimp' },
      { name: 'spider' },
      { name: 'worm' },
    ],
  },
  arrows: {
    title: 'Arrows',
    list: [
      { name: 'angle-down' },
      { name: 'angle-left' },
      { name: 'angle-right' },
      { name: 'angle-up' },
      { name: 'angles-down' },
      { name: 'angles-left' },
      { name: 'angles-right' },
      { name: 'angles-up' },
      { name: 'arrow-down' },
      { name: 'arrow-down-1-9' },
      { name: 'arrow-down-9-1' },
      { name: 'arrow-down-a-z' },
      { name: 'arrow-down-long' },
      { name: 'arrow-down-short-wide' },
      { name: 'arrow-down-up-across-line' },
      { name: 'arrow-down-up-lock' },
      { name: 'arrow-down-wide-short' },
      { name: 'arrow-down-z-a' },
      { name: 'arrow-left' },
      { name: 'arrow-left-long' },
      { name: 'arrow-pointer' },
      { name: 'arrow-right' },
      { name: 'arrow-right-arrow-left' },
      { name: 'arrow-right-from-bracket' },
      { name: 'arrow-right-long' },
      { name: 'arrow-right-to-bracket' },
      { name: 'arrow-rotate-left' },
      { name: 'arrow-rotate-right' },
      { name: 'arrow-trend-down' },
      { name: 'arrow-trend-up' },
      { name: 'arrow-turn-down' },
      { name: 'arrow-turn-up' },
      { name: 'arrow-up' },
      { name: 'arrow-up-1-9' },
      { name: 'arrow-up-9-1' },
      { name: 'arrow-up-a-z' },
      { name: 'arrow-up-from-bracket' },
      { name: 'arrow-up-long' },
      { name: 'arrow-up-right-dots' },
      { name: 'arrow-up-right-from-square' },
      { name: 'arrow-up-short-wide' },
      { name: 'arrow-up-wide-short' },
      { name: 'arrow-up-z-a' },
      { name: 'arrows-down-to-line' },
      { name: 'arrows-left-right' },
      { name: 'arrows-left-right-to-line' },
      { name: 'arrows-rotate' },
      { name: 'arrows-spin' },
      { name: 'arrows-split-up-and-left' },
      { name: 'arrows-to-circle' },
      { name: 'arrows-to-dot' },
      { name: 'arrows-to-eye' },
      { name: 'arrows-turn-right' },
      { name: 'arrows-turn-to-dots' },
      { name: 'arrows-up-down' },
      { name: 'arrows-up-down-left-right' },
      { name: 'arrows-up-to-line' },
      { name: 'caret-down' },
      { name: 'caret-left' },
      { name: 'caret-right' },
      { name: 'caret-up' },
      { name: 'chevron-down' },
      { name: 'chevron-left' },
      { name: 'chevron-right' },
      { name: 'chevron-up' },
      { name: 'circle-arrow-down' },
      { name: 'circle-arrow-left' },
      { name: 'circle-arrow-right' },
      { name: 'circle-arrow-up' },
      { name: 'circle-chevron-down' },
      { name: 'circle-chevron-left' },
      { name: 'circle-chevron-right' },
      { name: 'circle-chevron-up' },
      { name: 'circle-down' },
      { name: 'circle-left' },
      { name: 'circle-right' },
      { name: 'circle-up' },
      { name: 'clock-rotate-left' },
      { name: 'cloud-arrow-down' },
      { name: 'cloud-arrow-up' },
      { name: 'down-left-and-up-right-to-center' },
      { name: 'down-long' },
      { name: 'download' },
      { name: 'left-long' },
      { name: 'left-right' },
      { name: 'location-arrow' },
      { name: 'maximize' },
      { name: 'recycle' },
      { name: 'repeat' },
      { name: 'reply' },
      { name: 'reply-all' },
      { name: 'retweet' },
      { name: 'right-from-bracket' },
      { name: 'right-left' },
      { name: 'right-long' },
      { name: 'right-to-bracket' },
      { name: 'rotate' },
      { name: 'rotate-left' },
      { name: 'rotate-right' },
      { name: 'share' },
      { name: 'share-from-square' },
      { name: 'shuffle' },
      { name: 'sort' },
      { name: 'sort-down' },
      { name: 'sort-up' },
      { name: 'square-arrow-up-right' },
      { name: 'square-caret-down' },
      { name: 'square-caret-left' },
      { name: 'square-caret-right' },
      { name: 'square-caret-up' },
      { name: 'square-up-right' },
      { name: 'turn-down' },
      { name: 'turn-up' },
      { name: 'up-down' },
      { name: 'up-down-left-right' },
      { name: 'up-long' },
      { name: 'up-right-and-down-left-from-center' },
      { name: 'up-right-from-square' },
      { name: 'upload' },
    ],
  },
  astronomy: {
    title: 'Astronomy',
    list: [
      { name: 'globe' },
      { name: 'meteor' },
      { name: 'moon' },
      { name: 'satellite' },
      { name: 'satellite-dish' },
      { name: 'shuttle-space' },
      { name: 'user-astronaut' },
    ],
  },
  automotive: {
    title: 'Automotive',
    list: [
      { name: 'bus' },
      { name: 'bus-simple' },
      { name: 'car' },
      { name: 'car-battery' },
      { name: 'car-burst' },
      { name: 'car-on' },
      { name: 'car-rear' },
      { name: 'car-side' },
      { name: 'car-tunnel' },
      { name: 'caravan' },
      { name: 'charging-station' },
      { name: 'gas-pump' },
      { name: 'gauge' },
      { name: 'gauge-high' },
      { name: 'gauge-simple' },
      { name: 'gauge-simple-high' },
      { name: 'motorcycle' },
      { name: 'oil-can' },
      { name: 'spray-can-sparkles' },
      { name: 'taxi' },
      { name: 'trailer' },
      { name: 'truck' },
      { name: 'truck-field' },
      { name: 'truck-field-un' },
      { name: 'truck-medical' },
      { name: 'truck-monster' },
      { name: 'truck-pickup' },
      { name: 'van-shuttle' },
    ],
  },
  brands: {
    title: 'Brands',
    list: [
      { name: 'facebook' },
      { name: 'facebook-f' },
      { name: 'facebook-messenger' },
      { name: 'square-facebook' },
      { name: 'google' },
      { name: 'google-drive' },
      { name: 'google-play' },
      { name: 'google-plus' },
      { name: 'google-plus-g' },
      { name: 'square-google-plus' },
      { name: 'google-wallet' },
      { name: 'google-scholar' },
      { name: 'google-pay' },
      { name: 'instagram' },
      { name: 'square-instagram' },
      { name: 'linkedin' },
      { name: 'linkedin-in' },
      { name: 'tiktok' },
      { name: 'twitch' },
      { name: 'twitter' },
      { name: 'square-twitter' },
      { name: 'x-twitter' },
      { name: 'square-x-twitter' },
      { name: 'youtube' },
      { name: 'square-youtube' },
    ],
  },
  buildings: {
    title: 'Buildings',
    list: [
      { name: 'archway' },
      { name: 'arrow-right-to-city' },
      { name: 'building' },
      { name: 'building-circle-arrow-right' },
      { name: 'building-circle-check' },
      { name: 'building-circle-exclamation' },
      { name: 'building-circle-xmark' },
      { name: 'building-columns' },
      { name: 'building-flag' },
      { name: 'building-lock' },
      { name: 'building-ngo' },
      { name: 'building-shield' },
      { name: 'building-un' },
      { name: 'building-user' },
      { name: 'building-wheat' },
      { name: 'campground' },
      { name: 'church' },
      { name: 'city' },
      { name: 'dungeon' },
      { name: 'gopuram' },
      { name: 'hospital' },
      { name: 'hospital-user' },
      { name: 'hotel' },
      { name: 'house' },
      { name: 'house-chimney' },
      { name: 'house-chimney-crack' },
      { name: 'house-chimney-medical' },
      { name: 'house-chimney-window' },
      { name: 'house-circle-check' },
      { name: 'house-circle-exclamation' },
      { name: 'house-circle-xmark' },
      { name: 'house-crack' },
      { name: 'house-fire' },
      { name: 'house-flag' },
      { name: 'house-lock' },
      { name: 'house-medical' },
      { name: 'house-medical-circle-check' },
      { name: 'house-medical-circle-exclamation' },
      { name: 'house-medical-circle-xmark' },
      { name: 'house-medical-flag' },
      { name: 'igloo' },
      { name: 'industry' },
      { name: 'kaaba' },
      { name: 'landmark' },
      { name: 'landmark-dome' },
      { name: 'landmark-flag' },
      { name: 'monument' },
      { name: 'mosque' },
      { name: 'mountain-city' },
      { name: 'oil-well' },
      { name: 'place-of-worship' },
      { name: 'school' },
      { name: 'school-circle-check' },
      { name: 'school-circle-exclamation' },
      { name: 'school-circle-xmark' },
      { name: 'school-flag' },
      { name: 'school-lock' },
      { name: 'shop' },
      { name: 'shop-lock' },
      { name: 'store' },
      { name: 'synagogue' },
      { name: 'tent' },
      { name: 'tent-arrow-down-to-line' },
      { name: 'tent-arrow-left-right' },
      { name: 'tent-arrow-turn-left' },
      { name: 'tent-arrows-down' },
      { name: 'tents' },
      { name: 'toilet-portable' },
      { name: 'toilets-portable' },
      { name: 'torii-gate' },
      { name: 'tower-observation' },
      { name: 'tree-city' },
      { name: 'vihara' },
      { name: 'warehouse' },
    ],
  },
  business: {
    title: 'Business',
    list: [
      { name: 'address-book' },
      { name: 'address-card' },
      { name: 'arrows-spin' },
      { name: 'arrows-to-dot' },
      { name: 'arrows-to-eye' },
      { name: 'bars-progress' },
      { name: 'bars-staggered' },
      { name: 'book' },
      { name: 'box-archive' },
      { name: 'boxes-packing' },
      { name: 'briefcase' },
      { name: 'building' },
      { name: 'bullhorn' },
      { name: 'bullseye' },
      { name: 'business-time' },
      { name: 'cake-candles' },
      { name: 'calculator' },
      { name: 'calendar' },
      { name: 'calendar-days' },
      { name: 'certificate' },
      { name: 'chart-line' },
      { name: 'chart-pie' },
      { name: 'chart-simple' },
      { name: 'city' },
      { name: 'clipboard' },
      { name: 'clipboard-question' },
      { name: 'compass' },
      { name: 'copy' },
      { name: 'copyright' },
      { name: 'envelope' },
      { name: 'envelope-circle-check' },
      { name: 'envelope-open' },
      { name: 'eraser' },
      { name: 'fax' },
      { name: 'file' },
      { name: 'file-circle-plus' },
      { name: 'file-lines' },
      { name: 'floppy-disk' },
      { name: 'folder' },
      { name: 'folder-minus' },
      { name: 'folder-open' },
      { name: 'folder-plus' },
      { name: 'folder-tree' },
      { name: 'glasses' },
      { name: 'globe' },
      { name: 'highlighter' },
      { name: 'house-laptop' },
      { name: 'industry' },
      { name: 'landmark' },
      { name: 'laptop-file' },
      { name: 'list-check' },
      { name: 'magnifying-glass-arrow-right' },
      { name: 'magnifying-glass-chart' },
      { name: 'marker' },
      { name: 'mug-saucer' },
      { name: 'network-wired' },
      { name: 'note-sticky' },
      { name: 'paperclip' },
      { name: 'paste' },
      { name: 'pen' },
      { name: 'pen-clip' },
      { name: 'pen-fancy' },
      { name: 'pen-nib' },
      { name: 'pen-to-square' },
      { name: 'pencil' },
      { name: 'percent' },
      { name: 'person-chalkboard' },
      { name: 'phone' },
      { name: 'phone-flip' },
      { name: 'phone-slash' },
      { name: 'phone-volume' },
      { name: 'print' },
      { name: 'registered' },
      { name: 'scale-balanced' },
      { name: 'scale-unbalanced' },
      { name: 'scale-unbalanced-flip' },
      { name: 'scissors' },
      { name: 'signature' },
      { name: 'sitemap' },
      { name: 'socks' },
      { name: 'square-envelope' },
      { name: 'square-pen' },
      { name: 'square-phone' },
      { name: 'square-phone-flip' },
      { name: 'square-poll-horizontal' },
      { name: 'square-poll-vertical' },
      { name: 'stapler' },
      { name: 'table' },
      { name: 'table-columns' },
      { name: 'tag' },
      { name: 'tags' },
      { name: 'thumbtack' },
      { name: 'timeline' },
      { name: 'trademark' },
      { name: 'vault' },
      { name: 'wallet' },
    ],
  },
  camping: {
    title: 'Camping',
    list: [
      { name: 'binoculars' },
      { name: 'bottle-water' },
      { name: 'bucket' },
      { name: 'campground' },
      { name: 'caravan' },
      { name: 'compass' },
      { name: 'faucet' },
      { name: 'faucet-drip' },
      { name: 'fire' },
      { name: 'fire-burner' },
      { name: 'fire-flame-curved' },
      { name: 'frog' },
      { name: 'kit-medical' },
      { name: 'map' },
      { name: 'map-location' },
      { name: 'map-location-dot' },
      { name: 'mattress-pillow' },
      { name: 'mosquito' },
      { name: 'mosquito-net' },
      { name: 'mountain' },
      { name: 'mountain-sun' },
      { name: 'people-roof' },
      { name: 'person-hiking' },
      { name: 'person-shelter' },
      { name: 'route' },
      { name: 'signs-post' },
      { name: 'tarp' },
      { name: 'tarp-droplet' },
      { name: 'tent' },
      { name: 'tent-arrow-down-to-line' },
      { name: 'tent-arrow-left-right' },
      { name: 'tent-arrow-turn-left' },
      { name: 'tent-arrows-down' },
      { name: 'tents' },
      { name: 'toilet-paper' },
      { name: 'trailer' },
      { name: 'tree' },
    ],
  },
  charity: {
    title: 'Charity',
    list: [
      { name: 'circle-dollar-to-slot' },
      { name: 'dollar-sign' },
      { name: 'dove' },
      { name: 'gift' },
      { name: 'globe' },
      { name: 'hand-holding-dollar' },
      { name: 'hand-holding-droplet' },
      { name: 'hand-holding-hand' },
      { name: 'hand-holding-heart' },
      { name: 'hands-holding-child' },
      { name: 'hands-holding-circle' },
      { name: 'handshake' },
      { name: 'handshake-angle' },
      { name: 'handshake-simple' },
      { name: 'heart' },
      { name: 'leaf' },
      { name: 'parachute-box' },
      { name: 'piggy-bank' },
      { name: 'ribbon' },
      { name: 'seedling' },
    ],
  },
  'charts-diagrams': {
    title: 'Charts + Diagrams',
    list: [
      { name: 'bars-progress' },
      { name: 'chart-area' },
      { name: 'chart-bar' },
      { name: 'chart-column' },
      { name: 'chart-gantt' },
      { name: 'chart-line' },
      { name: 'chart-pie' },
      { name: 'chart-simple' },
      { name: 'circle-half-stroke' },
      { name: 'diagram-next' },
      { name: 'diagram-predecessor' },
      { name: 'diagram-project' },
      { name: 'diagram-successor' },
      { name: 'square-poll-horizontal' },
      { name: 'square-poll-vertical' },
    ],
  },
  childhood: {
    title: 'Childhood',
    list: [
      { name: 'apple-whole' },
      { name: 'baby' },
      { name: 'baby-carriage' },
      { name: 'baseball-bat-ball' },
      { name: 'bath' },
      { name: 'bucket' },
      { name: 'cake-candles' },
      { name: 'child' },
      { name: 'child-dress' },
      { name: 'child-reaching' },
      { name: 'children' },
      { name: 'cookie' },
      { name: 'cookie-bite' },
      { name: 'cubes-stacked' },
      { name: 'gamepad' },
      { name: 'hands-holding-child' },
      { name: 'ice-cream' },
      { name: 'mitten' },
      { name: 'person-biking' },
      { name: 'person-breastfeeding' },
      { name: 'puzzle-piece' },
      { name: 'robot' },
      { name: 'school' },
      { name: 'shapes' },
      { name: 'snowman' },
    ],
  },
  'clothing-fashion': {
    title: 'Clothing + Fashion',
    list: [
      { name: 'glasses' },
      { name: 'graduation-cap' },
      { name: 'hat-cowboy' },
      { name: 'hat-cowboy-side' },
      { name: 'hat-wizard' },
      { name: 'mitten' },
      { name: 'shirt' },
      { name: 'shoe-prints' },
      { name: 'socks' },
      { name: 'user-tie' },
      { name: 'vest' },
      { name: 'vest-patches' },
    ],
  },
  coding: {
    title: 'Coding',
    list: [
      { name: 'barcode' },
      { name: 'bars' },
      { name: 'bars-staggered' },
      { name: 'bath' },
      { name: 'box-archive' },
      { name: 'bug' },
      { name: 'bug-slash' },
      { name: 'circle-nodes' },
      { name: 'code' },
      { name: 'code-branch' },
      { name: 'code-commit' },
      { name: 'code-compare' },
      { name: 'code-fork' },
      { name: 'code-merge' },
      { name: 'code-pull-request' },
      { name: 'cube' },
      { name: 'cubes' },
      { name: 'diagram-project' },
      { name: 'file' },
      { name: 'file-code' },
      { name: 'file-lines' },
      { name: 'filter' },
      { name: 'fire-extinguisher' },
      { name: 'folder' },
      { name: 'folder-open' },
      { name: 'font-awesome' },
      { name: 'gear' },
      { name: 'gears' },
      { name: 'keyboard' },
      { name: 'laptop-code' },
      { name: 'microchip' },
      { name: 'mug-saucer' },
      { name: 'network-wired' },
      { name: 'notdef' },
      { name: 'qrcode' },
      { name: 'rectangle-xmark' },
      { name: 'shield' },
      { name: 'shield-halved' },
      { name: 'sitemap' },
      { name: 'terminal' },
      { name: 'user-secret' },
      { name: 'window-maximize' },
      { name: 'window-minimize' },
      { name: 'window-restore' },
    ],
  },
  communication: {
    title: 'Communication',
    list: [
      { name: 'address-book' },
      { name: 'address-card' },
      { name: 'at' },
      { name: 'blender-phone' },
      { name: 'bullhorn' },
      { name: 'comment' },
      { name: 'comment-dots' },
      { name: 'comment-medical' },
      { name: 'comment-slash' },
      { name: 'comment-sms' },
      { name: 'comments' },
      { name: 'ear-listen' },
      { name: 'envelope' },
      { name: 'envelope-circle-check' },
      { name: 'envelope-open' },
      { name: 'face-frown' },
      { name: 'face-meh' },
      { name: 'face-smile' },
      { name: 'fax' },
      { name: 'hands-asl-interpreting' },
      { name: 'icons' },
      { name: 'inbox' },
      { name: 'language' },
      { name: 'message' },
      { name: 'microphone' },
      { name: 'microphone-lines' },
      { name: 'microphone-lines-slash' },
      { name: 'microphone-slash' },
      { name: 'mobile' },
      { name: 'mobile-button' },
      { name: 'mobile-retro' },
      { name: 'mobile-screen' },
      { name: 'mobile-screen-button' },
      { name: 'paper-plane' },
      { name: 'phone' },
      { name: 'phone-flip' },
      { name: 'phone-slash' },
      { name: 'phone-volume' },
      { name: 'poo' },
      { name: 'quote-left' },
      { name: 'quote-right' },
      { name: 'square-envelope' },
      { name: 'square-phone' },
      { name: 'square-phone-flip' },
      { name: 'square-rss' },
      { name: 'tower-cell' },
      { name: 'tty' },
      { name: 'video' },
      { name: 'video-slash' },
      { name: 'voicemail' },
      { name: 'walkie-talkie' },
    ],
  },
  connectivity: {
    title: 'Connectivity',
    list: [
      { name: 'circle-nodes' },
      { name: 'cloud' },
      { name: 'cloud-arrow-down' },
      { name: 'cloud-arrow-up' },
      { name: 'ethernet' },
      { name: 'globe' },
      { name: 'house-signal' },
      { name: 'rss' },
      { name: 'satellite-dish' },
      { name: 'signal' },
      { name: 'tower-broadcast' },
      { name: 'tower-cell' },
      { name: 'wifi' },
    ],
  },
  construction: {
    title: 'Construction',
    list: [
      { name: 'arrow-up-from-ground-water' },
      { name: 'bore-hole' },
      { name: 'brush' },
      { name: 'bucket' },
      { name: 'compass-drafting' },
      { name: 'dumpster' },
      { name: 'dumpster-fire' },
      { name: 'hammer' },
      { name: 'helmet-safety' },
      { name: 'mound' },
      { name: 'paint-roller' },
      { name: 'pen-ruler' },
      { name: 'pencil' },
      { name: 'person-digging' },
      { name: 'ruler' },
      { name: 'ruler-combined' },
      { name: 'ruler-horizontal' },
      { name: 'ruler-vertical' },
      { name: 'screwdriver' },
      { name: 'screwdriver-wrench' },
      { name: 'sheet-plastic' },
      { name: 'tarp' },
      { name: 'tarp-droplet' },
      { name: 'toilet-portable' },
      { name: 'toilets-portable' },
      { name: 'toolbox' },
      { name: 'trowel' },
      { name: 'trowel-bricks' },
      { name: 'truck-pickup' },
      { name: 'wrench' },
    ],
  },
  design: {
    title: 'Design',
    list: [
      { name: 'bezier-curve' },
      { name: 'brush' },
      { name: 'circle-half-stroke' },
      { name: 'circle-nodes' },
      { name: 'clone' },
      { name: 'compass-drafting' },
      { name: 'copy' },
      { name: 'crop' },
      { name: 'crop-simple' },
      { name: 'crosshairs' },
      { name: 'cube' },
      { name: 'cubes' },
      { name: 'draw-polygon' },
      { name: 'droplet' },
      { name: 'droplet-slash' },
      { name: 'eraser' },
      { name: 'eye' },
      { name: 'eye-dropper' },
      { name: 'eye-slash' },
      { name: 'fill' },
      { name: 'fill-drip' },
      { name: 'floppy-disk' },
      { name: 'font-awesome' },
      { name: 'highlighter' },
      { name: 'icons' },
      { name: 'layer-group' },
      { name: 'lines-leaning' },
      { name: 'marker' },
      { name: 'object-group' },
      { name: 'object-ungroup' },
      { name: 'paint-roller' },
      { name: 'paintbrush' },
      { name: 'palette' },
      { name: 'paste' },
      { name: 'pen' },
      { name: 'pen-clip' },
      { name: 'pen-fancy' },
      { name: 'pen-nib' },
      { name: 'pen-ruler' },
      { name: 'pen-to-square' },
      { name: 'pencil' },
      { name: 'ruler-combined' },
      { name: 'ruler-horizontal' },
      { name: 'ruler-vertical' },
      { name: 'scissors' },
      { name: 'splotch' },
      { name: 'spray-can' },
      { name: 'stamp' },
      { name: 'stapler' },
      { name: 'swatchbook' },
      { name: 'vector-square' },
      { name: 'wand-magic' },
      { name: 'wand-magic-sparkles' },
    ],
  },
  'devices-hardware': {
    title: 'Devices + Hardware',
    list: [
      { name: 'blender-phone' },
      { name: 'camera' },
      { name: 'camera-retro' },
      { name: 'car-battery' },
      { name: 'compact-disc' },
      { name: 'computer' },
      { name: 'computer-mouse' },
      { name: 'database' },
      { name: 'desktop' },
      { name: 'display' },
      { name: 'download' },
      { name: 'ethernet' },
      { name: 'fax' },
      { name: 'floppy-disk' },
      { name: 'gamepad' },
      { name: 'hard-drive' },
      { name: 'headphones' },
      { name: 'house-laptop' },
      { name: 'keyboard' },
      { name: 'laptop' },
      { name: 'laptop-file' },
      { name: 'memory' },
      { name: 'microchip' },
      { name: 'mobile' },
      { name: 'mobile-button' },
      { name: 'mobile-retro' },
      { name: 'mobile-screen' },
      { name: 'mobile-screen-button' },
      { name: 'plug' },
      { name: 'power-off' },
      { name: 'print' },
      { name: 'satellite' },
      { name: 'satellite-dish' },
      { name: 'sd-card' },
      { name: 'server' },
      { name: 'sim-card' },
      { name: 'tablet' },
      { name: 'tablet-button' },
      { name: 'tablet-screen-button' },
      { name: 'tachograph-digital' },
      { name: 'tv' },
      { name: 'upload' },
      { name: 'walkie-talkie' },
    ],
  },
  disaster: {
    title: 'Disaster + Crisis',
    list: [
      { name: 'biohazard' },
      { name: 'bugs' },
      { name: 'burst' },
      { name: 'child-combatant' },
      { name: 'circle-radiation' },
      { name: 'cloud-bolt' },
      { name: 'cloud-showers-heavy' },
      { name: 'cloud-showers-water' },
      { name: 'helmet-un' },
      { name: 'hill-avalanche' },
      { name: 'hill-rockslide' },
      { name: 'house-chimney-crack' },
      { name: 'house-crack' },
      { name: 'house-fire' },
      { name: 'house-flood-water' },
      { name: 'house-flood-water-circle-arrow-right' },
      { name: 'house-tsunami' },
      { name: 'hurricane' },
      { name: 'locust' },
      { name: 'mosquito' },
      { name: 'person-drowning' },
      { name: 'person-rifle' },
      { name: 'person-walking-arrow-loop-left' },
      { name: 'person-walking-arrow-right' },
      { name: 'person-walking-dashed-line-arrow-right' },
      { name: 'plant-wilt' },
      { name: 'radiation' },
      { name: 'snowflake' },
      { name: 'sun-plant-wilt' },
      { name: 'temperature-arrow-down' },
      { name: 'temperature-arrow-up' },
      { name: 'tornado' },
      { name: 'volcano' },
      { name: 'wheat-awn-circle-exclamation' },
      { name: 'wind' },
      { name: 'worm' },
      { name: 'xmarks-lines' },
    ],
  },
  editing: {
    title: 'Editing',
    list: [
      { name: 'arrows-rotate' },
      { name: 'bandage' },
      { name: 'bars' },
      { name: 'brush' },
      { name: 'chart-simple' },
      { name: 'check' },
      { name: 'check-double' },
      { name: 'circle-check' },
      { name: 'circle-half-stroke' },
      { name: 'crop' },
      { name: 'crop-simple' },
      { name: 'cube' },
      { name: 'delete-left' },
      { name: 'ellipsis' },
      { name: 'ellipsis-vertical' },
      { name: 'eye-dropper' },
      { name: 'eye-slash' },
      { name: 'gear' },
      { name: 'grip' },
      { name: 'grip-lines' },
      { name: 'grip-lines-vertical' },
      { name: 'grip-vertical' },
      { name: 'link' },
      { name: 'link-slash' },
      { name: 'minus' },
      { name: 'paintbrush' },
      { name: 'pen' },
      { name: 'pen-clip' },
      { name: 'pen-fancy' },
      { name: 'pen-nib' },
      { name: 'pen-ruler' },
      { name: 'pen-to-square' },
      { name: 'pencil' },
      { name: 'plus' },
      { name: 'rotate' },
      { name: 'scissors' },
      { name: 'signature' },
      { name: 'sliders' },
      { name: 'square-check' },
      { name: 'square-pen' },
      { name: 'trash' },
      { name: 'trash-arrow-up' },
      { name: 'trash-can' },
      { name: 'trash-can-arrow-up' },
      { name: 'wand-magic' },
      { name: 'wand-magic-sparkles' },
      { name: 'xmark' },
    ],
  },
  education: {
    title: 'Education',
    list: [
      { name: 'apple-whole' },
      { name: 'atom' },
      { name: 'award' },
      { name: 'bell' },
      { name: 'bell-slash' },
      { name: 'book-open' },
      { name: 'book-open-reader' },
      { name: 'chalkboard' },
      { name: 'chalkboard-user' },
      { name: 'graduation-cap' },
      { name: 'laptop-code' },
      { name: 'laptop-file' },
      { name: 'masks-theater' },
      { name: 'microscope' },
      { name: 'music' },
      { name: 'person-chalkboard' },
      { name: 'school' },
      { name: 'school-circle-check' },
      { name: 'school-circle-exclamation' },
      { name: 'school-circle-xmark' },
      { name: 'school-flag' },
      { name: 'school-lock' },
      { name: 'shapes' },
      { name: 'user-graduate' },
    ],
  },
  emoji: {
    title: 'Emoji',
    list: [
      { name: 'face-angry' },
      { name: 'face-dizzy' },
      { name: 'face-flushed' },
      { name: 'face-frown' },
      { name: 'face-frown-open' },
      { name: 'face-grimace' },
      { name: 'face-grin' },
      { name: 'face-grin-beam' },
      { name: 'face-grin-beam-sweat' },
      { name: 'face-grin-hearts' },
      { name: 'face-grin-squint' },
      { name: 'face-grin-squint-tears' },
      { name: 'face-grin-stars' },
      { name: 'face-grin-tears' },
      { name: 'face-grin-tongue' },
      { name: 'face-grin-tongue-squint' },
      { name: 'face-grin-tongue-wink' },
      { name: 'face-grin-wide' },
      { name: 'face-grin-wink' },
      { name: 'face-kiss' },
      { name: 'face-kiss-beam' },
      { name: 'face-kiss-wink-heart' },
      { name: 'face-laugh' },
      { name: 'face-laugh-beam' },
      { name: 'face-laugh-squint' },
      { name: 'face-laugh-wink' },
      { name: 'face-meh' },
      { name: 'face-meh-blank' },
      { name: 'face-rolling-eyes' },
      { name: 'face-sad-cry' },
      { name: 'face-sad-tear' },
      { name: 'face-smile' },
      { name: 'face-smile-beam' },
      { name: 'face-smile-wink' },
      { name: 'face-surprise' },
      { name: 'face-tired' },
    ],
  },
  energy: {
    title: 'Energy',
    list: [
      { name: 'arrow-up-from-ground-water' },
      { name: 'atom' },
      { name: 'battery-empty' },
      { name: 'battery-full' },
      { name: 'battery-half' },
      { name: 'battery-quarter' },
      { name: 'battery-three-quarters' },
      { name: 'bolt' },
      { name: 'car-battery' },
      { name: 'charging-station' },
      { name: 'circle-radiation' },
      { name: 'explosion' },
      { name: 'fan' },
      { name: 'fire' },
      { name: 'fire-flame-curved' },
      { name: 'fire-flame-simple' },
      { name: 'gas-pump' },
      { name: 'industry' },
      { name: 'leaf' },
      { name: 'lightbulb' },
      { name: 'oil-well' },
      { name: 'plug' },
      { name: 'plug-circle-bolt' },
      { name: 'plug-circle-check' },
      { name: 'plug-circle-exclamation' },
      { name: 'plug-circle-minus' },
      { name: 'plug-circle-plus' },
      { name: 'plug-circle-xmark' },
      { name: 'poop' },
      { name: 'power-off' },
      { name: 'radiation' },
      { name: 'seedling' },
      { name: 'solar-panel' },
      { name: 'sun' },
      { name: 'tower-broadcast' },
      { name: 'water' },
      { name: 'wind' },
    ],
  },
  files: {
    title: 'Files',
    list: [
      { name: 'box-archive' },
      { name: 'clone' },
      { name: 'copy' },
      { name: 'file' },
      { name: 'file-arrow-down' },
      { name: 'file-arrow-up' },
      { name: 'file-audio' },
      { name: 'file-circle-check' },
      { name: 'file-circle-exclamation' },
      { name: 'file-circle-minus' },
      { name: 'file-circle-plus' },
      { name: 'file-circle-question' },
      { name: 'file-circle-xmark' },
      { name: 'file-code' },
      { name: 'file-csv' },
      { name: 'file-excel' },
      { name: 'file-export' },
      { name: 'file-image' },
      { name: 'file-import' },
      { name: 'file-lines' },
      { name: 'file-pdf' },
      { name: 'file-pen' },
      { name: 'file-powerpoint' },
      { name: 'file-shield' },
      { name: 'file-video' },
      { name: 'file-word' },
      { name: 'file-zipper' },
      { name: 'floppy-disk' },
      { name: 'folder' },
      { name: 'folder-closed' },
      { name: 'folder-open' },
      { name: 'note-sticky' },
      { name: 'paste' },
      { name: 'photo-film' },
      { name: 'scissors' },
    ],
  },
  'film-video': {
    title: 'Film + Video',
    list: [
      { name: 'audio-description' },
      { name: 'circle' },
      { name: 'clapperboard' },
      { name: 'closed-captioning' },
      { name: 'compact-disc' },
      { name: 'file-audio' },
      { name: 'file-video' },
      { name: 'film' },
      { name: 'headphones' },
      { name: 'microphone' },
      { name: 'microphone-lines' },
      { name: 'microphone-lines-slash' },
      { name: 'microphone-slash' },
      { name: 'photo-film' },
      { name: 'podcast' },
      { name: 'square-rss' },
      { name: 'ticket' },
      { name: 'tower-broadcast' },
      { name: 'tower-cell' },
      { name: 'tv' },
      { name: 'video' },
      { name: 'video-slash' },
    ],
  },
  'food-beverage': {
    title: 'Food + Beverage',
    list: [
      { name: 'apple-whole' },
      { name: 'bacon' },
      { name: 'beer-mug-empty' },
      { name: 'blender' },
      { name: 'bone' },
      { name: 'bottle-droplet' },
      { name: 'bottle-water' },
      { name: 'bowl-food' },
      { name: 'bowl-rice' },
      { name: 'bread-slice' },
      { name: 'burger' },
      { name: 'cake-candles' },
      { name: 'candy-cane' },
      { name: 'carrot' },
      { name: 'champagne-glasses' },
      { name: 'cheese' },
      { name: 'cloud-meatball' },
      { name: 'cookie' },
      { name: 'cubes-stacked' },
      { name: 'drumstick-bite' },
      { name: 'egg' },
      { name: 'fish' },
      { name: 'fish-fins' },
      { name: 'flask' },
      { name: 'glass-water' },
      { name: 'glass-water-droplet' },
      { name: 'hotdog' },
      { name: 'ice-cream' },
      { name: 'jar' },
      { name: 'jar-wheat' },
      { name: 'lemon' },
      { name: 'martini-glass' },
      { name: 'martini-glass-citrus' },
      { name: 'martini-glass-empty' },
      { name: 'mug-hot' },
      { name: 'mug-saucer' },
      { name: 'pepper-hot' },
      { name: 'pizza-slice' },
      { name: 'plate-wheat' },
      { name: 'seedling' },
      { name: 'shrimp' },
      { name: 'stroopwafel' },
      { name: 'wheat-awn' },
      { name: 'wheat-awn-circle-exclamation' },
      { name: 'whiskey-glass' },
      { name: 'wine-bottle' },
      { name: 'wine-glass' },
      { name: 'wine-glass-empty' },
    ],
  },
  'fruits-vegetables': {
    title: 'Fruits + Vegetables',
    list: [
      { name: 'apple-whole' },
      { name: 'carrot' },
      { name: 'leaf' },
      { name: 'lemon' },
      { name: 'pepper-hot' },
      { name: 'seedling' },
    ],
  },
  gaming: {
    title: 'Gaming',
    list: [
      { name: 'book-skull' },
      { name: 'chess' },
      { name: 'chess-bishop' },
      { name: 'chess-board' },
      { name: 'chess-king' },
      { name: 'chess-knight' },
      { name: 'chess-pawn' },
      { name: 'chess-queen' },
      { name: 'chess-rook' },
      { name: 'diamond' },
      { name: 'dice' },
      { name: 'dice-d20' },
      { name: 'dice-d6' },
      { name: 'dice-five' },
      { name: 'dice-four' },
      { name: 'dice-one' },
      { name: 'dice-six' },
      { name: 'dice-three' },
      { name: 'dice-two' },
      { name: 'dragon' },
      { name: 'dungeon' },
      { name: 'gamepad' },
      { name: 'ghost' },
      { name: 'hand-fist' },
      { name: 'hat-wizard' },
      { name: 'headset' },
      { name: 'heart' },
      { name: 'puzzle-piece' },
      { name: 'ring' },
      { name: 'scroll' },
      { name: 'shield-halved' },
      { name: 'skull-crossbones' },
      { name: 'square-full' },
      { name: 'vr-cardboard' },
      { name: 'wand-sparkles' },
    ],
  },
  gender: {
    title: 'Genders',
    list: [
      { name: 'genderless' },
      { name: 'mars' },
      { name: 'mars-and-venus' },
      { name: 'mars-double' },
      { name: 'mars-stroke' },
      { name: 'mars-stroke-right' },
      { name: 'mars-stroke-up' },
      { name: 'mercury' },
      { name: 'neuter' },
      { name: 'person-half-dress' },
      { name: 'transgender' },
      { name: 'venus' },
      { name: 'venus-double' },
      { name: 'venus-mars' },
    ],
  },
  halloween: {
    title: 'Halloween',
    list: [
      { name: 'book-skull' },
      { name: 'broom' },
      { name: 'cat' },
      { name: 'cloud-moon' },
      { name: 'crow' },
      { name: 'ghost' },
      { name: 'hat-wizard' },
      { name: 'mask' },
      { name: 'skull' },
      { name: 'skull-crossbones' },
      { name: 'spider' },
      { name: 'toilet-paper' },
      { name: 'wand-sparkles' },
    ],
  },
  hands: {
    title: 'Hands',
    list: [
      { name: 'hand' },
      { name: 'hand-back-fist' },
      { name: 'hand-dots' },
      { name: 'hand-fist' },
      { name: 'hand-holding' },
      { name: 'hand-holding-dollar' },
      { name: 'hand-holding-droplet' },
      { name: 'hand-holding-hand' },
      { name: 'hand-holding-heart' },
      { name: 'hand-holding-medical' },
      { name: 'hand-lizard' },
      { name: 'hand-middle-finger' },
      { name: 'hand-peace' },
      { name: 'hand-point-down' },
      { name: 'hand-point-left' },
      { name: 'hand-point-right' },
      { name: 'hand-point-up' },
      { name: 'hand-pointer' },
      { name: 'hand-scissors' },
      { name: 'hand-sparkles' },
      { name: 'hand-spock' },
      { name: 'hands-bound' },
      { name: 'hands-bubbles' },
      { name: 'hands-clapping' },
      { name: 'hands-holding' },
      { name: 'hands-holding-child' },
      { name: 'hands-holding-circle' },
      { name: 'hands-praying' },
      { name: 'handshake' },
      { name: 'handshake-angle' },
      { name: 'handshake-simple' },
      { name: 'handshake-simple-slash' },
      { name: 'handshake-slash' },
      { name: 'thumbs-down' },
      { name: 'thumbs-up' },
    ],
  },
  holidays: {
    title: 'Holidays',
    list: [
      { name: 'candy-cane' },
      { name: 'carrot' },
      { name: 'champagne-glasses' },
      { name: 'cookie-bite' },
      { name: 'face-grin-hearts' },
      { name: 'face-kiss-wink-heart' },
      { name: 'gift' },
      { name: 'gifts' },
      { name: 'heart' },
      { name: 'holly-berry' },
      { name: 'menorah' },
      { name: 'mug-hot' },
      { name: 'sleigh' },
      { name: 'snowman' },
    ],
  },
  household: {
    title: 'Household',
    list: [
      { name: 'arrow-up-from-water-pump' },
      { name: 'bath' },
      { name: 'bed' },
      { name: 'bell' },
      { name: 'blender' },
      { name: 'box-tissue' },
      { name: 'chair' },
      { name: 'computer' },
      { name: 'couch' },
      { name: 'door-closed' },
      { name: 'door-open' },
      { name: 'dungeon' },
      { name: 'fan' },
      { name: 'faucet' },
      { name: 'faucet-drip' },
      { name: 'fire-burner' },
      { name: 'house-chimney-user' },
      { name: 'house-chimney-window' },
      { name: 'house-fire' },
      { name: 'house-laptop' },
      { name: 'house-lock' },
      { name: 'house-signal' },
      { name: 'house-user' },
      { name: 'jar' },
      { name: 'jar-wheat' },
      { name: 'jug-detergent' },
      { name: 'kitchen-set' },
      { name: 'lightbulb' },
      { name: 'mattress-pillow' },
      { name: 'mug-saucer' },
      { name: 'people-roof' },
      { name: 'plug' },
      { name: 'pump-soap' },
      { name: 'rug' },
      { name: 'sheet-plastic' },
      { name: 'shower' },
      { name: 'sink' },
      { name: 'snowflake' },
      { name: 'soap' },
      { name: 'spoon' },
      { name: 'stairs' },
      { name: 'temperature-arrow-down' },
      { name: 'temperature-arrow-up' },
      { name: 'toilet' },
      { name: 'toilet-paper' },
      { name: 'toilet-paper-slash' },
      { name: 'tv' },
      { name: 'utensils' },
    ],
  },
  humanitarian: {
    title: 'Humanitarian',
    list: [
      { name: 'anchor' },
      { name: 'anchor-circle-check' },
      { name: 'anchor-circle-exclamation' },
      { name: 'anchor-circle-xmark' },
      { name: 'anchor-lock' },
      { name: 'arrow-down-up-across-line' },
      { name: 'arrow-down-up-lock' },
      { name: 'arrow-right-to-city' },
      { name: 'arrow-up-from-ground-water' },
      { name: 'arrow-up-from-water-pump' },
      { name: 'arrow-up-right-dots' },
      { name: 'arrow-up-right-from-square' },
      { name: 'arrows-down-to-line' },
      { name: 'arrows-down-to-people' },
      { name: 'arrows-left-right-to-line' },
      { name: 'arrows-spin' },
      { name: 'arrows-split-up-and-left' },
      { name: 'arrows-to-circle' },
      { name: 'arrows-to-dot' },
      { name: 'arrows-to-eye' },
      { name: 'arrows-turn-right' },
      { name: 'arrows-turn-to-dots' },
      { name: 'arrows-up-to-line' },
      { name: 'baby' },
      { name: 'bacterium' },
      { name: 'ban' },
      { name: 'bed' },
      { name: 'biohazard' },
      { name: 'book-bookmark' },
      { name: 'bore-hole' },
      { name: 'bottle-droplet' },
      { name: 'bottle-water' },
      { name: 'bowl-food' },
      { name: 'bowl-rice' },
      { name: 'boxes-packing' },
      { name: 'bridge' },
      { name: 'bridge-circle-check' },
      { name: 'bridge-circle-exclamation' },
      { name: 'bridge-circle-xmark' },
      { name: 'bridge-lock' },
      { name: 'bridge-water' },
      { name: 'bucket' },
      { name: 'bugs' },
      { name: 'building' },
      { name: 'building-circle-arrow-right' },
      { name: 'building-circle-check' },
      { name: 'building-circle-exclamation' },
      { name: 'building-circle-xmark' },
      { name: 'building-columns' },
      { name: 'building-flag' },
      { name: 'building-lock' },
      { name: 'building-ngo' },
      { name: 'building-shield' },
      { name: 'building-un' },
      { name: 'building-user' },
      { name: 'building-wheat' },
      { name: 'burst' },
      { name: 'bus' },
      { name: 'car' },
      { name: 'car-on' },
      { name: 'car-tunnel' },
      { name: 'child-combatant' },
      { name: 'children' },
      { name: 'church' },
      { name: 'circle-h' },
      { name: 'circle-nodes' },
      { name: 'clipboard-question' },
      { name: 'clipboard-user' },
      { name: 'cloud-bolt' },
      { name: 'cloud-showers-heavy' },
      { name: 'cloud-showers-water' },
      { name: 'computer' },
      { name: 'cow' },
      { name: 'cubes-stacked' },
      { name: 'display' },
      { name: 'droplet' },
      { name: 'envelope' },
      { name: 'envelope-circle-check' },
      { name: 'explosion' },
      { name: 'faucet-drip' },
      { name: 'fax' },
      { name: 'ferry' },
      { name: 'file' },
      { name: 'file-circle-check' },
      { name: 'file-circle-exclamation' },
      { name: 'file-circle-minus' },
      { name: 'file-circle-plus' },
      { name: 'file-circle-question' },
      { name: 'file-circle-xmark' },
      { name: 'file-csv' },
      { name: 'file-pdf' },
      { name: 'file-pen' },
      { name: 'file-shield' },
      { name: 'fire-burner' },
      { name: 'fire-flame-simple' },
      { name: 'fish-fins' },
      { name: 'flag' },
      { name: 'flask-vial' },
      { name: 'gas-pump' },
      { name: 'glass-water' },
      { name: 'glass-water-droplet' },
      { name: 'gopuram' },
      { name: 'group-arrows-rotate' },
      { name: 'hammer' },
      { name: 'hand-holding-hand' },
      { name: 'handcuffs' },
      { name: 'hands-bound' },
      { name: 'hands-bubbles' },
      { name: 'hands-holding-child' },
      { name: 'hands-holding-circle' },
      { name: 'handshake-simple' },
      { name: 'headset' },
      { name: 'heart-circle-bolt' },
      { name: 'heart-circle-check' },
      { name: 'heart-circle-exclamation' },
      { name: 'heart-circle-minus' },
      { name: 'heart-circle-plus' },
      { name: 'heart-circle-xmark' },
      { name: 'helicopter' },
      { name: 'helicopter-symbol' },
      { name: 'helmet-un' },
      { name: 'hill-avalanche' },
      { name: 'hill-rockslide' },
      { name: 'hospital' },
      { name: 'hotel' },
      { name: 'house-chimney' },
      { name: 'house-chimney-crack' },
      { name: 'house-circle-check' },
      { name: 'house-circle-exclamation' },
      { name: 'house-circle-xmark' },
      { name: 'house-fire' },
      { name: 'house-flag' },
      { name: 'house-flood-water' },
      { name: 'house-flood-water-circle-arrow-right' },
      { name: 'house-lock' },
      { name: 'house-medical' },
      { name: 'house-medical-circle-check' },
      { name: 'house-medical-circle-exclamation' },
      { name: 'house-medical-circle-xmark' },
      { name: 'house-medical-flag' },
      { name: 'house-signal' },
      { name: 'house-tsunami' },
      { name: 'hurricane' },
      { name: 'id-card' },
      { name: 'jar' },
      { name: 'jar-wheat' },
      { name: 'jet-fighter-up' },
      { name: 'jug-detergent' },
      { name: 'kitchen-set' },
      { name: 'land-mine-on' },
      { name: 'landmark' },
      { name: 'landmark-dome' },
      { name: 'landmark-flag' },
      { name: 'laptop' },
      { name: 'laptop-file' },
      { name: 'life-ring' },
      { name: 'lines-leaning' },
      { name: 'location-pin-lock' },
      { name: 'locust' },
      { name: 'lungs' },
      { name: 'magnifying-glass-arrow-right' },
      { name: 'magnifying-glass-chart' },
      { name: 'mars-and-venus' },
      { name: 'mars-and-venus-burst' },
      { name: 'mask-face' },
      { name: 'mask-ventilator' },
      { name: 'mattress-pillow' },
      { name: 'microscope' },
      { name: 'mobile-retro' },
      { name: 'mobile-screen' },
      { name: 'money-bill-transfer' },
      { name: 'money-bill-trend-up' },
      { name: 'money-bill-wheat' },
      { name: 'money-bills' },
      { name: 'mosque' },
      { name: 'mosquito' },
      { name: 'mosquito-net' },
      { name: 'mound' },
      { name: 'mountain-city' },
      { name: 'mountain-sun' },
    ],
  },
  logistics: {
    title: 'Logistics',
    list: [
      { name: 'anchor' },
      { name: 'anchor-circle-check' },
      { name: 'anchor-circle-exclamation' },
      { name: 'anchor-circle-xmark' },
      { name: 'anchor-lock' },
      { name: 'box' },
      { name: 'boxes-packing' },
      { name: 'boxes-stacked' },
      { name: 'bridge' },
      { name: 'bridge-circle-check' },
      { name: 'bridge-circle-exclamation' },
      { name: 'bridge-circle-xmark' },
      { name: 'bridge-lock' },
      { name: 'bridge-water' },
      { name: 'bus' },
      { name: 'bus-simple' },
      { name: 'car' },
      { name: 'car-tunnel' },
      { name: 'cart-flatbed' },
      { name: 'chart-simple' },
      { name: 'clipboard-check' },
      { name: 'clipboard-list' },
      { name: 'clipboard-question' },
      { name: 'dolly' },
      { name: 'ferry' },
      { name: 'gas-pump' },
      { name: 'gears' },
      { name: 'helicopter' },
      { name: 'helicopter-symbol' },
      { name: 'helmet-safety' },
      { name: 'jet-fighter-up' },
      { name: 'pallet' },
      { name: 'plane-circle-check' },
      { name: 'plane-circle-exclamation' },
      { name: 'plane-circle-xmark' },
      { name: 'plane-lock' },
      { name: 'road' },
      { name: 'road-barrier' },
      { name: 'road-bridge' },
      { name: 'road-circle-check' },
      { name: 'road-circle-exclamation' },
      { name: 'road-circle-xmark' },
      { name: 'road-lock' },
      { name: 'sailboat' },
      { name: 'square-nfi' },
      { name: 'train' },
      { name: 'train-subway' },
      { name: 'truck' },
      { name: 'truck-arrow-right' },
      { name: 'truck-fast' },
      { name: 'truck-field' },
      { name: 'truck-field-un' },
      { name: 'truck-front' },
      { name: 'truck-plane' },
      { name: 'warehouse' },
      { name: 'xmarks-lines' },
    ],
  },
  maps: {
    title: 'Maps',
    list: [
      { name: 'anchor' },
      { name: 'bag-shopping' },
      { name: 'basket-shopping' },
      { name: 'bath' },
      { name: 'bed' },
      { name: 'beer-mug-empty' },
      { name: 'bell' },
      { name: 'bell-slash' },
      { name: 'bicycle' },
      { name: 'binoculars' },
      { name: 'bomb' },
      { name: 'book' },
      { name: 'book-atlas' },
      { name: 'bookmark' },
      { name: 'bridge' },
      { name: 'bridge-water' },
      { name: 'briefcase' },
      { name: 'building' },
      { name: 'building-columns' },
      { name: 'cake-candles' },
      { name: 'car' },
      { name: 'cart-shopping' },
      { name: 'circle-info' },
      { name: 'crosshairs' },
      { name: 'diamond-turn-right' },
      { name: 'dollar-sign' },
      { name: 'draw-polygon' },
      { name: 'droplet' },
      { name: 'eye' },
      { name: 'eye-low-vision' },
      { name: 'eye-slash' },
      { name: 'fire' },
      { name: 'fire-extinguisher' },
      { name: 'fire-flame-curved' },
      { name: 'flag' },
      { name: 'flag-checkered' },
      { name: 'flask' },
      { name: 'gamepad' },
      { name: 'gavel' },
      { name: 'gift' },
      { name: 'globe' },
      { name: 'graduation-cap' },
      { name: 'heart' },
      { name: 'heart-pulse' },
      { name: 'helicopter' },
      { name: 'helicopter-symbol' },
      { name: 'hospital' },
      { name: 'house' },
      { name: 'image' },
      { name: 'images' },
      { name: 'industry' },
      { name: 'info' },
      { name: 'jet-fighter' },
      { name: 'key' },
      { name: 'landmark' },
      { name: 'landmark-flag' },
      { name: 'layer-group' },
      { name: 'leaf' },
      { name: 'lemon' },
      { name: 'life-ring' },
      { name: 'lightbulb' },
      { name: 'location-arrow' },
      { name: 'location-crosshairs' },
      { name: 'location-dot' },
      { name: 'location-pin' },
      { name: 'location-pin-lock' },
      { name: 'magnet' },
      { name: 'magnifying-glass' },
      { name: 'magnifying-glass-minus' },
      { name: 'magnifying-glass-plus' },
      { name: 'map' },
      { name: 'map-pin' },
      { name: 'martini-glass-empty' },
      { name: 'money-bill' },
      { name: 'money-bill-1' },
      { name: 'monument' },
      { name: 'motorcycle' },
      { name: 'mountain-sun' },
      { name: 'mug-saucer' },
      { name: 'music' },
      { name: 'newspaper' },
      { name: 'paw' },
      { name: 'person' },
      { name: 'person-walking-with-cane' },
      { name: 'phone' },
      { name: 'phone-flip' },
      { name: 'phone-volume' },
      { name: 'plane' },
      { name: 'plug' },
      { name: 'plus' },
      { name: 'print' },
      { name: 'recycle' },
      { name: 'restroom' },
      { name: 'road' },
      { name: 'rocket' },
      { name: 'route' },
      { name: 'scale-balanced' },
      { name: 'scale-unbalanced' },
      { name: 'scale-unbalanced-flip' },
      { name: 'ship' },
      { name: 'shoe-prints' },
      { name: 'shower' },
      { name: 'signs-post' },
      { name: 'snowplow' },
      { name: 'spoon' },
      { name: 'square-h' },
      { name: 'square-parking' },
      { name: 'square-phone' },
      { name: 'square-phone-flip' },
      { name: 'square-plus' },
      { name: 'street-view' },
      { name: 'suitcase' },
      { name: 'suitcase-medical' },
      { name: 'tag' },
      { name: 'tags' },
      { name: 'taxi' },
      { name: 'thumbtack' },
      { name: 'ticket' },
      { name: 'ticket-simple' },
      { name: 'traffic-light' },
      { name: 'train' },
      { name: 'train-subway' },
      { name: 'train-tram' },
      { name: 'tree' },
      { name: 'trophy' },
      { name: 'truck' },
      { name: 'truck-medical' },
      { name: 'tty' },
      { name: 'umbrella' },
      { name: 'utensils' },
      { name: 'vest' },
      { name: 'vest-patches' },
      { name: 'wheelchair' },
      { name: 'wheelchair-move' },
      { name: 'wifi' },
      { name: 'wine-glass' },
      { name: 'wrench' },
    ],
  },
  maritime: {
    title: 'Maritime',
    list: [
      { name: 'anchor' },
      { name: 'anchor-circle-check' },
      { name: 'anchor-circle-exclamation' },
      { name: 'anchor-circle-xmark' },
      { name: 'anchor-lock' },
      { name: 'ferry' },
      { name: 'fish' },
      { name: 'fish-fins' },
      { name: 'otter' },
      { name: 'person-swimming' },
      { name: 'sailboat' },
      { name: 'ship' },
      { name: 'shrimp' },
      { name: 'water' },
    ],
  },
  marketing: {
    title: 'Marketing',
    list: [
      { name: 'arrows-spin' },
      { name: 'arrows-to-dot' },
      { name: 'arrows-to-eye' },
      { name: 'bullhorn' },
      { name: 'bullseye' },
      { name: 'chart-simple' },
      { name: 'comment-dollar' },
      { name: 'comments-dollar' },
      { name: 'envelope-open-text' },
      { name: 'envelopes-bulk' },
      { name: 'filter-circle-dollar' },
      { name: 'group-arrows-rotate' },
      { name: 'lightbulb' },
      { name: 'magnifying-glass-arrow-right' },
      { name: 'magnifying-glass-chart' },
      { name: 'magnifying-glass-dollar' },
      { name: 'magnifying-glass-location' },
      { name: 'people-group' },
      { name: 'person-rays' },
      { name: 'ranking-star' },
      { name: 'rectangle-ad' },
      { name: 'square-poll-horizontal' },
      { name: 'square-poll-vertical' },
      { name: 'timeline' },
    ],
  },
  mathematics: {
    title: 'Mathematics',
    list: [
      { name: 'calculator' },
      { name: 'circle-minus' },
      { name: 'circle-plus' },
      { name: 'circle-xmark' },
      { name: 'divide' },
      { name: 'equals' },
      { name: 'greater-than' },
      { name: 'greater-than-equal' },
      { name: 'infinity' },
      { name: 'less-than' },
      { name: 'less-than-equal' },
      { name: 'minus' },
      { name: 'not-equal' },
      { name: 'percent' },
      { name: 'plus' },
      { name: 'plus-minus' },
      { name: 'square-minus' },
      { name: 'square-root-variable' },
      { name: 'square-xmark' },
      { name: 'subscript' },
      { name: 'superscript' },
      { name: 'wave-square' },
      { name: 'xmark' },
    ],
  },
  'media-playback': {
    title: 'Media Playback',
    list: [
      { name: 'arrow-rotate-left' },
      { name: 'arrow-rotate-right' },
      { name: 'arrows-rotate' },
      { name: 'backward' },
      { name: 'backward-fast' },
      { name: 'backward-step' },
      { name: 'circle-pause' },
      { name: 'circle-play' },
      { name: 'circle-stop' },
      { name: 'compress' },
      { name: 'down-left-and-up-right-to-center' },
      { name: 'eject' },
      { name: 'expand' },
      { name: 'forward' },
      { name: 'forward-fast' },
      { name: 'forward-step' },
      { name: 'hand' },
      { name: 'maximize' },
      { name: 'minimize' },
      { name: 'music' },
      { name: 'pause' },
      { name: 'phone-volume' },
      { name: 'play' },
      { name: 'plus-minus' },
      { name: 'repeat' },
      { name: 'rotate' },
      { name: 'rotate-left' },
      { name: 'rotate-right' },
      { name: 'rss' },
      { name: 'shuffle' },
      { name: 'sliders' },
      { name: 'stop' },
      { name: 'up-right-and-down-left-from-center' },
      { name: 'volume-high' },
      { name: 'volume-low' },
      { name: 'volume-off' },
      { name: 'volume-xmark' },
    ],
  },
  'medical-health': {
    title: 'Medical + Health',
    list: [
      { name: 'bacteria' },
      { name: 'bacterium' },
      { name: 'ban-smoking' },
      { name: 'bandage' },
      { name: 'bed-pulse' },
      { name: 'biohazard' },
      { name: 'bone' },
      { name: 'bong' },
      { name: 'book-medical' },
      { name: 'brain' },
      { name: 'briefcase-medical' },
      { name: 'cannabis' },
      { name: 'capsules' },
      { name: 'circle-h' },
      { name: 'circle-radiation' },
      { name: 'clipboard-user' },
      { name: 'clock-rotate-left' },
      { name: 'comment-medical' },
      { name: 'crutch' },
      { name: 'disease' },
      { name: 'dna' },
      { name: 'eye' },
      { name: 'eye-dropper' },
      { name: 'file-medical' },
      { name: 'file-prescription' },
      { name: 'file-waveform' },
      { name: 'fire-flame-simple' },
      { name: 'flask' },
      { name: 'flask-vial' },
      { name: 'hand-dots' },
      { name: 'hand-holding-medical' },
      { name: 'head-side-cough' },
      { name: 'head-side-cough-slash' },
      { name: 'head-side-mask' },
      { name: 'head-side-virus' },
      { name: 'heart' },
      { name: 'heart-circle-bolt' },
      { name: 'heart-circle-check' },
      { name: 'heart-circle-exclamation' },
      { name: 'heart-circle-minus' },
      { name: 'heart-circle-plus' },
      { name: 'heart-circle-xmark' },
      { name: 'heart-pulse' },
      { name: 'hospital' },
      { name: 'hospital-user' },
      { name: 'house-chimney-medical' },
      { name: 'house-medical' },
      { name: 'house-medical-circle-check' },
      { name: 'house-medical-circle-exclamation' },
      { name: 'house-medical-circle-xmark' },
      { name: 'house-medical-flag' },
      { name: 'id-card-clip' },
      { name: 'joint' },
      { name: 'kit-medical' },
      { name: 'laptop-medical' },
      { name: 'lungs' },
      { name: 'lungs-virus' },
      { name: 'mask-face' },
      { name: 'mask-ventilator' },
      { name: 'microscope' },
      { name: 'mortar-pestle' },
      { name: 'notes-medical' },
      { name: 'pager' },
      { name: 'person-breastfeeding' },
      { name: 'person-cane' },
      { name: 'person-dots-from-line' },
      { name: 'person-half-dress' },
      { name: 'pills' },
      { name: 'plus' },
      { name: 'poop' },
      { name: 'prescription' },
      { name: 'prescription-bottle' },
      { name: 'prescription-bottle-medical' },
      { name: 'pump-medical' },
      { name: 'radiation' },
      { name: 'receipt' },
      { name: 'shield-virus' },
      { name: 'skull' },
      { name: 'skull-crossbones' },
      { name: 'smoking' },
      { name: 'square-h' },
      { name: 'square-plus' },
      { name: 'square-virus' },
      { name: 'staff-snake' },
      { name: 'star-of-life' },
      { name: 'stethoscope' },
      { name: 'suitcase-medical' },
      { name: 'syringe' },
      { name: 'tablets' },
      { name: 'teeth' },
      { name: 'teeth-open' },
      { name: 'thermometer' },
      { name: 'tooth' },
      { name: 'truck-medical' },
      { name: 'user-doctor' },
      { name: 'user-nurse' },
      { name: 'vial' },
      { name: 'vial-circle-check' },
      { name: 'vial-virus' },
      { name: 'vials' },
      { name: 'virus' },
      { name: 'virus-covid' },
      { name: 'virus-covid-slash' },
      { name: 'virus-slash' },
      { name: 'viruses' },
      { name: 'weight-scale' },
      { name: 'wheelchair' },
      { name: 'wheelchair-move' },
      { name: 'x-ray' },
    ],
  },
  money: {
    title: 'Money',
    list: [
      { name: 'austral-sign' },
      { name: 'baht-sign' },
      { name: 'bangladeshi-taka-sign' },
      { name: 'bitcoin-sign' },
      { name: 'brazilian-real-sign' },
      { name: 'cash-register' },
      { name: 'cedi-sign' },
      { name: 'cent-sign' },
      { name: 'chart-line' },
      { name: 'chart-pie' },
      { name: 'circle-dollar-to-slot' },
      { name: 'coins' },
      { name: 'colon-sign' },
      { name: 'comment-dollar' },
      { name: 'comments-dollar' },
      { name: 'credit-card' },
      { name: 'cruzeiro-sign' },
      { name: 'dollar-sign' },
      { name: 'dong-sign' },
      { name: 'euro-sign' },
      { name: 'file-invoice' },
      { name: 'file-invoice-dollar' },
      { name: 'florin-sign' },
      { name: 'franc-sign' },
      { name: 'guarani-sign' },
      { name: 'hand-holding-dollar' },
      { name: 'hryvnia-sign' },
      { name: 'indian-rupee-sign' },
      { name: 'kip-sign' },
      { name: 'landmark' },
      { name: 'lari-sign' },
      { name: 'lira-sign' },
      { name: 'litecoin-sign' },
      { name: 'manat-sign' },
      { name: 'mill-sign' },
      { name: 'money-bill' },
      { name: 'money-bill-1' },
      { name: 'money-bill-1-wave' },
      { name: 'money-bill-transfer' },
      { name: 'money-bill-trend-up' },
      { name: 'money-bill-wave' },
      { name: 'money-bill-wheat' },
      { name: 'money-bills' },
      { name: 'money-check' },
      { name: 'money-check-dollar' },
      { name: 'naira-sign' },
      { name: 'percent' },
      { name: 'peseta-sign' },
      { name: 'peso-sign' },
      { name: 'piggy-bank' },
      { name: 'receipt' },
      { name: 'ruble-sign' },
      { name: 'rupee-sign' },
      { name: 'rupiah-sign' },
      { name: 'sack-dollar' },
      { name: 'sack-xmark' },
      { name: 'scale-balanced' },
      { name: 'scale-unbalanced' },
      { name: 'scale-unbalanced-flip' },
      { name: 'shekel-sign' },
      { name: 'stamp' },
      { name: 'sterling-sign' },
      { name: 'tenge-sign' },
      { name: 'turkish-lira-sign' },
      { name: 'vault' },
      { name: 'wallet' },
      { name: 'won-sign' },
      { name: 'yen-sign' },
    ],
  },
  moving: {
    title: 'Moving',
    list: [
      { name: 'box-archive' },
      { name: 'box-open' },
      { name: 'boxes-packing' },
      { name: 'caravan' },
      { name: 'couch' },
      { name: 'dolly' },
      { name: 'house-chimney' },
      { name: 'people-carry-box' },
      { name: 'route' },
      { name: 'sign-hanging' },
      { name: 'suitcase' },
      { name: 'tape' },
      { name: 'trailer' },
      { name: 'truck-moving' },
      { name: 'truck-ramp-box' },
      { name: 'wine-glass' },
    ],
  },
  'music-audio': {
    title: 'Music + Audio',
    list: [
      { name: 'compact-disc' },
      { name: 'drum' },
      { name: 'drum-steelpan' },
      { name: 'file-audio' },
      { name: 'guitar' },
      { name: 'headphones' },
      { name: 'headphones-simple' },
      { name: 'microphone' },
      { name: 'microphone-lines' },
      { name: 'microphone-lines-slash' },
      { name: 'microphone-slash' },
      { name: 'music' },
      { name: 'radio' },
      { name: 'record-vinyl' },
      { name: 'sliders' },
      { name: 'volume-high' },
      { name: 'volume-low' },
      { name: 'volume-off' },
      { name: 'volume-xmark' },
    ],
  },
  nature: {
    title: 'Nature',
    list: [
      { name: 'binoculars' },
      { name: 'bug' },
      { name: 'bugs' },
      { name: 'cannabis' },
      { name: 'cloud-sun' },
      { name: 'clover' },
      { name: 'feather' },
      { name: 'feather-pointed' },
      { name: 'fire' },
      { name: 'frog' },
      { name: 'icicles' },
      { name: 'leaf' },
      { name: 'locust' },
      { name: 'mosquito' },
      { name: 'mound' },
      { name: 'mountain' },
      { name: 'mountain-city' },
      { name: 'mountain-sun' },
      { name: 'person-hiking' },
      { name: 'plant-wilt' },
      { name: 'seedling' },
      { name: 'signs-post' },
      { name: 'spider' },
      { name: 'tree' },
      { name: 'volcano' },
      { name: 'water' },
      { name: 'wind' },
      { name: 'worm' },
    ],
  },
  numbers: {
    title: 'Numbers',
    list: [
      { name: '0' },
      { name: '1' },
      { name: '2' },
      { name: '3' },
      { name: '4' },
      { name: '5' },
      { name: '6' },
      { name: '7' },
      { name: '8' },
      { name: '9' },
    ],
  },
  'photos-images': {
    title: 'Photos + Images',
    list: [
      { name: 'bolt' },
      { name: 'bolt-lightning' },
      { name: 'camera' },
      { name: 'camera-retro' },
      { name: 'camera-rotate' },
      { name: 'chalkboard' },
      { name: 'circle-half-stroke' },
      { name: 'clone' },
      { name: 'droplet' },
      { name: 'eye' },
      { name: 'eye-dropper' },
      { name: 'eye-slash' },
      { name: 'file-image' },
      { name: 'film' },
      { name: 'id-badge' },
      { name: 'id-card' },
      { name: 'image' },
      { name: 'image-portrait' },
      { name: 'images' },
      { name: 'minimize' },
      { name: 'panorama' },
      { name: 'photo-film' },
      { name: 'sliders' },
    ],
  },
  political: {
    title: 'Political',
    list: [
      { name: 'award' },
      { name: 'building-flag' },
      { name: 'bullhorn' },
      { name: 'check-double' },
      { name: 'check-to-slot' },
      { name: 'circle-dollar-to-slot' },
      { name: 'democrat' },
      { name: 'dove' },
      { name: 'dumpster-fire' },
      { name: 'flag-usa' },
      { name: 'hand-fist' },
      { name: 'handshake' },
      { name: 'landmark-dome' },
      { name: 'landmark-flag' },
      { name: 'person-booth' },
      { name: 'piggy-bank' },
      { name: 'republican' },
      { name: 'scale-balanced' },
      { name: 'scale-unbalanced' },
      { name: 'scale-unbalanced-flip' },
    ],
  },
  'punctuation-symbols': {
    title: 'Punctuation + Symbols',
    list: [
      { name: 'asterisk' },
      { name: 'at' },
      { name: 'check' },
      { name: 'check-double' },
      { name: 'circle-exclamation' },
      { name: 'circle-question' },
      { name: 'equals' },
      { name: 'exclamation' },
      { name: 'greater-than' },
      { name: 'hashtag' },
      { name: 'less-than' },
      { name: 'minus' },
      { name: 'percent' },
      { name: 'plus' },
      { name: 'question' },
      { name: 'quote-left' },
      { name: 'quote-right' },
      { name: 'section' },
    ],
  },
  religion: {
    title: 'Religion',
    list: [
      { name: 'ankh' },
      { name: 'atom' },
      { name: 'bahai' },
      { name: 'book-bible' },
      { name: 'book-journal-whills' },
      { name: 'book-quran' },
      { name: 'book-tanakh' },
      { name: 'church' },
      { name: 'cross' },
      { name: 'dharmachakra' },
      { name: 'dove' },
      { name: 'gopuram' },
      { name: 'hamsa' },
      { name: 'hands-praying' },
      { name: 'hanukiah' },
      { name: 'jedi' },
      { name: 'kaaba' },
      { name: 'khanda' },
      { name: 'menorah' },
      { name: 'mosque' },
      { name: 'om' },
      { name: 'peace' },
      { name: 'person-praying' },
      { name: 'place-of-worship' },
      { name: 'scroll-torah' },
      { name: 'spaghetti-monster-flying' },
      { name: 'star-and-crescent' },
      { name: 'star-of-david' },
      { name: 'synagogue' },
      { name: 'torii-gate' },
      { name: 'vihara' },
      { name: 'yin-yang' },
    ],
  },
  science: {
    title: 'Science',
    list: [
      { name: 'atom' },
      { name: 'biohazard' },
      { name: 'brain' },
      { name: 'capsules' },
      { name: 'circle-radiation' },
      { name: 'clipboard-check' },
      { name: 'disease' },
      { name: 'dna' },
      { name: 'eye-dropper' },
      { name: 'filter' },
      { name: 'fire' },
      { name: 'fire-flame-curved' },
      { name: 'fire-flame-simple' },
      { name: 'flask' },
      { name: 'flask-vial' },
      { name: 'frog' },
      { name: 'magnet' },
      { name: 'microscope' },
      { name: 'mortar-pestle' },
      { name: 'pills' },
      { name: 'prescription-bottle' },
      { name: 'radiation' },
      { name: 'seedling' },
      { name: 'skull-crossbones' },
      { name: 'square-virus' },
      { name: 'syringe' },
      { name: 'tablets' },
      { name: 'temperature-high' },
      { name: 'temperature-low' },
      { name: 'vial' },
      { name: 'vial-circle-check' },
      { name: 'vial-virus' },
      { name: 'vials' },
    ],
  },
  'science-fiction': {
    title: 'Science Fiction',
    list: [
      { name: 'atom' },
      { name: 'book-journal-whills' },
      { name: 'explosion' },
      { name: 'hand-spock' },
      { name: 'jedi' },
      { name: 'robot' },
      { name: 'rocket' },
      { name: 'user-astronaut' },
    ],
  },
  security: {
    title: 'Security',
    list: [
      { name: 'ban' },
      { name: 'bug' },
      { name: 'bug-slash' },
      { name: 'building-lock' },
      { name: 'building-shield' },
      { name: 'burst' },
      { name: 'car-on' },
      { name: 'door-closed' },
      { name: 'door-open' },
      { name: 'dungeon' },
      { name: 'explosion' },
      { name: 'eye' },
      { name: 'eye-slash' },
      { name: 'file-contract' },
      { name: 'file-shield' },
      { name: 'file-signature' },
      { name: 'fingerprint' },
      { name: 'gun' },
      { name: 'handcuffs' },
      { name: 'hands-bound' },
      { name: 'hands-holding-child' },
      { name: 'hands-holding-circle' },
      { name: 'house-fire' },
      { name: 'house-lock' },
      { name: 'id-badge' },
      { name: 'id-card' },
      { name: 'id-card-clip' },
      { name: 'key' },
      { name: 'land-mine-on' },
      { name: 'lock' },
      { name: 'lock-open' },
      { name: 'mars-and-venus-burst' },
      { name: 'mask' },
      { name: 'passport' },
      { name: 'people-pulling' },
      { name: 'people-robbery' },
      { name: 'person-burst' },
      { name: 'person-dress-burst' },
      { name: 'person-falling-burst' },
      { name: 'person-harassing' },
      { name: 'person-military-pointing' },
      { name: 'person-military-rifle' },
      { name: 'person-military-to-person' },
      { name: 'person-rifle' },
      { name: 'person-shelter' },
      { name: 'person-through-window' },
      { name: 'road-spikes' },
      { name: 'shield' },
      { name: 'shield-cat' },
      { name: 'shield-dog' },
      { name: 'shield-halved' },
      { name: 'shield-heart' },
      { name: 'skull-crossbones' },
      { name: 'square-person-confined' },
      { name: 'tower-observation' },
      { name: 'unlock' },
      { name: 'unlock-keyhole' },
      { name: 'user-lock' },
      { name: 'user-secret' },
      { name: 'user-shield' },
      { name: 'vault' },
    ],
  },
  shapes: {
    title: 'Shapes',
    list: [
      { name: 'bookmark' },
      { name: 'burst' },
      { name: 'calendar' },
      { name: 'certificate' },
      { name: 'circle' },
      { name: 'circle-half-stroke' },
      { name: 'cloud' },
      { name: 'clover' },
      { name: 'comment' },
      { name: 'crown' },
      { name: 'cubes-stacked' },
      { name: 'diamond' },
      { name: 'file' },
      { name: 'folder' },
      { name: 'heart' },
      { name: 'heart-crack' },
      { name: 'lines-leaning' },
      { name: 'location-pin' },
      { name: 'play' },
      { name: 'shapes' },
      { name: 'shield' },
      { name: 'square' },
      { name: 'star' },
      { name: 'ticket-simple' },
    ],
  },
  shopping: {
    title: 'Shopping',
    list: [
      { name: 'bag-shopping' },
      { name: 'barcode' },
      { name: 'basket-shopping' },
      { name: 'bell' },
      { name: 'bookmark' },
      { name: 'bullhorn' },
      { name: 'camera' },
      { name: 'camera-retro' },
      { name: 'cart-arrow-down' },
      { name: 'cart-plus' },
      { name: 'cart-shopping' },
      { name: 'cash-register' },
      { name: 'certificate' },
      { name: 'credit-card' },
      { name: 'gem' },
      { name: 'gift' },
      { name: 'gifts' },
      { name: 'handshake' },
      { name: 'heart' },
      { name: 'key' },
      { name: 'money-check' },
      { name: 'money-check-dollar' },
      { name: 'person-booth' },
      { name: 'receipt' },
      { name: 'shirt' },
      { name: 'shop' },
      { name: 'shop-lock' },
      { name: 'shop-slash' },
      { name: 'star' },
      { name: 'store' },
      { name: 'store-slash' },
      { name: 'tag' },
      { name: 'tags' },
      { name: 'thumbs-down' },
      { name: 'thumbs-up' },
      { name: 'trophy' },
      { name: 'truck' },
      { name: 'truck-fast' },
    ],
  },
  social: {
    title: 'Social',
    list: [
      { name: 'bell' },
      { name: 'cake-candles' },
      { name: 'camera' },
      { name: 'circle-user' },
      { name: 'comment' },
      { name: 'envelope' },
      { name: 'hashtag' },
      { name: 'heart' },
      { name: 'icons' },
      { name: 'image' },
      { name: 'images' },
      { name: 'location-dot' },
      { name: 'location-pin' },
      { name: 'message' },
      { name: 'photo-film' },
      { name: 'retweet' },
      { name: 'share' },
      { name: 'share-from-square' },
      { name: 'share-nodes' },
      { name: 'square-poll-horizontal' },
      { name: 'square-poll-vertical' },
      { name: 'square-share-nodes' },
      { name: 'star' },
      { name: 'thumbs-down' },
      { name: 'thumbs-up' },
      { name: 'thumbtack' },
      { name: 'user' },
      { name: 'user-group' },
      { name: 'user-plus' },
      { name: 'users' },
      { name: 'video' },
    ],
  },
  spinners: {
    title: 'Spinners',
    list: [
      { name: 'arrows-spin' },
      { name: 'asterisk' },
      { name: 'atom' },
      { name: 'bahai' },
      { name: 'certificate' },
      { name: 'circle-notch' },
      { name: 'compact-disc' },
      { name: 'compass' },
      { name: 'crosshairs' },
      { name: 'dharmachakra' },
      { name: 'fan' },
      { name: 'gear' },
      { name: 'hurricane' },
      { name: 'life-ring' },
      { name: 'palette' },
      { name: 'ring' },
      { name: 'rotate' },
      { name: 'slash' },
      { name: 'snowflake' },
      { name: 'spinner' },
      { name: 'stroopwafel' },
      { name: 'sun' },
      { name: 'yin-yang' },
    ],
  },
  'sports-fitness': {
    title: 'Sports + Fitness',
    list: [
      { name: 'baseball' },
      { name: 'baseball-bat-ball' },
      { name: 'basketball' },
      { name: 'bicycle' },
      { name: 'bowling-ball' },
      { name: 'broom-ball' },
      { name: 'dumbbell' },
      { name: 'fire-flame-curved' },
      { name: 'fire-flame-simple' },
      { name: 'football' },
      { name: 'futbol' },
      { name: 'golf-ball-tee' },
      { name: 'heart' },
      { name: 'heart-pulse' },
      { name: 'hockey-puck' },
      { name: 'medal' },
      { name: 'mound' },
      { name: 'person-biking' },
      { name: 'person-hiking' },
      { name: 'person-running' },
      { name: 'person-skating' },
      { name: 'person-skiing' },
      { name: 'person-skiing-nordic' },
      { name: 'person-snowboarding' },
      { name: 'person-swimming' },
      { name: 'person-walking' },
      { name: 'ranking-star' },
      { name: 'shoe-prints' },
      { name: 'spa' },
      { name: 'stopwatch-20' },
      { name: 'table-tennis-paddle-ball' },
      { name: 'volleyball' },
      { name: 'weight-hanging' },
    ],
  },
  'text-formatting': {
    title: 'Text Formatting',
    list: [
      { name: 'align-center' },
      { name: 'align-justify' },
      { name: 'align-left' },
      { name: 'align-right' },
      { name: 'bold' },
      { name: 'border-all' },
      { name: 'border-none' },
      { name: 'border-top-left' },
      { name: 'check' },
      { name: 'check-double' },
      { name: 'circle-check' },
      { name: 'filter-circle-xmark' },
      { name: 'font' },
      { name: 'heading' },
      { name: 'highlighter' },
      { name: 'i-cursor' },
      { name: 'icons' },
      { name: 'indent' },
      { name: 'italic' },
      { name: 'list' },
      { name: 'list-check' },
      { name: 'list-ol' },
      { name: 'list-ul' },
      { name: 'outdent' },
      { name: 'paragraph' },
      { name: 'rectangle-list' },
      { name: 'spell-check' },
      { name: 'square-check' },
      { name: 'strikethrough' },
      { name: 'subscript' },
      { name: 'superscript' },
      { name: 'table' },
      { name: 'table-cells' },
      { name: 'table-cells-large' },
      { name: 'table-columns' },
      { name: 'table-list' },
      { name: 'text-height' },
      { name: 'text-slash' },
      { name: 'text-width' },
      { name: 'underline' },
    ],
  },
  time: {
    title: 'Time',
    list: [
      { name: 'bell' },
      { name: 'bell-slash' },
      { name: 'calendar' },
      { name: 'calendar-check' },
      { name: 'calendar-day' },
      { name: 'calendar-days' },
      { name: 'calendar-minus' },
      { name: 'calendar-plus' },
      { name: 'calendar-week' },
      { name: 'calendar-xmark' },
      { name: 'clock' },
      { name: 'hourglass' },
      { name: 'hourglass-end' },
      { name: 'hourglass-half' },
      { name: 'hourglass-start' },
      { name: 'stopwatch' },
      { name: 'stopwatch-20' },
    ],
  },
  toggle: {
    title: 'Toggle',
    list: [
      { name: 'bullseye' },
      { name: 'circle' },
      { name: 'circle-check' },
      { name: 'circle-dot' },
      { name: 'location-crosshairs' },
      { name: 'microphone' },
      { name: 'microphone-slash' },
      { name: 'plane-up' },
      { name: 'signal' },
      { name: 'star' },
      { name: 'star-half' },
      { name: 'star-half-stroke' },
      { name: 'toggle-off' },
      { name: 'toggle-on' },
      { name: 'wifi' },
    ],
  },
  transportation: {
    title: 'Transportation',
    list: [
      { name: 'baby-carriage' },
      { name: 'bicycle' },
      { name: 'bus' },
      { name: 'bus-simple' },
      { name: 'cable-car' },
      { name: 'car' },
      { name: 'car-burst' },
      { name: 'car-rear' },
      { name: 'car-side' },
      { name: 'car-tunnel' },
      { name: 'cart-shopping' },
      { name: 'ferry' },
      { name: 'helicopter' },
      { name: 'horse' },
      { name: 'jet-fighter' },
      { name: 'jet-fighter-up' },
      { name: 'motorcycle' },
      { name: 'mound' },
      { name: 'paper-plane' },
      { name: 'plane' },
      { name: 'plane-slash' },
      { name: 'plane-up' },
      { name: 'road' },
      { name: 'road-barrier' },
      { name: 'road-spikes' },
      { name: 'rocket' },
      { name: 'sailboat' },
      { name: 'ship' },
      { name: 'shuttle-space' },
      { name: 'sleigh' },
      { name: 'snowplow' },
      { name: 'taxi' },
      { name: 'tractor' },
      { name: 'train' },
      { name: 'train-subway' },
      { name: 'train-tram' },
      { name: 'truck' },
      { name: 'truck-arrow-right' },
      { name: 'truck-droplet' },
      { name: 'truck-field' },
      { name: 'truck-field-un' },
      { name: 'truck-front' },
      { name: 'truck-medical' },
      { name: 'truck-monster' },
      { name: 'truck-pickup' },
      { name: 'truck-plane' },
      { name: 'van-shuttle' },
      { name: 'wheelchair' },
      { name: 'wheelchair-move' },
    ],
  },
  'travel-hotel': {
    title: 'Travel + Hotel',
    list: [
      { name: 'archway' },
      { name: 'baby-carriage' },
      { name: 'ban-smoking' },
      { name: 'bath' },
      { name: 'bed' },
      { name: 'bell-concierge' },
      { name: 'book-atlas' },
      { name: 'briefcase' },
      { name: 'bus' },
      { name: 'bus-simple' },
      { name: 'cable-car' },
      { name: 'car' },
      { name: 'caravan' },
      { name: 'cart-flatbed-suitcase' },
      { name: 'dice' },
      { name: 'dice-five' },
      { name: 'door-closed' },
      { name: 'door-open' },
      { name: 'dumbbell' },
      { name: 'earth-africa' },
      { name: 'earth-americas' },
      { name: 'earth-asia' },
      { name: 'earth-europe' },
      { name: 'earth-oceania' },
      { name: 'elevator' },
      { name: 'hot-tub-person' },
      { name: 'hotel' },
      { name: 'infinity' },
      { name: 'key' },
      { name: 'kitchen-set' },
      { name: 'map' },
      { name: 'map-location' },
      { name: 'map-location-dot' },
      { name: 'martini-glass' },
      { name: 'martini-glass-citrus' },
      { name: 'martini-glass-empty' },
      { name: 'monument' },
      { name: 'mountain-city' },
      { name: 'mug-saucer' },
      { name: 'passport' },
      { name: 'person-swimming' },
      { name: 'person-walking-luggage' },
      { name: 'plane' },
      { name: 'plane-arrival' },
      { name: 'plane-circle-check' },
      { name: 'plane-circle-exclamation' },
      { name: 'plane-circle-xmark' },
      { name: 'plane-departure' },
      { name: 'plane-lock' },
      { name: 'plane-slash' },
      { name: 'plane-up' },
      { name: 'shower' },
      { name: 'smoking' },
      { name: 'snowflake' },
      { name: 'spa' },
      { name: 'stairs' },
      { name: 'suitcase' },
      { name: 'suitcase-rolling' },
      { name: 'taxi' },
      { name: 'toilet' },
      { name: 'toilet-paper' },
      { name: 'train-tram' },
      { name: 'tree-city' },
      { name: 'tv' },
      { name: 'umbrella-beach' },
      { name: 'utensils' },
      { name: 'van-shuttle' },
      { name: 'water-ladder' },
      { name: 'wheelchair' },
      { name: 'wheelchair-move' },
      { name: 'wifi' },
      { name: 'wine-glass' },
      { name: 'wine-glass-empty' },
    ],
  },
  'users-people': {
    title: 'Users + People',
    list: [
      { name: 'address-book' },
      { name: 'address-card' },
      { name: 'arrows-down-to-people' },
      { name: 'baby' },
      { name: 'bed' },
      { name: 'chalkboard-user' },
      { name: 'child' },
      { name: 'child-dress' },
      { name: 'child-reaching' },
      { name: 'children' },
      { name: 'circle-user' },
      { name: 'clipboard-user' },
      { name: 'elevator' },
      { name: 'face-frown' },
      { name: 'face-meh' },
      { name: 'face-smile' },
      { name: 'head-side-cough' },
      { name: 'head-side-cough-slash' },
      { name: 'head-side-mask' },
      { name: 'head-side-virus' },
      { name: 'hospital-user' },
      { name: 'hot-tub-person' },
      { name: 'house-chimney-user' },
      { name: 'house-user' },
      { name: 'id-badge' },
      { name: 'id-card' },
      { name: 'id-card-clip' },
      { name: 'image-portrait' },
      { name: 'mars-and-venus-burst' },
      { name: 'people-arrows' },
      { name: 'people-carry-box' },
      { name: 'people-group' },
      { name: 'people-line' },
      { name: 'people-pulling' },
      { name: 'people-robbery' },
      { name: 'people-roof' },
      { name: 'person' },
      { name: 'person-arrow-down-to-line' },
      { name: 'person-arrow-up-from-line' },
      { name: 'person-biking' },
      { name: 'person-booth' },
      { name: 'person-breastfeeding' },
      { name: 'person-burst' },
      { name: 'person-cane' },
      { name: 'person-chalkboard' },
      { name: 'person-circle-check' },
      { name: 'person-circle-exclamation' },
      { name: 'person-circle-minus' },
      { name: 'person-circle-plus' },
      { name: 'person-circle-question' },
      { name: 'person-circle-xmark' },
      { name: 'person-digging' },
      { name: 'person-dots-from-line' },
      { name: 'person-dress' },
      { name: 'person-dress-burst' },
      { name: 'person-drowning' },
      { name: 'person-falling' },
      { name: 'person-falling-burst' },
      { name: 'person-half-dress' },
      { name: 'person-harassing' },
      { name: 'person-hiking' },
      { name: 'person-military-pointing' },
      { name: 'person-military-rifle' },
      { name: 'person-military-to-person' },
      { name: 'person-praying' },
      { name: 'person-pregnant' },
      { name: 'person-rays' },
      { name: 'person-rifle' },
      { name: 'person-running' },
      { name: 'person-shelter' },
      { name: 'person-skating' },
      { name: 'person-skiing' },
      { name: 'person-skiing-nordic' },
      { name: 'person-snowboarding' },
      { name: 'person-swimming' },
      { name: 'person-through-window' },
      { name: 'person-walking' },
      { name: 'person-walking-arrow-loop-left' },
      { name: 'person-walking-arrow-right' },
      { name: 'person-walking-dashed-line-arrow-right' },
      { name: 'person-walking-luggage' },
      { name: 'person-walking-with-cane' },
      { name: 'poo' },
      { name: 'restroom' },
      { name: 'skull' },
      { name: 'square-person-confined' },
      { name: 'street-view' },
      { name: 'user' },
      { name: 'user-astronaut' },
      { name: 'user-check' },
      { name: 'user-clock' },
      { name: 'user-doctor' },
      { name: 'user-gear' },
      { name: 'user-graduate' },
      { name: 'user-group' },
      { name: 'user-injured' },
      { name: 'user-large' },
      { name: 'user-large-slash' },
      { name: 'user-lock' },
      { name: 'user-minus' },
      { name: 'user-ninja' },
      { name: 'user-nurse' },
      { name: 'user-pen' },
      { name: 'user-plus' },
      { name: 'user-secret' },
      { name: 'user-shield' },
      { name: 'user-slash' },
      { name: 'user-tag' },
      { name: 'user-tie' },
      { name: 'user-xmark' },
      { name: 'users' },
      { name: 'users-between-lines' },
      { name: 'users-gear' },
      { name: 'users-line' },
      { name: 'users-rays' },
      { name: 'users-rectangle' },
      { name: 'users-slash' },
      { name: 'users-viewfinder' },
      { name: 'wheelchair' },
      { name: 'wheelchair-move' },
    ],
  },
  weather: {
    title: 'Weather',
    list: [
      { name: 'bolt' },
      { name: 'bolt-lightning' },
      { name: 'cloud' },
      { name: 'cloud-bolt' },
      { name: 'cloud-meatball' },
      { name: 'cloud-moon' },
      { name: 'cloud-moon-rain' },
      { name: 'cloud-rain' },
      { name: 'cloud-showers-heavy' },
      { name: 'cloud-showers-water' },
      { name: 'cloud-sun' },
      { name: 'cloud-sun-rain' },
      { name: 'house-tsunami' },
      { name: 'hurricane' },
      { name: 'icicles' },
      { name: 'meteor' },
      { name: 'moon' },
      { name: 'poo-storm' },
      { name: 'rainbow' },
      { name: 'smog' },
      { name: 'snowflake' },
      { name: 'sun' },
      { name: 'sun-plant-wilt' },
      { name: 'temperature-arrow-down' },
      { name: 'temperature-arrow-up' },
      { name: 'temperature-empty' },
      { name: 'temperature-full' },
      { name: 'temperature-half' },
      { name: 'temperature-high' },
      { name: 'temperature-low' },
      { name: 'temperature-quarter' },
      { name: 'temperature-three-quarters' },
      { name: 'tornado' },
      { name: 'umbrella' },
      { name: 'volcano' },
      { name: 'water' },
      { name: 'wind' },
    ],
  },
  writing: {
    title: 'Writing',
    list: [
      { name: 'blog' },
      { name: 'book' },
      { name: 'book-bookmark' },
      { name: 'bookmark' },
      { name: 'box-archive' },
      { name: 'envelope' },
      { name: 'envelope-open' },
      { name: 'eraser' },
      { name: 'file' },
      { name: 'file-lines' },
      { name: 'folder' },
      { name: 'folder-open' },
      { name: 'keyboard' },
      { name: 'newspaper' },
      { name: 'notdef' },
      { name: 'note-sticky' },
      { name: 'paper-plane' },
      { name: 'paperclip' },
      { name: 'paragraph' },
      { name: 'pen' },
      { name: 'pen-clip' },
      { name: 'pen-to-square' },
      { name: 'pencil' },
      { name: 'quote-left' },
      { name: 'quote-right' },
      { name: 'signature' },
      { name: 'square-pen' },
      { name: 'thumbtack' },
    ],
  },
};
