/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import alfresco from './alfresco/alfresco';
import menu from './menu';
import structure from './HeaderStructure';
import govService from './govServices/service';
import apiUft from './apiUft/apiUft';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  alfresco,
  menu,
  structure,
  govService,
  apiUft,
  // Add your reducers here
};

export default reducers;
