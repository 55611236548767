import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import './button.less';
import cx from 'classnames';

const ButtonBlockView = (props, className) => {
  const { data } = props || {};

  const buttonType = data.buttonType ? data.buttonType : '';

  // const buttonProps =
  //   data.href !== undefined && data.href.length === 1 ? data.href[0] : null;

  const link = data.href !== undefined && data.href.length === 1 ? data.href[0]?.['@id'] : data.external_link;

  return (
    <div className={cx('block __button', className)}>
      <div className={cx(`align ${data?.inneralign}`)}>
        <a
          className={'br-button ' + buttonType}
          href={link}
          target={data.openLinkInNewPage ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {data.text}
        </a>
      </div>
    </div>
  );
};

export default withBlockExtensions(ButtonBlockView);
