import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { difference } from '@plone/volto/helpers';
import { usePrevious /* , replaceItemOfArray */ } from '@plone/volto/helpers';

import { pessoasSchema } from './schema';

export const replaceItemOfArray = (array, index, value) => Object.assign([...array], { [index]: value });

const PessoasData = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();
  const { pessoas } = props.data;
  const previous = usePrevious(pessoas);
  const schema = pessoasSchema({ ...props, intl });

  React.useEffect(() => {
    if (previous) {
      const diff = difference(pessoas, previous);
      const index = diff.findIndex((val) => val);
      if (diff[index]?.href?.[0]) {
        onChangeBlock(block, {
          ...data,
          pessoas: replaceItemOfArray(data.pessoas, index, {
            ...data.pessoas[index],
            nome: diff[index].href[0].nome,
            cargo: diff[index].href[0].cargo,
            email: diff[index].href[0].email,
            lattes: diff[index].href[0].lattes,
          }),
        });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pessoas]);

  return (
    <BlockDataForm
      schema={schema}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      onChangeBlock={onChangeBlock}
      block={block}
    />
  );
};

PessoasData.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default PessoasData;
