export const AlfrescolinkEditorSchema = {
  title: 'Link do alfresco',
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['filename', 'message', 'link', 'targetNewPage'],
    },
  ],
  properties: {
    message: {
      title: 'mensagem',
      type: 'string',
    },
    filename: {
      title: 'Nome do Arquivo',
      type: 'string',
    },
    link: {
      title: 'Arquivo',
      type: 'string',
      widget: 'alfrescoNav',
    },
    targetNewPage: {
      title: 'Abrir em nova janela',
      type: 'boolean',
    },
  },
  required: ['link'],
};
