import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import { LanguageSelector, SearchWidget, Icon } from '@plone/volto/components';
// EXTRA IMPORT
import AvatarComponent from '@package/components/GovBr/avatar';
import { CircleButton } from '@package/components/GovBr/button';
import logoHeader from '@package/images/logos/LogoHeader.svg';
import menuIcon from '@package/icons/bars-solid.svg';
import useHeader from './useHeader';
import MenuTourProvider from './TourMenu/MenuTourProvider';
import NavigationHeaderUFT from './NavigationHeaderUFT';
// EXTRA IMPORT

const Header = ({ pathname, toggleMenu }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const { firstText, secondText, thirdText, showActions, toogleActions, showSearch, toogleSearch, dropdownRef } =
    useHeader({ pathname });
  return (
    <MenuTourProvider>
      <header className="br-header small">
        <Container className="container-lg">
          <div className="header-top">
            <div className="header-logo">
              <a href="/">
                <img src={logoHeader} alt="logo" width={85} height={40} />
              </a>
              <span className="br-divider vertical mx-half mx-sm-1"></span>
              <div className="header-sign">{firstText}</div>
            </div>
            <div className="header-actions">
              <div className={`header-links dropdown ${showActions && 'show'}`} ref={dropdownRef}>
                <button
                  className={`br-button circle small ${showActions && 'active'}`}
                  onClick={toogleActions}
                  type="button"
                  data-toggle="dropdown"
                  aria-label="Abrir Acesso Rápido"
                >
                  <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
                </button>
                <div className="br-list">
                  <div className="header">
                    <div className="title">Acesso Rápido</div>
                  </div>
                  <NavigationHeaderUFT pathname={pathname} onClick={toogleActions} />
                </div>
              </div>
              <span className="br-divider vertical mx-half mx-sm-1"></span>
              <div className="header-search-trigger">
                <button
                  className="br-button circle"
                  onClick={toogleSearch}
                  type="button"
                  aria-label="Abrir Busca"
                  data-toggle="search"
                  data-target=".header-search"
                >
                  <i className="fas fa-search" aria-hidden="true"></i>
                </button>
              </div>
              <LanguageSelector />
              <AvatarComponent token={token} />
            </div>
          </div>
          <div className="header-bottom">
            <div className="header-menu">
              <div className="header-menu-trigger">
                <button className="br-button small circle" type="button" id="menu-small" onClick={() => toggleMenu()}>
                  <Icon name={menuIcon} size="24px" />
                </button>
              </div>
              <div className="header-info">
                <div className="header-title">{secondText}</div>
                <div className="header-subtitle">{thirdText}</div>
              </div>
            </div>
            <div className={`header-search ${showSearch && 'active'}`}>
              <SearchWidget />
              {showSearch && (
                <CircleButton
                  onClick={toogleSearch}
                  className="search-close"
                  type="button"
                  aria-label="Fechar Busca"
                  data-dismiss="search"
                >
                  <i className="fas fa-times" aria-hidden="true"></i>
                </CircleButton>
              )}
            </div>
          </div>
        </Container>
      </header>
    </MenuTourProvider>
  );
};

export default Header;

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};
