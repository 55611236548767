import React from 'react';
import { Popup } from 'semantic-ui-react';
import './Styles.css';

const AlfrescolinkElement = (props) => {
  const { children, element } = props;
  const { data = {} } = element;

  return (
    <>
      {data.message && (
        <Popup
          position="top center"
          trigger={
            <a
              href={data.link}
              target={data.targetNewPage ? '_blank' : '_self'}
              rel="noreferrer"
              title={data.filename}
              className="link-without-decoration"
            >
              {children}
            </a>
          }
        >
          {data.message}
        </Popup>
      )}
      {!data.message && (
        <a
          href={data.link}
          target={data.targetNewPage ? '_blank' : '_self'}
          rel="noreferrer"
          title={data.filename}
          className="link-without-decoration"
        >
          {children}
        </a>
      )}
    </>
  );
};

export default AlfrescolinkElement;
