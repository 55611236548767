/**
 * Menu actions.
 * @module actions/menu/index
 */

import { GET_MENU, GET_FULL_MENU, GET_PAGINED_MENU } from '@package/constants/ActionTypes';

/**
 * Request menu from path  function.
 * @function getMenu
 * @returns {Object} Entry menu.
 * @param url
 */
export function getMenu(url) {
  return {
    type: GET_MENU,
    request: {
      op: 'get',
      path: `${url}/@settings`,
    },
  };
}

/**
 * Request menu from path  function.
 * @function getFullMenu
 * @returns {Object} Entry menu.
 * @param url
 */
export function getFullMenu(url) {
  return {
    type: GET_FULL_MENU,
    request: {
      op: 'get',
      path: `@full_menu`,
    },
  };
}

/**
 * Request menu from pagined path  function.
 * @function getPaginedMenu
 * @returns {Object} Entry menu.
 * @param pathname
 */
export function getPaginedMenu(pathname = '/') {
  const path = `${pathname}/@pagined_menu`;
  return {
    type: GET_PAGINED_MENU,
    request: {
      op: 'get',
      path: path,
    },
  };
}
