/**
 * RatingWidget component.
 * @module components/RatingWidget/RatingWidget
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import { compose } from 'redux';
import ProfileCrop from '../../ImageCrop/ProfileCrop';

/**
 * RatingWidget component class.
 * @function RatingWidget
 * @returns {string} Markup of the component.
 */
const ProfilePicWidget = ({ id, title, required, description, error, value, onChange, onEdit, onDelete }) => {
  return (
    <Form.Field
      inline
      required={required}
      error={error.length > 0}
      className={description ? 'help' : ''}
      style={{
        paddingTop: '10px',
      }}
    >
      <div
        className="wrapper"
        style={{
          borderBottom: 'none',
        }}
      >
        <ProfileCrop title={title} onChange={(value) => onChange(id, value)} value={value} />
      </div>
    </Form.Field>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ProfilePicWidget.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ProfilePicWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: '',
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default compose(injectIntl)(ProfilePicWidget);
