import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const options = [
  { key: 'relevance', text: <FormattedMessage id="Relevance" defaultMessage="Relevance" />, value: 'relevance' },
  {
    key: 'sortable_title',
    text: <FormattedMessage id="Alphabetically" defaultMessage="Alphabetically" />,
    value: 'sortable_title',
  },
  {
    key: 'effective',
    text: <FormattedMessage id="Date (newest first)" defaultMessage="Date (newest first)" />,
    value: 'effective',
  },
];

const OrderSearch = ({ active, onSelect }) => {
  const handleChange = (e, { value }) => {
    e.target.name = value;
    onSelect(e, value === 'effective' ? 'reverse' : null);
  };
  return (
    <>
      <FormattedMessage id="Sort By:" defaultMessage="Sort by:" />{' '}
      <Dropdown
        className="button-sort button-active"
        upward
        floating
        inline
        options={options}
        onChange={handleChange}
        defaultValue={active ?? 'relevance'}
      />{' '}
    </>
  );
};
export default OrderSearch;
