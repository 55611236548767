import React from 'react';
import { BlockDataForm } from '@plone/volto/components';
import { alfrescoListSchema } from './schema';
import { useIntl } from 'react-intl';
import ExploreListDocs from './ExploreListDocs';

const AlfrescoListBlockData = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();
  const schema = alfrescoListSchema({ ...props, intl });

  const onChooseFolder = (entry) => {
    onChangeBlock(block, { ...data, ...entry });
  };

  return (
    <>
      {data.id ? (
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(id, value) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          formData={data}
          block={block}
        />
      ) : (
        <>
          <ExploreListDocs
            onChangeDocument={onChooseFolder}
            mimeTypes={['image/jpeg', 'image/png', 'image/gif']}
            defaultPath={'95657c2e-fa2b-46fd-9f52-d1b276afce10'}
          />
        </>
      )}
    </>
  );
};

export default AlfrescoListBlockData;
