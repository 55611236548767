/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import AutenticacaoPage from './components/Pages/Autenticacao';
import DiplomaPage from './components/Pages/Diploma';

/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      // Add your routes here
      {
        path: '/autenticacao/certificado.php',
        component: AutenticacaoPage,
      },
      {
        path: '/autenticacao',
        component: AutenticacaoPage,
      },
      {
        path: '/diploma',
        component: DiplomaPage,
      },
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
