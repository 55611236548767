import React, { useCallback } from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { searchContent } from '@plone/volto/actions/search/search';

import ContactBody from '@package/components/View/Contact/ContactBody';
import ContactChildTable from '@package/components/View/Contact/ContactChildTable';
import ContactInfoUpdate from '@package/components/View/Contact/ContactInfoUpdate';
import ContactFloatWhatsApp from '../../View/Contact/ContactFloatWhatsApp';
import { resetSearchContent } from '@plone/volto/actions';
import { generateHash } from '@package/util/generalUtil';

const MainContactBlockView = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const contactProps = data.href !== undefined && data.href.length === 1 ? data.href[0] : null;
  const [idForSelector, setIdForSelector] = React.useState('');

  const content = useSelector((state) =>
    state.search.subrequests?.[`mainContact-${idForSelector}`]?.items?.find((i) => i),
  );
  const getKey = useCallback(async (str) => {
    if (str) {
      const hash = await generateHash(str);
      setIdForSelector(hash);
    }
  }, []);

  React.useEffect(() => {
    getKey(contactProps?.['@id']);
  }, [contactProps, getKey]);

  React.useEffect(() => {
    if (idForSelector && !content) {
      dispatch(
        searchContent(
          flattenToAppURL(contactProps['@id']),
          {
            metadata_fields: [
              'title',
              'short_title',
              'contact_campus',
              'contact_unit',
              'contact_address',
              'complement',
              'contact_neighborhood',
              'contact_city',
              'contact_cep',
              'email',
              'phone_number',
              'manager',
              'site_url',
              'whatsapp_number',
              'whatsapp_simple',
              'created',
              'contributors',
              'creators',
              'grouper',
              'grouper_availables',
              'id',
              'location_map',
              'modified',
              'observations',
              'opening_hours',
              'subjects',
              'text_button',
              'type_contact',
              'type_title',
              'whatsapp_avatar',
              'whatsapp_chat_message',
              'whatsapp_number',
              'whatsapp_status_message',
              'whatsapp_chat_message',
              'UID',
              'changeNote',
              'getPath',
            ],
          },
          `mainContact-${idForSelector}`,
        ),
      );
    }
    return () => resetSearchContent(`mainContact-${idForSelector}`);
  }, [idForSelector]);

  if (idForSelector == null || idForSelector === '') {
    return <>Aguardando carregamento da fonte de contato ...</>;
  }

  if (!content) {
    return 'Aguardando seleção da fonte de contato ...';
  }
  return (
    <div>
      <ContactBody content={content} isBlock={true} />

      <ContactChildTable content={content} idForSelector={idForSelector} />
      <ContactInfoUpdate content={content} />
      <ContactFloatWhatsApp content={content} />
    </div>
  );
};

export default withBlockExtensions(MainContactBlockView);
