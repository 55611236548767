export const hasOverride = (schema, user) => {
  let type = schema.widgetOptions?.klass?.split('__');
  if (type.length < 3) return schema;
  const type_comparation = type[0] === 'NO' ? 0 : 1;

  const type_permission = type[1];
  if (user?.roles?.filter((r) => r.toUpperCase() === type_permission).length !== type_comparation) return schema;

  const type_override = type[2];
  return fieldsToCheck[type_override] ? fieldsToCheck[type_override](schema) : schema;
};

export const fieldsToCheck = {
  TUPLE_TO_SIMPLE: (schema) => {
    return {
      behavior: schema.behavior,
      description: schema.description,
      title: schema.title,
      factory: 'Relation Choice',
      type: 'string',
      vocabulary: schema?.widgetOptions?.vocabulary,
    };
  },
};
