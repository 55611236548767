/**
 * RatingWidget component.
 * @module components/RatingWidget/RatingWidget
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Grid, Icon } from 'semantic-ui-react';
import { defineMessages, useIntl, injectIntl } from 'react-intl';

import _ from 'lodash';
import { compose } from 'redux';
import TimePicker from 'rc-time-picker';
import Moment from 'moment';

import './style.css';

/**
 * RatingWidget component class.
 * @function RatingWidget
 * @returns {string} Markup of the component.
 */
const OpeningHoursWidget = ({ id, title, required, description, error, value, onChange, onEdit, onDelete }) => {
  const messages = defineMessages({
    sunday: {
      id: 'sunday',
      defaultMessage: 'Domingo',
    },
    monday: {
      id: 'monday',
      defaultMessage: 'Segunda',
    },
    tuesday: {
      id: 'tuesday',
      defaultMessage: 'Terça',
    },
    wednesday: {
      id: 'wednesday',
      defaultMessage: 'Quarta',
    },
    thursday: {
      id: 'thursday',
      defaultMessage: 'Quinta',
    },
    friday: {
      id: 'friday',
      defaultMessage: 'Sexta',
    },
    saturday: {
      id: 'saturday',
      defaultMessage: 'Sabado',
    },
  });

  const intl = useIntl();

  const defaultHours = {
    sunday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
    monday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
    tuesday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
    wednesday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
    thursday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
    friday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
    saturday: {
      open: false,
      plusHour: false,
      hours: { one: { open: '', close: '' }, two: { open: '', close: '' } },
    },
  };

  const [hours, setHours] = useState(defaultHours);

  const stringToMoment = (val) => {
    if (typeof val === 'string' && val !== '') return Moment(val);
    return val;
  };

  useEffect(() => {
    if (JSON.stringify(hours) !== JSON.stringify(defaultHours)) onChange(id, JSON.stringify(hours));
  }, [hours]);

  useEffect(() => {
    if (value && value !== 'null' && JSON.stringify(hours) === JSON.stringify(defaultHours)) {
      setHours(JSON.parse(value));
    }
  }, []);
  return (
    <Form.Field
      inline
      required={required}
      error={error.length > 0}
      className={description ? 'help' : ''}
      style={{
        paddingTop: '10px',
      }}
    >
      <Grid>
        <Grid.Row stretched>
          <Grid.Column width="4">
            <div
              className="wrapper"
              style={{
                borderBottom: 'none',
              }}
            >
              <label htmlFor={`field-${id}`}>
                {onEdit && <i aria-hidden="true" className="grey bars icon drag handle" />}
                {title}
              </label>
            </div>
          </Grid.Column>
          <Grid.Column width="8">
            {_.map(hours, (v, key) => {
              return (
                <React.Fragment key={key}>
                  <div
                    className="row"
                    style={{
                      borderBottom: '1px solid #EDF1F2',
                      marginBottom: '10px',
                    }}
                  >
                    <Grid.Column
                      style={{
                        width: '25%',
                        height: v.open && v?.plusHour ? '120px' : '60px',
                      }}
                    >
                      <div className="wrapper">
                        <Checkbox
                          slider
                          label={intl.formatMessage(messages[key])}
                          onChange={(e, data) => {
                            let newHours = { ...hours };
                            newHours[key].open = data.checked;
                            setHours(newHours);
                          }}
                          checked={v.open}
                        />
                      </div>
                    </Grid.Column>
                    {v.open && (
                      <Grid
                        columns={3}
                        style={{
                          width: '75%',
                          paddingLeft: '50px',
                          paddingRight: '-25px',
                        }}
                      >
                        <Grid.Row
                          stretched
                          style={{
                            marginBottom: '-35px',
                          }}
                        >
                          <Grid.Column width="5">
                            <label
                              htmlFor={`${key}-de`}
                              style={{
                                paddingTop: '10px',
                                marginBottom: '-20px',
                              }}
                            >
                              Inicia à(s):
                              <TimePicker
                                allowEmpty={false}
                                showSecond={false}
                                id={`${key}-de`}
                                focusOnOpen
                                value={stringToMoment(v.hours.one.open)}
                                onChange={(e) => {
                                  let newHours = { ...hours };
                                  newHours[key].hours.one.open = e;
                                  setHours(newHours);
                                }}
                              />
                            </label>
                          </Grid.Column>

                          <Grid.Column width="5">
                            <label
                              htmlFor={`${key}-ate`}
                              style={{
                                paddingTop: '10px',
                                marginBottom: '-20px',
                              }}
                            >
                              Termina à(s):
                              <TimePicker
                                allowEmpty={false}
                                showSecond={false}
                                id={`${key}-ate`}
                                style={{ width: 100 }}
                                value={stringToMoment(v.hours.one.close)}
                                onChange={(e) => {
                                  let newHours = { ...hours };
                                  newHours[key].hours.one.close = e;
                                  setHours(newHours);
                                }}
                              />
                            </label>
                          </Grid.Column>
                          <Grid.Column width="2">
                            <Button.Group style={{ paddingLeft: '15px' }}>
                              <Button
                                basic
                                className="cancel"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  let newHours = { ...hours };
                                  newHours[key].plusHour = true;
                                  setHours(newHours);
                                }}
                                style={{ marginTop: '30px', height: '15px' }}
                              >
                                <Icon name="plus" size="large" />
                              </Button>
                            </Button.Group>
                          </Grid.Column>
                        </Grid.Row>
                        {v?.plusHour && (
                          <Grid.Row stretched>
                            <Grid.Column width="5">
                              <label
                                htmlFor={`${key}-de`}
                                style={{
                                  paddingTop: '10px',
                                  marginBottom: '-20px',
                                }}
                              >
                                Inicia à(s):
                                <TimePicker
                                  allowEmpty={false}
                                  showSecond={false}
                                  id={`${key}-de`}
                                  focusOnOpen
                                  value={stringToMoment(v.hours.two.open)}
                                  onChange={(e) => {
                                    let newHours = { ...hours };
                                    newHours[key].hours.two.open = e;
                                    setHours(newHours);
                                  }}
                                />
                              </label>
                            </Grid.Column>

                            <Grid.Column width="5">
                              <label
                                htmlFor={`${key}-ate`}
                                style={{
                                  paddingTop: '10px',
                                  marginBottom: '-20px',
                                }}
                              >
                                Termina à(s):
                                <TimePicker
                                  allowEmpty={false}
                                  showSecond={false}
                                  id={`${key}-ate`}
                                  style={{ width: 100 }}
                                  value={stringToMoment(v.hours.two.close)}
                                  onChange={(e) => {
                                    let newHours = { ...hours };
                                    newHours[key].hours.two.close = e;
                                    setHours(newHours);
                                  }}
                                />
                              </label>
                            </Grid.Column>
                            <Grid.Column width="2">
                              <Button.Group style={{ paddingLeft: '15px' }}>
                                <Button
                                  basic
                                  className="cancel"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    let newHours = { ...hours };
                                    newHours[key].plusHour = false;
                                    setHours(newHours);
                                  }}
                                  style={{ marginTop: '30px', height: '15px' }}
                                >
                                  <Icon name="close" size="large" />
                                </Button>
                              </Button.Group>
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
          </Grid.Column>
        </Grid.Row>

        {description && (
          <Grid.Row stretched>
            <Grid.Column stretched width="12">
              <p className="help">{description}</p>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form.Field>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OpeningHoursWidget.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
OpeningHoursWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: '',
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default compose(injectIntl)(OpeningHoursWidget);
