/**
 * ContactView view component.
 * @module components/View/ContactView
 */

import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

import ContactChildTable from '@package/components/View/Contact/ContactChildTable';
import ContactInfoUpdate from '@package/components/View/Contact/ContactInfoUpdate';
import ContactBody from '@package/components/View/Contact/ContactBody';
import ContactFloatWhatsApp from './Contact/ContactFloatWhatsApp';

/**
 * ContactView view component class.
 * @function ContactView
 * @params {object} content Content object.
 * @returns {JSX.Element} Markup of the component.
 */
const ContactView = (props) => {
  const { content } = props;

  return (
    <div id="page-document" className="ui container viewwrapper event-view">
      <ContactBody content={content} />
      <ContactChildTable content={content} />
      <ContactInfoUpdate content={content} />
      <ContactFloatWhatsApp content={content} />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ContactView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    short_title: PropTypes.string,
    email: PropTypes.string,
    manager: PropTypes.string,
    phone_number: PropTypes.string,
    whatsapp_number: PropTypes.string,
    whatsapp_status_message: PropTypes.string,
    whatsapp_chat_message: PropTypes.string,
    opening_hours: PropTypes.string,
    location_map: PropTypes.string,
    site_url: PropTypes.string,
    type_contact: PropTypes.shape({}),
    grouper: PropTypes.shape({}),
    main_contact: PropTypes.shape({}),
    grouper_availables: PropTypes.arrayOf(PropTypes.string),
    contact_campus: PropTypes.arrayOf(PropTypes.string),
    contact_unit: PropTypes.arrayOf(PropTypes.string),
    contact_address: PropTypes.arrayOf(PropTypes.string),
    complement: PropTypes.string,
    contact_neighborhood: PropTypes.arrayOf(PropTypes.string),
    contact_city: PropTypes.arrayOf(PropTypes.string),
    contact_cep: PropTypes.arrayOf(PropTypes.string),
    subjects: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ContactView;
