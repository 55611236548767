import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ButtonBlock: {
    id: 'Button UFT',
    defaultMessage: 'Botão UFT',
  },
  ButtonLinkInternal: {
    id: 'Link Internal',
    defaultMessage: 'Link interno',
  },
  ButtonText: {
    id: 'Text',
    defaultMessage: 'Texto',
  },
  ButtonExternalLink: {
    id: 'External Link',
    defaultMessage: 'URL Externa',
  },
  ButtonType: {
    id: 'Type',
    defaultMessage: 'Tipo',
  },
  openLinkInNewPage: {
    id: 'Open in a new page',
    defaultMessage: 'Abrir em nova janela',
  },
  innerAlign: {
    id: ' Inner Alignment',
    defaultMessage: 'Alinhamento',
  },
});

export const ButtonSchema = (props) => {
  const { intl } = props;

  return {
    title: props.intl.formatMessage(messages.ButtonBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['text', 'href', 'external_link', 'openLinkInNewPage', 'buttonType', 'inneralign'],
      },
    ],
    properties: {
      text: {
        title: props.intl.formatMessage(messages.ButtonText),
      },
      href: {
        title: props.intl.formatMessage(messages.ButtonLinkInternal),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'image_scales', 'effective'],
        // widgetOptions: {
        //   pattern_options: { selectableTypes: ['News Item', 'Document'] },
        // },
        allowExternals: true,
      },
      external_link: {
        title: props.intl.formatMessage(messages.ButtonExternalLink),
        description: 'A URL deve começar com http:// ou https://',
      },
      openLinkInNewPage: {
        title: intl.formatMessage(messages.openLinkInNewPage),
        type: 'boolean',
      },
      buttonType: {
        title: props.intl.formatMessage(messages.ButtonType),
        default: 'primary',
        choices: [
          ['primary', 'Primário'],
          ['secondary', 'Secundário'],
        ],
      },
      inneralign: {
        title: props.intl.formatMessage(messages.innerAlign),
        widget: 'inner_align',
        default: 'left',
      },
    },
    required: ['text'],
  };
};
