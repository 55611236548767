import React from 'react';
import '../../styles/style.less';

const Icon = (props) => {
  const { name, size } = props;

  const formatedStyle = getFontBrands(name) ? 'fa-brands' : 'fa-solid';

  const formatedName = name
    ?.split(' ')
    .map((separetedName) => `fa-${separetedName}`)
    .join(' ');

  return <i className={`af-icon ${formatedStyle} ${formatedName} ${size}`}></i>;
};

function getFontBrands(name) {
  const brands = [
    { name: 'facebook' },
    { name: 'facebook-f' },
    { name: 'facebook-messenger' },
    { name: 'square-facebook' },
    { name: 'google' },
    { name: 'google-drive' },
    { name: 'google-play' },
    { name: 'google-plus' },
    { name: 'google-plus-g' },
    { name: 'square-google-plus' },
    { name: 'google-wallet' },
    { name: 'google-scholar' },
    { name: 'google-pay' },
    { name: 'instagram' },
    { name: 'square-instagram' },
    { name: 'linkedin' },
    { name: 'linkedin-in' },
    { name: 'tiktok' },
    { name: 'twitch' },
    { name: 'twitter' },
    { name: 'square-twitter' },
    { name: 'x-twitter' },
    { name: 'square-x-twitter' },
    { name: 'youtube' },
    { name: 'square-youtube' },
  ];
  return brands.find((element) => {
    return element.name === name;
  });
}

export default Icon;
