import { getEndOfDay, getStartOfDay } from '../../../../../../util/generalUtil';

export const defaultPortalType = [
  {
    key: 'News Item',
    text: 'Notícia',
    value: 'News Item',
  },
  {
    key: 'campus',
    text: 'Câmpus',
    value: 'campus',
  },
  {
    key: 'course',
    text: 'Curso',
    value: 'course',
  },
  {
    key: 'department',
    text: 'Departamento',
    value: 'department',
  },
  {
    key: 'service',
    text: 'Serviço',
    value: 'service',
  },
  {
    key: 'contact',
    text: 'Contato',
    value: 'contact',
  },
];

export const defaultPeriod = [
  {
    key: 'yesterday',
    text: 'Ontem',
    value: [getStartOfDay(new Date(new Date().setDate(new Date().getDate() - 1))), getEndOfDay(new Date())],
  },
  {
    key: 'last_week',
    text: 'Última Semana',
    value: [getStartOfDay(new Date(new Date().setDate(new Date().getDate() - 7))), getEndOfDay(new Date())],
  },
  {
    key: 'last_month',
    text: 'Último Mês',
    value: [getStartOfDay(new Date(new Date().setMonth(new Date().getMonth() - 1))), getEndOfDay(new Date())],
  },
  {
    key: 'ever',
    text: 'Desde sempre',
    value: null,
  },
];

export const dateFilters = ['effective'];

export const canShowFilter = (filter, valueToCheck) => {
  switch (filter) {
    case 'effective':
      const value = Array.isArray(valueToCheck) ? valueToCheck[0] : valueToCheck;
      return value === 'News Item';
    default:
      return true;
  }
};
