import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import moment from 'moment';
import { Container, Button, PrimaryIcon } from './style';
import './customTheme.less';

const DatePicker = ({
  id,
  name,
  value,
  onChange,
  required,
  disabled,
  minDate,
  maxDate,
  error,
  clearError,
  placeholder,
  containerStyle,
  mode,
  width,
  style,
}) => (
  <>
    <Container
      style={{
        backgroundColor: disabled ? '#dedede' : '',
        ...containerStyle,
        width,
      }}
      error={!!error}
    >
      <Flatpickr
        options={{
          wrap: true,
          minDate,
          maxDate,
          locale: Portuguese,
          dateFormat: 'd/m/Y',
          parseDate: (datestr) => moment(datestr, 'YYYY-MM-DD', true).toDate(),
          mode,
        }}
        onChange={onChange}
        value={value}
      >
        <input
          data-input=""
          id={id}
          name={name}
          style={{
            ...style,
          }}
          placeholder={placeholder || 'dd/mm/aaaa'}
          required={required}
          disabled={disabled}
          onFocus={clearError}
        />
        <Button type="button" data-toggle disabled={disabled}>
          <PrimaryIcon className="fas fa-calendar-alt" aria-hidden="true" />
        </Button>
      </Flatpickr>
    </Container>
  </>
);

DatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  error: PropTypes.string,
  clearError: PropTypes.func,
  placeholder: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mode: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

DatePicker.defaultProps = {
  id: 'datePicker',
  name: null,
  required: false,
  disabled: false,
  error: null,
  clearError: null,
  placeholder: null,
  value: null,
  minDate: null,
  maxDate: null,
  containerStyle: null,
  mode: 'single',
  width: null,
  style: null,
};

export default DatePicker;
