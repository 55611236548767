import { defineMessages } from 'react-intl';

const messages = defineMessages({
  mainContactBlock: {
    id: 'Contato',
    defaultMessage: 'Contato',
  },
  href: {
    id: 'Ref',
    defaultMessage: 'Caminho do Contato',
  },
});

export const mainContactSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.mainContactBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['href'],
      },
    ],
    properties: {
      href: {
        title: props.intl.formatMessage(messages.href),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['id'],
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['contact'],
          },
        },
        allowExternals: false,
      },
    },
    required: ['href'],
  };
};
