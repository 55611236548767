import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { withBlockExtensions } from '@plone/volto/helpers';
import { Message } from 'semantic-ui-react';
import './main.less';
import { Icon } from '@plone/volto/components';
import teaserTemplate from './icons/teaser-template.svg';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage: 'Por favor, escolha um conteúdo existente como fonte para este elemento',
  },
});

const AlfrescoGalleryView = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();
  const settings = {
    showIndex: false,
    showBullets: false,
    infinite: true,
    showThumbnails: true,
    showFullscreenButton: true,
    showGalleryFullscreenButton: true,
    showPlayButton: true,
    showGalleryPlayButton: true,
    showNav: true,
    isRTL: false,
    slideDuration: 450,
    slideInterval: 2000,
    slideOnThumbnailOver: false,
    thumbnailPosition: 'bottom',
    showVideo: false,
    useWindowKeyDown: true,
  };

  return (
    <>
      {(data.slides?.length === 0 || !data.slides) && isEditMode && (
        <Message>
          <div className="teaser-item default">
            <Icon name={teaserTemplate} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
          </div>
        </Message>
      )}
      {data.slides?.length > 0 && (
        <div className="images-container">
          <ImageGallery items={data.slides} {...settings} />
        </div>
      )}
    </>
  );
};

export default withBlockExtensions(AlfrescoGalleryView);
