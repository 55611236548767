import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import './Style.css';

const ShareButtons = ({ ...props }) => {
  const url = typeof window !== 'undefined' ? window.location.href : '/#';
  return (
    <div className="share-comp" key={props.shareKey}>
      <span>Compartilhe: </span>
      <span className="share-button share-first">
        <FacebookShareButton url={url}>
          <FacebookIcon size={16} />
        </FacebookShareButton>
      </span>
      <span className="share-button">
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={16} />
        </LinkedinShareButton>
      </span>
      <span className="share-button">
        <TwitterShareButton url={url}>
          <TwitterIcon size={16} />
        </TwitterShareButton>
      </span>
      <span className="share-button">
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={16} />
        </WhatsappShareButton>
      </span>
    </div>
  );
};

export default ShareButtons;
