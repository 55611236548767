import React, { useEffect } from 'react';
import DatePicker from '@package/components/GovBr/DatePicker';
import { FormField, FormGroup, FormSelect, Radio } from 'semantic-ui-react';
import { map, toPairs } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getQuerystring, getVocabulary } from '@plone/volto/actions';
import { defaultPeriod, defaultPortalType } from './constant';

const SerachDateRangePicker = ({ value, setValue }) => {
  return (
    <DatePicker
      id="dateRange"
      name="dateRange"
      containerStyle={{
        height: '40px',
      }}
      style={{
        height: '40px',
      }}
      value={value}
      onChange={(e) => {
        if (e.length === 2) {
          setValue([e[0].toISOString().substring(0, 10), e[1].toISOString().substring(0, 10)]);
        }
      }}
      mode="range"
      placeholder="Escolha um período"
    />
  );
};
const SearchDateRangeRadio = ({ value, setValue, horizontal = true }) => {
  const renderRadioButtons = (isHorizontal) => (
    <>
      <label>Data de publicação</label>
      {map(defaultPeriod, (type) => (
        <FormField
          control={Radio}
          key={type.key}
          label={type.text}
          data_value={type.value}
          checked={value?.[0] === type.value?.[0]}
          onChange={(e, data) => setValue(data.data_value)}
        />
      ))}
    </>
  );

  return (
    <>
      {horizontal ? <FormGroup grouped>{renderRadioButtons(true)}</FormGroup> : <div>{renderRadioButtons(false)}</div>}
    </>
  );
};
const SearchPortalTypeRadio = ({ value, setValue, horizontal = true }) => {
  useEffect(() => {
    if (Array.isArray(value)) setValue(value[0]);
  }, [value]);
  const renderRadioButtons = (isHorizontal) => (
    <>
      <label>Tipo de conteúdo</label>
      {map(defaultPortalType, (type) => (
        <FormField
          control={Radio}
          key={type.key}
          label={type.text}
          value={type.value}
          checked={value === type.value}
          onChange={(e, data) => setValue(data.value)}
        />
      ))}
    </>
  );

  return (
    <>
      {horizontal ? <FormGroup grouped>{renderRadioButtons(true)}</FormGroup> : <div>{renderRadioButtons(false)}</div>}
    </>
  );
};

const SearchPortalType = ({ value, setValue }) => {
  const { indexes } = useSelector((state) => state.querystring);
  const dispatch = useDispatch();
  const portalType =
    map(toPairs(indexes?.portal_type?.values), (pair) => ({
      key: pair[0],
      text: pair[1].title,
      value: pair[0],
    })) ?? defaultPortalType;

  React.useEffect(() => {
    dispatch(getQuerystring());
  }, []);

  return (
    <FormSelect
      fluid
      label="Tipo de conteudo"
      multiple
      options={portalType}
      value={value}
      onChange={(e, { value }) => setValue(value)}
    />
  );
};

const convertStateToOption = (items) =>
  map(items, (item) => {
    return {
      key: item.title ?? item.token,
      text: item.label ?? item.title,
      value: item.value ?? item.token,
    };
  });
const SearchAdvancedTags = ({ value, setValue }) => {
  const vocabulary = 'plone.app.vocabularies.Keywords';
  const noResultsMessage = 'Nenhuma tag encontrada';
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const dispatch = useDispatch();
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback(
    (e, data) => {
      const filteredOptions = data?.options?.filter((option) => data?.value?.includes(option.value)) ?? [];

      setLoading(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        dispatch(getVocabulary({ vocabNameOrURL: vocabulary, query: data.searchQuery }))
          .then((e) => setOptions([...filteredOptions, ...convertStateToOption(e.items)]))
          .then(() => setLoading(false));
      }, 300);
    },
    [value],
  );

  useEffect(() => {
    const initalData = value?.map((v) => ({ key: v, text: v, value: v })) ?? [];
    dispatch(getVocabulary({ vocabNameOrURL: vocabulary })).then((e) =>
      setOptions([...initalData, ...convertStateToOption(e.items)]),
    );
  }, [dispatch]);
  return (
    <FormSelect
      fluid
      label="Tags"
      multiple
      options={options}
      value={value}
      search
      selection
      loading={loading}
      onSearchChange={handleSearchChange}
      onChange={(e, { value }) => setValue(value)}
      noResultsMessage={noResultsMessage}
    />
  );
};

export { SerachDateRangePicker, SearchDateRangeRadio, SearchPortalType, SearchPortalTypeRadio, SearchAdvancedTags };
