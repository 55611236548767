import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { hasApiExpander } from '@plone/volto/helpers/Utils/Utils';
import { getBaseUrl } from '@plone/volto/helpers/Url/Url';
import { getStructure } from '@package/actions';

export default function useHeader({ pathname }) {
  const [showActions, setShowActions] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const dropdownRef = React.useRef(null);

  const dispatch = useDispatch();
  const structure = useSelector((state) => state.structure.structure, shallowEqual);

  const firstLevel = structure ? structure[0] : '';
  const secondLevel = structure ? structure[2] : '';

  let firstText = '';
  let secondText = '';
  let thirdText = '';

  if (firstLevel?.path === undefined && secondLevel?.path === undefined) {
    firstText = '';
    secondText = 'Universidade Federal do Tocantins';
    thirdText = '';
  } else {
    if (firstLevel?.path !== undefined && secondLevel?.path === undefined) {
      firstText = 'Universidade Federal do Tocantins';
      secondText = firstLevel.label;
      thirdText = '';
    } else {
      firstText = 'Universidade Federal do Tocantins';
      secondText = secondLevel.label;
      thirdText = firstLevel.label;
    }
  }
  const toogleActions = () => setShowActions((prevShowActions) => !prevShowActions);
  const toogleSearch = () => setShowSearch((prevShowSearch) => !prevShowSearch);

  React.useEffect(() => {
    if (!hasApiExpander('Structure', getBaseUrl(pathname))) {
      dispatch(getStructure(getBaseUrl(pathname)));
    }
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowActions(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [pathname, dispatch]);

  return {
    firstText,
    secondText,
    thirdText,
    showActions,
    toogleActions,
    showSearch,
    toogleSearch,
    dropdownRef,
  };
}
