import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import './card.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fab, fas, far);

const CardBlockView = (props, className) => {
  const { data } = props || {};

  const link = data.href !== undefined && data.href.length === 1 ? data.href[0]?.['@id'] : data.external_link;

  const formatedStyle = getFontBrands(data.icon_selector) ? 'fab' : 'fas';

  return (
    <div className="card-uft">
      <a href={link} target={data.openLinkInNewPage ? '_blank' : '_self'} rel="noreferrer">
        <div className="card-content">
          {data.icon_selector ? (
            <span className="card-icon">
              {/*<i className={icone} aria-hidden="true"></i>*/}
              <FontAwesomeIcon icon={[formatedStyle, data.icon_selector]} className="icon-card" />
            </span>
          ) : (
            ''
          )}
          <div className="title-card">
            <h5>{data.text}</h5>
          </div>
        </div>
      </a>
    </div>
  );
};

function getFontBrands(name) {
  const brands = [
    { name: 'facebook' },
    { name: 'facebook-f' },
    { name: 'facebook-messenger' },
    { name: 'square-facebook' },
    { name: 'google' },
    { name: 'google-drive' },
    { name: 'google-play' },
    { name: 'google-plus' },
    { name: 'google-plus-g' },
    { name: 'square-google-plus' },
    { name: 'google-wallet' },
    { name: 'google-scholar' },
    { name: 'google-pay' },
    { name: 'instagram' },
    { name: 'square-instagram' },
    { name: 'linkedin' },
    { name: 'linkedin-in' },
    { name: 'tiktok' },
    { name: 'twitch' },
    { name: 'twitter' },
    { name: 'square-twitter' },
    { name: 'x-twitter' },
    { name: 'square-x-twitter' },
    { name: 'youtube' },
    { name: 'square-youtube' },
  ];
  return brands.find((element) => {
    return element.name === name;
  });
}

export default withBlockExtensions(CardBlockView);
