import { TourProvider } from '@reactour/tour';
import React from 'react';
import steps from './steps';
import { SecondaryButton } from '@package/components/GovBr/button';
import Tour from './Tour';

function MenuTourProvider({ children }) {
  const handleCallback = (setIsOpen) => {
    setIsOpen(false);
    localStorage.setItem('JoyrideMenu', 'true'); // Marca como completado
  };

  return (
    <TourProvider
      steps={steps}
      disableInteraction={true}
      showBadge={false}
      nextButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep, steps }) => {
        return (
          <SecondaryButton
            onClick={() => {
              handleCallback(setIsOpen);
            }}
            style={{
              height: '25px',
              width: '80px',
              backgroundColor: '#fff',
              border: 0,
              borderRadius: 4,
              color: '#000',
              cursor: 'pointer',
              fontSize: 16,
              lineHeight: 1,
              padding: 8,
              WebkitAppearance: 'none',
            }}
          >
            Finalizar
          </SecondaryButton>
        );
      }}
    >
      <Tour />
      {children}
    </TourProvider>
  );
}

export default MenuTourProvider;
