import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { clearSharedLink, getNode, requestTicket } from '@package/actions/alfresco/alfresco';
import { useDispatch, useSelector } from 'react-redux';
import AlfrescoAuthUtil from '@package/util/AlfrescoAuthUtil';
import { Link } from 'react-router-dom';
import { Dimmer, Loader, Select } from 'semantic-ui-react';
import './style.less';
import PropTypes from 'prop-types';
import alfrescoSVG from '@package/icons/alfresco.svg';
import { Icon } from '@plone/volto/components';

/**
 * Component for navigation in alfresco folders
 * @param onChangeDocument
 * @param mimeTypes
 * @param defaultPath
 * @param title
 * @param placeholder
 * @returns {JSX.Element}
 * @constructor
 */
const ExploreListDocs = ({ onChangeDocument, mimeTypes, defaultPath, title, placeholder }) => {
  const alfresco = useSelector((state) => state && state.alfresco);
  const dispatch = useDispatch();
  const [history, setHistory] = useState([{ path: defaultPath, name: 'Home' }]);
  const [folderId, setFolderId] = useState(null);
  const [folderName, setFolderName] = useState(null);
  const [orderSidebar, setOrder] = useState(null);

  const mountBreadcrumb = (nodeId, name) => {
    let breakCondition = false;
    const newHistory = history.reduce((acc, curr) => {
      if (breakCondition) {
        return acc;
      }
      if (curr.path === nodeId) breakCondition = true;
      return [...acc, curr];
    }, []);
    if (!breakCondition) {
      setHistory([...newHistory, { path: nodeId, name }]);
    } else {
      setHistory(newHistory);
    }
  };
  const retrieveNode = (nodeId, name) => {
    dispatch(
      getNode({
        path: AlfrescoAuthUtil.getNodePath(nodeId),
      }),
    );
    mountBreadcrumb(nodeId, name);
  };

  const checkMimeType = (entry) => {
    if (mimeTypes.length && entry.isFile) {
      return mimeTypes.includes(entry.content.mimeType);
    }
    return true;
  };

  const onChooseFolder = async (entry, e_order) => {
    if (entry.isFolder) {
      if (folderName !== entry.name) setFolderName(entry.name);
      if (folderId !== entry.id) setFolderId(entry.id);
      onChangeDocument({ folderName: entry.name, nodeId: entry.id, order: e_order });
      dispatch(clearSharedLink());
    }
  };

  const onClickPath = (entry) => {
    if (entry.isFolder) {
      retrieveNode(entry.id, entry.name);
    }
  };
  const retriveHome = useCallback(() => {
    dispatch(
      getNode({
        path: AlfrescoAuthUtil.getNodePath(defaultPath),
      }),
    );
    setHistory([{ path: defaultPath, name: 'Home' }]);
  }, [defaultPath, dispatch]);

  useEffect(() => {
    if (alfresco?.token) {
      retriveHome();
    }
  }, [alfresco?.token, retriveHome]);

  const sortOptions = [
    { key: 'n_c', value: 'n_c', text: 'Nome Crescente' },
    { key: 'n_d', value: 'n_d', text: 'Nome Decrescente' },
    { key: 'd_c_c', value: 'd_c_c', text: 'Data Criação Crescente' },
    { key: 'd_c_d', value: 'd_c_d', text: 'Data Criação Decrescente' },
    { key: 'd_m_c', value: 'd_m_c', text: 'Data Modificação Crescente' },
    { key: 'd_m_d', value: 'd_m_d', text: 'Data Modificação Decrescente' },
  ];

  useLayoutEffect(() => {
    dispatch(requestTicket());
  }, [dispatch]);
  return (
    <div>
      <Dimmer active={alfresco.alfresco.loading}>
        <Loader>Carregando</Loader>
      </Dimmer>
      <div>
        <Select
          label="Ordenar"
          placeholder="Selecione para ordenar"
          options={sortOptions}
          onChange={(props, { value }) => {
            setOrder(value);
            if (folderId) {
              onChangeDocument({ folderName: folderName, nodeId: folderId, order: value });
              dispatch(clearSharedLink());
            }
          }}
        />
      </div>
      <div className="ui raised segments">
        <header className="header pulled">
          <div className="vertical divider"></div>
          <h2>{title}</h2>
        </header>
        <div className="ui secondary vertical segment breadcrumbs">
          {history.map((item) => (
            <Link
              key={`history-${item.name}`}
              to={'#/'}
              onClick={() => retrieveNode(item.path, item.name)}
              className="section"
            >
              {item.path !== defaultPath && <span className="divider"> / </span>}
              {item.path === defaultPath ? <i className="fas fa-home"></i> : item.name}
            </Link>
          ))}
        </div>
        <ul className="ui segment object-listing">
          {alfresco?.list?.entries.map(
            ({ entry }) =>
              checkMimeType(entry) && (
                <li key={`li-${entry.id}`} role="presentation" aria-label="Procurar Images">
                  <span title={entry.name} role="presentation" onClick={() => onClickPath(entry)}>
                    <i className={entry.isFolder ? 'fas fa-folder' : 'fas fa-file'} />
                    {entry.name}
                  </span>

                  <span title={entry.name} role="presentation" onClick={() => onChooseFolder(entry, orderSidebar)}>
                    <Icon name={alfrescoSVG} />
                    Usar pasta
                  </span>
                </li>
              ),
          )}
        </ul>
      </div>
    </div>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
ExploreListDocs.propTypes = {
  onChangeDocument: PropTypes.func.isRequired,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  defaultPath: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};
/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ExploreListDocs.defaultProps = {
  mimeTypes: [],
  defaultPath: '-my-',
  title: 'Escolha um Diretório',
  placeholder: 'Pesquisar por nome da imagem',
};

export default ExploreListDocs;
