import React, { useRef } from 'react';
import { FormField, FormGroup } from 'semantic-ui-react';

const CodeInput = ({ value, onChange }) => {
  // Cria um estado para cada grupo de caracteres
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const values = value ? value.split('-') : ['', '', '', '', ''];

  const handleInputChange = (index, newValue) => {
    const cleanedValue = newValue.toUpperCase().replace(/[^A-Z0-9]/gi, '');
    if (cleanedValue.length > 5) return;
    const newValues = [...values];
    newValues[index] = cleanedValue;

    onChange(newValues.join('-'));
    if (cleanedValue.length === 5 && index < 4 && inputRefs[index + 1].current) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData('text');
    const pasteParts = pastedData.split('-').map((part) =>
      part
        .substring(0, 5)
        .toUpperCase()
        .replace(/[^A-Z0-9]/gi, ''),
    );

    // Se há exatamente 5 partes, atribui cada uma ao campo correspondente
    pasteParts.forEach((part, index) => {
      if (inputRefs[index]?.current) {
        inputRefs[index].current.value = part;
      }
    });
    onChange(pasteParts.join('-'));
    event.preventDefault(); // Impede o comportamento padrão de colar
  };

  return (
    <div>
      <sapn style={{ fontWeight: 700 }}>Insira o Código de Autenticação</sapn>
      <FormGroup widths="equal" style={{ gap: '2', marginTop: '2px' }}>
        {[...Array(5)].map((_, index) => (
          <React.Fragment key={index}>
            <FormField>
              <input
                key={index}
                className="field"
                ref={inputRefs[index]}
                value={values[index] || ''}
                onChange={(e) => handleInputChange(index, e.target.value)}
                maxLength={5}
                onPaste={handlePaste}
              />
            </FormField>
            {index < 4 && (
              <FormField width={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                -
              </FormField>
            )}
          </React.Fragment>
        ))}
      </FormGroup>
    </div>
  );
};

export default CodeInput;
