/**
 * RatingWidget component.
 * @module components/RatingWidget/RatingWidget
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Icon, Input, Message } from 'semantic-ui-react';

import { FormattedMessage, injectIntl } from 'react-intl';
import mapsBlockSVG from '@plone/volto/components/manage/Blocks/Maps/block-maps.svg';

import { compose } from 'redux';

/**
 * RatingWidget component class.
 * @function RatingWidget
 * @returns {string} Markup of the component.
 */
const MapWidget = ({ id, title, required, description, error, value, onChange, onEdit, onDelete }) => {
  const [url, setUrl] = useState(value);
  const [errorFrame, setErrorFrame] = useState(false);

  const placeholder = 'Informe o código Embed do mapa';

  /**
   * Change url handler
   * @method onChangeUrl
   * @param {Object} target Target object
   * @returns {undefined}
   */
  const onChangeUrl = ({ target }) => {
    setUrl(target.value);
    onSubmitUrl(target.value);
  };

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @returns {undefined}
   * @param urlValue
   */
  const onSubmitUrl = (urlValue) => {
    if (urlValue === '') return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(urlValue, 'text/html');
    const iframe = doc.getElementsByTagName('iframe');
    if (iframe.length === 0) {
      setErrorFrame(true);
      return '';
    }
    setErrorFrame(false);
    onChange(id, urlValue);
  };

  const clearSubmitUrl = () => {
    setUrl('');
    onChange(id, '');
  };

  /**
   * Keydown handler on Variant Menu Form
   * This is required since the ENTER key is already mapped to a onKeyDown
   * event and needs to be overriden with a child onKeyDown.
   * @method onKeyDownVariantMenuForm
   * @param {Object} e Event object
   * @returns {undefined}
   */
  const onKeyDownVariantMenuForm = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onSubmitUrl();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      // TODO: Do something on ESC key
    }
  };

  return (
    <Form.Field inline required={required} error={error.length > 0} className={description ? 'help' : ''}>
      <Grid>
        <Grid.Row stretched>
          <Grid.Column width="4">
            <div className="wrapper">
              <label htmlFor={`field-${id}`}>
                {onEdit && <i aria-hidden="true" className="grey bars icon drag handle" />}
                {title}
              </label>
            </div>
          </Grid.Column>
          <Grid.Column width="8">
            <Message>
              <center>
                <img src={mapsBlockSVG} alt="" />
                <div
                  className="toolbar-inner"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.975)',
                  }}
                >
                  <Input
                    onKeyDown={onKeyDownVariantMenuForm}
                    onChange={onChangeUrl}
                    placeholder={placeholder}
                    value={url}
                    style={{ width: '90%' }}
                    // Prevents propagation to the Dropzone and the opening
                    // of the upload browser dialog
                    onClick={(e) => e.stopPropagation()}
                  />
                  {url && (
                    <Button.Group>
                      <Button
                        basic
                        primary
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          clearSubmitUrl();
                        }}
                      >
                        <Icon name="close" size="large" />
                      </Button>
                    </Button.Group>
                  )}
                </div>
                <div className="message-text">
                  <FormattedMessage
                    id="Please enter the Embed Code provided by Google Maps -> Share -> Embed map. It should contain the <iframe> code on it."
                    defaultMessage="Digite o código Embed fornecido pelo google maps -> compartilhar -> mapa Embed. Deve conter o <iframe> código.</iframe>."
                  />
                  {errorFrame && (
                    <div style={{ color: 'red' }}>
                      <FormattedMessage
                        id="Embed code error, please follow the instructions and try again."
                        defaultMessage="Erro de código Embed, siga as instruções e tente novamente."
                      />
                    </div>
                  )}
                </div>
              </center>
            </Message>
          </Grid.Column>
        </Grid.Row>
        {description && (
          <Grid.Row stretched>
            <Grid.Column stretched width="12">
              <p className="help">{description}</p>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form.Field>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MapWidget.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
MapWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: '',
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default compose(injectIntl)(MapWidget);
