import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

/**
 * Represents a menu item.
 * @param {object} props - The props for the menu item.
 * @param {string} props.label - The label for the menu item.
 * @param {string} props.path - The path for the menu item.
 * @param {string} props.uid - The unique ID for the menu item.
 * @param {string} props.activeLink - The active link for the menu item.
 * @param {string} props.icon - The icon for the menu item.
 * @param {boolean} props.child - Indicates if the menu item has child items.
 * @param {function} props.onClickNavigation - The function to handle click on the menu item.
 * @param {function} props.toggleMenu - The function to toggle the menu.
 * @returns {JSX.Element} - The menu item component.
 */
const MenuItem = ({ label, path, activeLink, icon, child, onClickNavigation, toggleMenu }) => {
  const history = useHistory();
  return (
    <>
      <a
        href={'/#'}
        className={`menu-item divider ${path === activeLink ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickNavigation && onClickNavigation(path);
          if (!child) {
            history.replace(path);
            toggleMenu();
          }
        }}
      >
        {icon && (
          <span className="icon">
            <i className={`${icon}`} aria-hidden="true"></i>
          </span>
        )}
        <span className="content">
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.replace(path);
              toggleMenu();
            }}
          >
            {label}
          </span>
        </span>
        {child && (
          <span role="menuitem" className="support">
            <i className="fas fa-chevron-right" aria-hidden="true"></i>
          </span>
        )}
      </a>
    </>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  activeLink: PropTypes.string,
  icon: PropTypes.string,
  child: PropTypes.bool,
  onClickNavigation: PropTypes.func,
  toggleMenu: PropTypes.func,
};

export default MenuItem;
