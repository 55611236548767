import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';
import defaultNews from '@package/images/default-news.jpg';
import './newsblock.less';
import Moment from 'moment';
import 'moment/locale/pt-br';

const NewsImage = ({ content }) => {
  const { image, image_scales, title } = content;
  let download = defaultNews;
  if (image || image_scales) {
    const base_image = image ? image : image_scales.image[0];
    if (image_scales) {
      const scale_name = 'teaser';
      const scale = base_image.scales[scale_name];
      download = scale.download;
    } else {
      download = base_image;
    }
    if (download.startsWith('@@')) {
      download = `${content['@id']}/${download}`;
    }
  }
  return <Image src={download} alt={title} className="news-img" />;
};

const NewsBlockView = (props) => {
  const { data } = props;
  const news = data.href !== undefined && data.href.length === 1 ? data.href[0] : null;
  const dt = news ? news.effective : new Date();
  return (
    <>
      {news && (
        <div className="news-container">
          <div className="ui fluid image pickgradient">
            <NewsImage content={news} />
            <div className="news-title">
              <a href={news['@id']} className="news-link">
                {news.title}
              </a>
            </div>
            <div className="news-subtitle">{Moment(dt).format('dddd, DD [de] MMMM [de] YYYY')}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default withBlockExtensions(NewsBlockView);
