import _ from 'lodash';
import { Accordion } from 'semantic-ui-react';
import React from 'react';
import DayTabs from './DayTabs';

const PeriodAccordion = ({ periods }) => {
  const panels = _.reduce(
    Object.keys(periods),
    (acc, period) => {
      const periodTitle = `${period === '0' ? 'Disciplinas Optativas' : period}${period === '0' ? '' : `º período`}`;
      acc.push({
        key: periodTitle,
        title: {
          content: periodTitle,
          icon: 'angle',
        },
        content: {
          content: <DayTabs days={periods[period]} />,
        },
      });
      return acc;
    },
    [],
  );

  return <Accordion panels={panels} className={'styled-event'} exclusive={false} />;
};

export default PeriodAccordion;
