/**
 * Service actions.
 * @module actions/apiUft/apiUft
 */

import { APIUFT_LIST_DISCIPLINES } from '@package/constants/ActionTypes';

export const initialState = {
  services: null,
  service: null,
  subrequests: {},
  disciplines: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request Key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * GovServices reducer.
 * @function service
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function apiUft(state = initialState, action = {}) {
  switch (action.type) {
    case `${APIUFT_LIST_DISCIPLINES}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                disciplines: null,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            [getRequestKey(action.type)]: {
              loading: true,
              loaded: false,
              error: null,
            },
          };
    case `${APIUFT_LIST_DISCIPLINES}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                disciplines: action.result,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            disciplines: action.result,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: null,
            },
          };
    case `${APIUFT_LIST_DISCIPLINES}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                disciplines: null,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            disciplines: null,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: false,
              error: action.error,
            },
          };
    default:
      return state;
  }
}
