/**
 * Menu reducer.
 * @module reducers/menu/index
 */
import { map } from 'lodash';

import { flattenToAppURL } from '@plone/volto/helpers';

import { GET_MENU, GET_FULL_MENU, GET_PAGINED_MENU } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  menu: [],
  fullMenu: [],
  paginedMenu: [],
  root: null,
  loaded: false,
  loading: false,
};

const normatizeMenu = (menu) => {
  return map(menu, (item) => {
    return {
      label: item.title,
      path: flattenToAppURL(item['@id']),
      child: normatizeMenu(item.child),
    };
  });
};
/**
 * Menu reducer.
 * @function menu itens
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function menu(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_MENU}_PENDING`:
    case `${GET_FULL_MENU}_PENDING`:
    case `${GET_PAGINED_MENU}_PENDING`:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };

    case `${GET_MENU}_SUCCESS`:
      return {
        ...state,
        menu: map(action.result.menu, (item) => ({
          label: item.title,
          path: flattenToAppURL(item['@id']),
          child: [],
        })),
        loading: false,
        loaded: true,
        error: null,
      };

    case `${GET_FULL_MENU}_SUCCESS`:
      return {
        ...state,
        fullMenu: normatizeMenu(action.result.menu),
        loading: false,
        loaded: true,
        error: null,
      };
    case `${GET_PAGINED_MENU}_SUCCESS`:
      return {
        ...state,
        paginedMenu: action.result,
        loading: false,
        loaded: true,
        error: null,
      };

    case `${GET_MENU}_FAIL`:
      return {
        ...state,
        menu: null,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case `${GET_FULL_MENU}_FAIL`:
      return {
        ...state,
        fullMenu: null,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case `${GET_PAGINED_MENU}_FAIL`:
      return {
        ...state,
        paginedMenu: null,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}
