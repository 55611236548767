/**
 * Menu actions.
 * @module actions/structure/index
 */

import { GET_STRUCTURE } from '@package/constants/ActionTypes';

/**
 * Request menu from path  function.
 * @function getMenu
 * @returns {Object} Entry menu.
 * @param url
 */
export function getStructure(url) {
  return {
    type: GET_STRUCTURE,
    request: {
      op: 'get',
      path: `${url}/@settings`,
    },
  };
}
