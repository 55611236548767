import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Icon, MaybeWrap, UniversalLink } from '@plone/volto/components';
import { Input, Button, Message } from 'semantic-ui-react';
import cx from 'classnames';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage: 'Please choose an existing content as source for this element',
  },
  moreInfo: {
    id: 'moreInfo',
    defaultMessage: 'More info',
  },
  source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
});

const SliderBody = ({ index, onChangeBlock, block, data, dataBlock, isEditMode, openObjectBrowser }) => {
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image;

  const handleClick = () => {
    openObjectBrowser({
      onSelectItem: (url, document) => {
        dataBlock.slides[index].title = document.Title;
        dataBlock.slides[index].description = document.Description;
        dataBlock.slides[index].preview_image = document.preview_image;
        dataBlock.slides[index].href = [
          {
            '@id': document['@id'],
            Title: document.title,
            Description: document.Description,
            title: document.Title,
            hasPreviewImage: document.hasPreviewImage,
          },
        ];
        onChangeBlock(block, dataBlock);
      },
      mode: 'link',
    });
  };

  return (
    <div
      className={cx('grid-teaser-item top', {
        'empty-slide': !href && isEditMode,
      })}
    >
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <div>
              <img src={imageBlockSVG} alt="" />
            </div>
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            <div className="toolbar-inner">
              <Button.Group>
                <Button onClick={handleClick} icon basic>
                  <Icon name={navTreeSVG} size="24px" />
                </Button>
              </Button.Group>
              <Input
                placeholder={`${intl.formatMessage(messages.source)}...`}
                onClick={handleClick}
                onFocus={(e) => e.target.blur()}
              />
            </div>
          </div>
        </Message>
      )}
      {href && (
        <div className="teaser-item" style={{ width: '100%' }}>
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={href['@id']}
            target={data.openLinkInNewTab ? '_blank' : null}
            tabIndex="-1"
          >
            {(href || image) && (
              <div className="highlight-image-wrapper">
                <div className="carousel-pickgradient"></div>
                <img
                  src={href.image_scales?.image ? href.image_scales?.image[0]?.download : image}
                  alt=""
                  loading="lazy"
                />
              </div>
            )}
            <div className="teaser-item-title">
              <div className="title">
                {data?.head_title && (
                  <span className="supertitle text-up-01 text-weight-medium text-uppercase">{data?.head_title}</span>
                )}
                <h3>{data?.nav_title || data?.title}</h3>
              </div>
              <p className="carousel-description">{data?.description}</p>
            </div>
          </MaybeWrap>
        </div>
      )}
    </div>
  );
};

export default SliderBody;
