/**
 * Ticket reducer.
 * @module reducers/alfresco/ticket
 */

import {
  ALFRESCO_GET_NODE,
  ALFRESCO_GET_TICKET,
  ALFRESCO_GET_CONTENT,
  ALFRESCO_CREATE_SHARED_LINK,
  ALFRESCO_CLEAR_SHARED_LINK,
  ALFRESCO_CREATE_RENDITION,
  ALFRESCO_GET_SHARED_LINK,
  ALFRESCO_SEARCH,
  ALFRESCO_GET_CHILDREN,
  ALFRESCO_GET_NODE_SUBREQUEST,
} from '@package/constants/ActionTypes';
import AlfrescoAuthUtil from '../../util/AlfrescoAuthUtil';

export const initialState = {
  token: null,
  list: null,
  content: null,
  sharedLink: null,
  rendition: null,
  subrequests: {},
  alfresco: {
    loaded: false,
    loading: false,
    error: null,
  },
};

/**
 * Get request Key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Users reducer.
 * @function users
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function alfresco(state = initialState, action = {}) {
  switch (action.type) {
    case `${ALFRESCO_GET_TICKET}_PENDING`:
    case `${ALFRESCO_GET_NODE}_PENDING`:
    case `${ALFRESCO_GET_CONTENT}_PENDING`:
    case `${ALFRESCO_CREATE_SHARED_LINK}_PENDING`:
    case `${ALFRESCO_GET_SHARED_LINK}_PENDING`:
    case `${ALFRESCO_CREATE_RENDITION}_PENDING`:
    case `${ALFRESCO_SEARCH}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${ALFRESCO_GET_NODE_SUBREQUEST}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  list: [],
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            [getRequestKey(action.type)]: {
              loading: true,
              loaded: false,
              error: null,
            },
          };
    case `${ALFRESCO_GET_TICKET}_SUCCESS`:
      AlfrescoAuthUtil.saveToken(action.result);
      return {
        ...state,
        token: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };

    case `${ALFRESCO_GET_TICKET}_FAIL`:
      AlfrescoAuthUtil.clearAccessToken();
      return {
        ...state,
        token: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${ALFRESCO_GET_CHILDREN}_SUCCESS`:
    case `${ALFRESCO_GET_NODE}_SUCCESS`:
    case `${ALFRESCO_SEARCH}_SUCCESS`:
      return {
        ...state,
        list: action.result?.list,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${ALFRESCO_GET_NODE_SUBREQUEST}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                list: action.result?.list,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            list: action.result?.list,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: null,
            },
          };

    case `${ALFRESCO_GET_NODE_SUBREQUEST}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                list: null,
                loaded: true,
                loading: false,
              },
            },
          }
        : {
            ...state,
            list: null,
            [getRequestKey(action.type)]: {
              loading: false,
              loaded: true,
              error: null,
            },
          };
    case `${ALFRESCO_GET_CHILDREN}_FAIL`:
    case `${ALFRESCO_GET_NODE}_FAIL`:
    case `${ALFRESCO_SEARCH}_FAIL`:
      return {
        ...state,
        list: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${ALFRESCO_GET_CONTENT}_SUCCESS`:
      return {
        ...state,
        content: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };

    case `${ALFRESCO_GET_CONTENT}_FAIL`:
      return {
        ...state,
        content: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case `${ALFRESCO_CREATE_RENDITION}_FAIL`:
      return {
        ...state,
        rendition: false,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };

    case `${ALFRESCO_CREATE_RENDITION}_SUCCESS`:
      return {
        ...state,
        rendition: true,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };

    case `${ALFRESCO_CREATE_SHARED_LINK}_SUCCESS`:
    case `${ALFRESCO_GET_SHARED_LINK}_SUCCESS`:
      return {
        ...state,
        sharedLink: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };

    case `${ALFRESCO_CREATE_SHARED_LINK}_FAIL`:
    case `${ALFRESCO_GET_SHARED_LINK}_FAIL`:
    case `${ALFRESCO_CLEAR_SHARED_LINK}`:
      return {
        ...state,
        sharedLink: null,
        rendition: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
