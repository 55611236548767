import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import View from './View';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';

export const SliderContext = React.createContext({
  slideIndex: 0,
  setSlideIndex: () => {},
});

const AlfrescoGalleryEdit = (props) => {
  const { onChangeBlock, block, selected } = props;

  const data = {
    ...props.data,
  };

  if (!props.data.slides) {
    onChangeBlock(block, data);
  }

  return (
    <>
      <View {...props} isEditMode={true} data={data} />
      <SidebarPortal selected={selected}>
        <Sidebar {...props} data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(AlfrescoGalleryEdit);
