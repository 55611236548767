import React, { useCallback, useEffect } from 'react';
import './Style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getPaginedMenu as getFullMenu } from '@package/actions';

/**
 * Fetches paginated menu from server and provides paginated menu and setUid function.
 *
 *
 * @return {Object} - The paginated menu and setUid function.
 * @return {Array} return.paginatedMenu - The paginated menu fetched from server.
 * @return {Function} return.setUid - The function to set the uid parameter for fetching menu.
 */
export default function usePaginedNavigation() {
  const dispatch = useDispatch();
  const paginedMenu = useSelector((state) => state.menu.paginedMenu);
  const paginedMenuError = useSelector((state) => state.menu.error);
  const [pathMenu, setPathMenu] = React.useState(null);

  const memoizedCallback = useCallback(
    (pathMenu) => {
      if (pathMenu) dispatch(getFullMenu(pathMenu));
    },
    [dispatch, pathMenu],
  );

  useEffect(() => {
    memoizedCallback(pathMenu);
  }, [memoizedCallback, pathMenu]);

  useEffect(() => {
    if (paginedMenuError?.status === 404) {
      dispatch(getFullMenu('/'));
    }
  }, [paginedMenuError]);

  return { paginedMenu, setPathMenu };
}
