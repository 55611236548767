export const primaryColor1 = '#1351b4';
export const secondaryColor1 = '#000000';
export const secondaryColor2 = '#ffffff';
export const secondaryColor3 = '#666666';
export const secondaryColor4 = '#a3a3a3';
export const secondaryColor5 = '#cccccc';
export const secondaryColor6 = '#f3f3ff';
export const secondaryColor7 = '#212529';
export const secondaryColor8 = '#C0C0C0';
export const secondaryColor9 = '#f8f8f8';
export const secondaryColor10 = '#c5d4eb';
export const secondaryColor11 = '#adc5ea';
export const secondaryColor12 = '#ff0000';
export const secondaryColor13 = '#888888';
export const secondaryColor14 = '#333333';
export const secondaryColor15 = '#bcbcbc';
export const secondaryColor16 = '#0c326f';
export const secondaryColor17 = '#081f32';
export const secondaryColor18 = '#c4c4c4';
export const secondaryColor19 = '#252525';

export const successColor = '#34a091';
export const infoColor = '#155bcb';
export const infoColor2 = '#40e0d0';
export const dangerColor = '#f08080';
export const dangerColorStrong = '#e54802';
export const warningColor = '#fdf7d4';

export const focusColor1 = '#e54805';
export const hoverColor1 = '#dcdef3';
export const hoverColor2 = '#dedede';
export const selectedItemColor = '#2670e7';
export const selectedItemHoverColor = '#7284ef';
export const disabledInputColor = '#ededed';

export const primaryButtonColor = '#1a1b59';
export const primaryButtonHoverColor = '#3f3f74';
export const primaryButtonActiveColor = '#8182a4';
export const primaryButtonDisabledColor = '#a5a5a5';
export const secondaryButtonColor = secondaryColor2;
export const secondaryButtonHoverColor = '#dadbe4';
export const secondaryButtonActiveColor = '#9898b4';
export const primaryButtonHoverColor1 = '#396dc0';
export const primaryButtonActiveColor1 = '#7d9fd6';
export const secondaryButtonHoverColor1 = '#d9e3f3';
export const secondaryButtonActiveColor1 = '#95b1dd';
