import React, { Component } from 'react';
import config from '@plone/volto/registry';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { getNavigation } from '@plone/volto/actions';
import { UniversalLink } from '@plone/volto/components';

/**
 * Navigation container class.
 * @class Navigation
 * @extends Component
 */
class NavigationHeaderUFT extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    lang: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { settings } = config;
    if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
      this.props.getNavigation(getBaseUrl(this.props.pathname), settings.navDepth);
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settings } = config;
    if (nextProps.pathname !== this.props.pathname || nextProps.token !== this.props.token) {
      if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
        this.props.getNavigation(getBaseUrl(nextProps.pathname), settings.navDepth);
      }
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {React.JSX.Element} Markup for the component.
   */
  render() {
    return (
      <>
        {this.props.items.map((item, index) => {
          return (
            <UniversalLink href={item.url} onClick={this.props.onClick} key={index} className="br-item">
              {item.title}
            </UniversalLink>
          );
        })}
      </>
    );
  }
}
export default compose(
  injectIntl,
  connect(
    (state) => ({
      token: state.userSession.token,
      items: state.navigation.items,
      lang: state.intl.locale,
    }),
    { getNavigation },
  ),
)(NavigationHeaderUFT);
