import { defineMessages } from 'react-intl';

const messages = defineMessages({
  newsBlock: {
    id: 'News Block',
    defaultMessage: 'Destaque',
  },
  news: {
    id: 'News',
    defaultMessage: 'Notícia',
  },
});

export const mostVisitedServicesSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.newsBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['href'],
      },
    ],
    properties: {
      href: {
        title: props.intl.formatMessage(messages.news),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'image_scales', 'effective'],
        widgetOptions: {
          pattern_options: { selectableTypes: ['News Item'] },
        },
        allowExternals: false,
      },
    },
    required: ['href'],
  };
};
