import React from 'react';
import './Style.css';

const cardServicoDefault = {
  icon: 'fas fa-cog',
  path: '/',
  title: 'Titulo do Serviço',
};

const CardService = ({ card }) => {
  const cardBuild = card || cardServicoDefault;

  return (
    <div className="card-service">
      <a href={cardBuild.path}>
        <div className="card-content">
          <div className="icon-service">
            <i className={cardBuild.icon} aria-hidden="true"></i>
          </div>
          <div className="title-service">{cardBuild.title}</div>
        </div>
      </a>
    </div>
  );
};

export default CardService;
