import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { flattenToAppURL, isInternalURL, withBlockExtensions } from '@plone/volto/helpers';

import './hero.less';

const View = ({ data, mode }) => {
  const {
    preHeadingText,
    headingText,
    subHeadingText,
    showActionButton,
    actionButtonText,
    actionButtonUrl,
    foregroundImage,
    margin,
    external_link,
    openLinkInNewPage,
  } = data;

  // let headingFirstWord,
  //   headingRestWords,
  let marginValue = '0px';

  // if (headingText) {
  //   headingFirstWord = headingText.substring(0, headingText.indexOf(' '));
  //   headingRestWords = headingText.substring(headingText.indexOf(' ') + 1);
  // }

  if (margin) {
    marginValue = margin;
  }

  const urlHandler = (url) => (isInternalURL(url) ? `${flattenToAppURL(url)}/@@images/image` : url);
  // nao estava sendo usado
  // const actionButtontoUrl = (url) => (url ? flattenToAppURL(url[0]['@id']) : '/#');

  const link =
    actionButtonUrl !== undefined && actionButtonUrl.length === 1 ? actionButtonUrl[0]?.['@id'] : external_link;

  return (
    <div className="uftHeroRoot" style={{ margin: marginValue }}>
      <div className="uftHeroContainer">
        <div className="uftHeroLeft">
          <div className="uftHeroInfo">
            <div className="uftHeroTextPreHeading">{preHeadingText}</div>
            <div className="uftHeroTextHeading uftHeroHeadingAlt">
              <span className="uftHeroYellowShapeWrapper">{headingText}</span>
              {/* {headingRestWords ? ` ${headingRestWords}` : null} */}
            </div>
          </div>
          <span className="uftHeroSubHeading text-up-02 pb-2 text-gray-50">{subHeadingText}</span>
          {showActionButton ? (
            <Button
              primary
              className="uftHeroButton"
              content={actionButtonText}
              as="a"
              href={link}
              target={openLinkInNewPage ? '_blank' : '_self'}
            />
          ) : null}
        </div>
        <div className="uftHeroRight">
          <div className="uftHeroImageWrapper">
            <Image src={urlHandler(foregroundImage)} className="uftHeroImageMain" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withBlockExtensions(View);
