import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import { mergeSchemas } from './mergeSchema';

const messages = defineMessages({
  Services: {
    id: 'Serviços',
    defaultMessage: 'Serviços',
  },
  service: {
    id: 'Serviço',
    defaultMessage: 'Serviço',
  },
  addService: {
    id: 'Adicionar Serviço',
    defaultMessage: 'Adicionar Serviço',
  },
});

export const serviceSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.service),
      addMessage: props.intl.formatMessage(messages.addService),
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['href'],
        },
      ],

      properties: {
        href: {
          title: props.intl.formatMessage(messages.service),
          widget: 'object_browser',
          mode: 'link',
          selectedItemAttrs: ['@type', 'icon', 'short_title'],
          widgetOptions: {
            pattern_options: { selectableTypes: ['service'] },
          },
          allowExternals: false,
        },
      },
      required: ['href'],
    },
    config.blocks.blocksConfig.servicesBlock.extensions?.blockSchema || {},
  );

export const servicesSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.Services),
      block: 'servicesBlock',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['ordenar', 'todos_servicos', 'services'],
        },
      ],
      properties: {
        ordenar: {
          title: 'ordenar A-Z',
          type: 'boolean',
          defaultValue: true,
        },
        todos_servicos: {
          title: 'Todos os serviços',
          widget: 'object_browser',
          mode: 'link',
          selectedItemAttrs: ['@type', '@id'],
          widgetOptions: {
            pattern_options: { selectableTypes: ['Document'] },
          },
          allowExternals: false,
        },
        services: {
          widget: 'servicesBlock_list',
          title: props.intl.formatMessage(messages.Services),
          schema: serviceSchema(props),
        },
      },
      required: [],
    },
    config.blocks.blocksConfig.servicesBlock.extensions?.blockSchema || {},
  );
