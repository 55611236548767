import { ALFRESCOLINK } from '../constants';

export const withAlfrescolink = (editor) => {
  const { isInline } = editor; // we can also normalize plugin data here

  if (editor) {
    editor.isInline = (element) => {
      if (element) {
        return element.type === ALFRESCOLINK ? true : isInline(element);
      }
      return null;
    };
  }

  return editor;
};
