const tokenKeyName = 'alfrescoTicket';

class AlfrescoAuthUtil {
  static saveToken(token) {
    window.localStorage.setItem(tokenKeyName, token);
  }

  static clearAccessToken() {
    window.localStorage.removeItem(tokenKeyName);
  }

  static getAccessToken() {
    return window.localStorage.getItem(tokenKeyName);
  }

  static newSession(token) {
    AlfrescoAuthUtil.saveToken(token);
  }

  static clearSession() {
    AlfrescoAuthUtil.clearAccessToken();
  }

  static getNodePath(nodeId) {
    return `-default-/public/alfresco/versions/1/nodes/${nodeId}/children?include=aspectNames,properties`;
  }

  static getNodeContentPath(nodeId) {
    return `-default-/public/alfresco/versions/1/nodes/${nodeId}/content`;
  }

  static getChildrenPath(nodeId) {
    return `-default-/public/alfresco/versions/1/nodes/${nodeId}/children?where=(isFile=true)&include=aspectNames,properties`;
  }
  static createSharedlinkPath() {
    return `-default-/public/alfresco/versions/1/shared-links`;
  }

  static createRendition(nodeId) {
    return `-default-/public/alfresco/versions/1/nodes/${nodeId}/renditions`;
  }

  static getSharedlinkPath(nodeId) {
    return `-default-/public/alfresco/versions/1/shared-links/${nodeId}`;
  }

  static searchPath() {
    return `-default-/public/search/versions/1/search`;
  }

  static getQueryContent(value, mineTypes = []) {
    const queryType =
      mineTypes.length > 0
        ? mineTypes.reduce(
            (previous, current, index) => previous + `${index > 0 && ' OR '} content.mimetype:'${current}'`,
            '',
          )
        : '';
    let query = `(name:'${value}')`;
    if (queryType) {
      query = `${query} AND (${queryType})`;
    }
    return `${query} AND TYPE:"cm:content"`;
  }

  static getPublicSharedlink(sharedLinkId, fileName) {
    const file = fileName ? `/${fileName}` : '';
    return `https://docs.uft.edu.br/share/proxy/alfresco-noauth/api/internal/shared/node/${sharedLinkId.replace(
      /\[|\]/g,
      '',
    )}/content${file}`;
  }
  static getPublicSharedlinkRendition(sharedLinkId, fileName, thumbnailType = 'imgpreview') {
    return this.getPublicSharedlink(sharedLinkId, fileName) + '/thumbnails/' + thumbnailType;
  }
}

export default AlfrescoAuthUtil;
