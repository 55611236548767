import React, { useState, useEffect } from 'react';
import { Button, Container, Form, FormField, Segment } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import CodeInput from './CodeInput';
import { useDispatch } from 'react-redux';
import { printCertificate } from '../../../actions';

const AutenticacaoPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialAuthCode = queryParams.get('AUTH') ?? queryParams.get('auth');
  const [authCode, setAuthCode] = useState(initialAuthCode ?? '');
  const model = 'certificado_autenticacao_participante';
  const dispatch = useDispatch();

  const redirectToPdf = (search) => {
    if (window) window.location.href = `https://intranet.uft.edu.br/autenticacao/certificado.php${search}`;
  };
  const isCodeComplete = () => {
    return authCode.split('-').length === 5 && authCode.split('-').every((part) => part.length === 5);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await getReport();
  };

  const getReport = async () => {
    let downloadName = 'uft_' + model.substr(0, 3) + Math.random().toString(36).substr(2, 5);

    try {
      dispatch(printCertificate(authCode))
        .then((data) => {
          // Decodificar a string Base64
          const byteCharacters = atob(data.content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: data.content_type });
          const blobUrl = URL.createObjectURL(blob);

          // Criar um link e clicar nele programaticamente para fazer o download
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = downloadName + '.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((err) => {
          redirectToPdf(`?acao=print&AUTH=${authCode}`);
        });
    } catch (error) {
      redirectToPdf(`?acao=print&AUTH=${authCode}`);
    }
  };

  useEffect(() => {
    if (initialAuthCode && location?.search) {
      getReport();
    }
  }, [location]);

  return (
    <Segment basic className="content-area">
      <main>
        <Container>
          <h1 style={{ textAlign: 'center', padding: '50px' }}>Autenticação de Certificados</h1>
          <Form onSubmit={handleSubmit}>
            <FormField hidden name={'action'} value={'print'} />
            <CodeInput value={authCode} onChange={setAuthCode} />
            <Button primary disabled={!isCodeComplete()}>
              Validar
            </Button>
          </Form>
          {initialAuthCode && <p>{'Aguarde estamos validando os dados.'}</p>}
        </Container>
      </main>
    </Segment>
  );
};

export default AutenticacaoPage;
