import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const DiplomaPage = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const redirectToDiplomaExterno = () => {
    const diplomaId = pathName.indexOf('diploma/') >= 0 ? pathName.split('diploma/')[1] : '';
    if (window) window.location.href = `https://diploma.uft.edu.br/${diplomaId}`;
  };

  useEffect(() => {
    redirectToDiplomaExterno();
  }, []);

  return <>Redirecionando ...</>;
};

export default DiplomaPage;
