import React, { useEffect, useState } from 'react';
import defaultNews from '@package/images/default-news.jpg';
import { Image, Grid } from 'semantic-ui-react';
import axios from 'axios';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { flattenToAppURL } from '@plone/volto/helpers';

const NewsImage = ({ content }) => {
  const { image, title } = content;
  let download = defaultNews;
  download = image['scales'].teaser.download;
  download = `${download}`;

  return <Image src={download} alt={title} className="news-img" />;
};

const RelatedNewsItem = (props) => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    if (props.items.length > 0) {
      let newsItems = [];

      async function getNew(id) {
        const url = '/++api++' + flattenToAppURL(id);
        const response = await axios.get(url);
        return await response.data;
      }

      props.items.map(async (item) => {
        const ni = await getNew(item['@id']);
        setTimeout(() => {
          newsItems.push(ni);
        }, 4600);
      });

      setNews(newsItems);
    }
  }, [props.items]);

  return (
    <React.Fragment>
      <div>
        {news.length > 0 && (
          <div className="related-news">
            <div className="ui container">
              <h1 className="related-news-title">Notícias Relacionadas</h1>
              <Grid>
                <Grid.Row columns={3}>
                  {news.map((newItem, index) => {
                    const dt = newItem ? newItem.effective : newItem.created;
                    return newItem.review_state === 'published' && newItem['@type'] === 'News Item' ? (
                      <Grid.Column key={'rl_grid_' + index}>
                        <div className="news-container" key={'rl_' + index}>
                          <div className="ui fluid image pickgradient">
                            <NewsImage content={newItem} />
                            <div className="news-title">
                              <a href={newItem['@id']} className="news-link">
                                {newItem.title}
                              </a>
                            </div>
                            <div className="news-subtitle">{Moment(dt).format('dddd, DD [de] MMMM [de] YYYY')}</div>
                          </div>
                          <div>
                            <p className="news-desc">{newItem.description}</p>
                          </div>
                        </div>
                      </Grid.Column>
                    ) : (
                      <div key={'rl_grid_' + index}></div>
                    );
                  })}
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default RelatedNewsItem;
