import { defineMessages } from 'react-intl';

const messages = defineMessages({
  swiperBlock: {
    id: 'swiper',
    defaultMessage: 'Swiper UFT',
  },
  estude_uft: {
    id: 'estude_uft',
    defaultMessage: 'Quero ser aluno(a)',
  },
  sou_aluno: {
    id: 'sou_aluno',
    defaultMessage: 'Sou aluno(a)',
  },
  sou_egresso: {
    id: 'sou_egresso',
    defaultMessage: 'Sou egresso(a)',
  },
  sou_servidor: {
    id: 'sou_servidor',
    defaultMessage: 'Sou servidor(a)',
  },
});

export const swiperSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.swiperBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['href_1', 'href_2', 'href_3', 'href_4'],
      },
    ],
    properties: {
      href_1: {
        title: props.intl.formatMessage(messages.estude_uft),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'effective'],
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['Document', 'department', 'campus'],
          },
        },
        allowExternals: false,
      },
      href_2: {
        title: props.intl.formatMessage(messages.sou_aluno),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'effective'],
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['Document', 'department', 'campus'],
          },
        },
        allowExternals: false,
      },
      href_3: {
        title: props.intl.formatMessage(messages.sou_egresso),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'effective'],
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['Document', 'department', 'campus'],
          },
        },
        allowExternals: false,
      },
      href_4: {
        title: props.intl.formatMessage(messages.sou_servidor),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'effective'],
        widgetOptions: {
          pattern_options: {
            selectableTypes: ['Document', 'department', 'campus'],
          },
        },
        allowExternals: false,
      },
    },
    required: [],
  };
};
