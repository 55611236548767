/**
 * RatingWidget component.
 * @module components/RatingWidget/RatingWidget
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';

import { compose } from 'redux';

import './style.css';

/**
 * RatingWidget component class.
 * @function RatingWidget
 * @returns {string} Markup of the component.
 */
const SelectIconWidget = ({ id, title = null, required, description, error, value, onChange, onEdit, onDelete }) => {
  const [defaultValue, setDefaultValue] = useState();

  const iconsOptions = [
    { key: 'address-book', value: 'fa-address-book', text: 'Contatos' },
    { key: 'bell', value: 'fa-bell', text: 'Sino' },
    { key: 'camera', value: 'fa-camera', text: 'Câmera' },
    { key: 'database', value: 'fa-database', text: 'Banco de Dados' },
    { key: 'envelope', value: 'fa-envelope', text: 'Envelope' },
    { key: 'file', value: 'fa-file', text: 'Arquivo' },
    { key: 'globe', value: 'fa-globe', text: 'Globo' },
    { key: 'heart', value: 'fa-heart', text: 'Coração' },
    { key: 'info-circle', value: 'fa-info-circle', text: 'Info' },
    { key: 'key', value: 'fa-key', text: 'Chave' },
    { key: 'lock', value: 'fa-lock', text: 'Cadeado' },
    { key: 'map-marker', value: 'fa-map-marker', text: 'Marcador' },
    { key: 'pen', value: 'fa-pen', text: 'Caneta' },
    { key: 'question-circle', value: 'fa-question-circle', text: 'Interrogação' },
    { key: 'search', value: 'fa-search', text: 'Pesquisa' },
    { key: 'thumbs-up', value: 'fa-thumbs-up', text: 'Legal' },
    { key: 'user', value: 'fa-user', text: 'Usuário' },
    { key: 'wrench', value: 'fa-wrench', text: 'Chave' },
    { key: 'download', value: 'fa-download', text: 'Download' },
    { key: 'exclamation-triangle', value: 'fa-exclamation-triangle', text: 'Exclamação' },
    { key: 'flag', value: 'fa-flag', text: 'Bandeira' },
    { key: 'graduation-cap', value: 'fa-graduation-cap', text: 'Graduação' },
    { key: 'home', value: 'fa-home', text: 'Casa' },
    { key: 'italic', value: 'fa-italic', text: 'Itálico' },
    { key: 'link', value: 'fa-link', text: 'Link' },
    { key: 'music', value: 'fa-music', text: 'Música' },
    { key: 'pencil-alt', value: 'fa-pencil-alt', text: 'Lápis' },
    { key: 'quote-left', value: 'fa-quote-left', text: 'Citação' },
    { key: 'users', value: 'fa-users', text: 'Usuários' },
    { key: 'cogs', value: 'fa-cogs', text: 'Engrenagens' },
    { key: 'building', value: 'fa-building', text: 'Prédio' },
    { key: 'lock', value: 'fa-lock', text: 'Cadeado' },
    { key: 'home', value: 'fa-home', text: 'Casa' },
    { key: 'school', value: 'fa-school', text: 'Graduação' },
    { key: 'star', value: 'fa-star', text: 'Estrela' },
    { key: 'sign-language', value: 'fa-sign-language', text: 'Linguagem de Sinais' },
    { key: 'user-graduate', value: 'fa-user-graduate', text: 'Usuário Graduação' },
    { key: 'graduation-cap', value: 'fa-graduation-cap', text: 'Chapéu Graduação' },
    { key: 'award', value: 'fa-award', text: 'Prêmio' },
    { key: 'microscope', value: 'fa-microscope', text: 'Microscópio' },
    { key: 'chalkboard', value: 'fa-chalkboard', text: 'Quadro' },
    { key: 'shapes', value: 'fa-shapes', text: 'Formas' },
    { key: 'masks-theater', value: 'fa-masks-theater', text: 'Máscaras' },
    { key: 'laptop-code', value: 'fa-laptop-code', text: 'Laptop Code' },
    { key: 'book-open', value: 'fa-book-open', text: 'Livro Aberto' },
    { key: 'network-wired', value: 'fa-network-wired', text: 'Rede' },
    { key: 'chart-line', value: 'fa-chart-line', text: 'Gráfico' },
    { key: 'handshake', value: 'fa-handshake', text: 'Aperto de Mão' },
    { key: 'scale-balanced', value: 'fa-scale-balanced', text: 'Balança' },
    { key: 'circle-info', value: 'fa-circle-info', text: 'Info' },
    { key: 'globe', value: 'fa-globe', text: 'Globo' },
    { key: 'certificate', value: 'fa-certificate', text: 'Certificado' },
    { key: 'list-check', value: 'fa-list-check', text: 'Check List' },
  ];

  const handleSelection = (e, { value }) => {
    setDefaultValue(value);
    onChange(id, value);
  };

  const getIconsOptions = () => {
    let options = [];

    for (let icon of iconsOptions || []) {
      options = [
        ...options,
        {
          text: icon.text,
          key: icon.key,
          value: icon.value,
          content: (
            <Dropdown.Item value={icon.value} key={icon.key}>
              <i className={'fa ' + icon.value} />
              &nbsp;{icon.text}
            </Dropdown.Item>
          ),
        },
      ];
    }

    return options;
  };

  useEffect(() => {
    if (value && value !== 'null') {
      setDefaultValue(value);
    }
  }, []);
  return (
    <Form.Field
      inline
      required={required}
      error={error.length > 0}
      className={description ? 'help' : ''}
      style={{
        paddingTop: '10px',
      }}
    >
      <div className="ui grid">
        <div className="stretched row">
          <div className="four wide column">
            <div className="wrapper">
              <label>Ícone</label>
            </div>
          </div>
          <div className="eight wide column">
            <Dropdown
              fluid
              selection
              placeholder="Selecione um ícone"
              options={getIconsOptions()}
              onChange={handleSelection}
              value={defaultValue}
            ></Dropdown>
          </div>
        </div>
      </div>
    </Form.Field>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SelectIconWidget.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
SelectIconWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: '',
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default compose(injectIntl)(SelectIconWidget);
