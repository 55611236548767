import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { SidebarPortal } from '@plone/volto/components';
import CardBlockData from './Data';
import CardBlockView from './View';

const CardBlockEdit = (props) => {
  const { data, onChangeBlock, block, selected } = props;

  return (
    <>
      <CardBlockView {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <CardBlockData data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </>
  );
};

export default withBlockExtensions(CardBlockEdit);
