/**
 * View image block.
 * @module components/manage/Blocks/Image/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import './style.less';
/**
 * View image block class.
 * @class View
 * @extends Component
 */
const AlfrescoImageViewBlock = ({ data, detached, clicable }) => {
  if (!data.url) {
    return 'Selecione uma imagem ...';
  }
  //const url = data.url.replace('thumbnails/imgpreview', '');

  return (
    <div
      className={cx(
        'block image align block-alfresco',
        {
          center: !Boolean(data.align),
          detached,
        },
        data.align,
      )}
    >
      {data.url && (
        <>
          {(() => {
            const image = (
              <img
                className={cx({
                  'full-width': data.align === 'full',
                  large: data.size === 'l',
                  medium: data.size === 'm',
                  small: data.size === 's',
                })}
                src={data.url}
                alt={data.alt || ''}
                loading="lazy"
                style={{ pointerEvents: clicable ? 'none' : 'auto' }}
              />
            );
            if (data.href) {
              return (
                <div>
                  <UniversalLink href={data.href} openLinkInNewTab={data.openLinkInNewTab}>
                    {image}
                  </UniversalLink>
                  {data.legend && (
                    <div
                      id="alfresco-image-legend"
                      className={cx('alfresco-image-caption', {
                        large: data.size === 'l',
                        medium: data.size === 'm',
                        small: data.size === 's',
                      })}
                    >
                      {data.legend}
                    </div>
                  )}
                </div>
              );
            } else {
              return image;
            }
          })()}
        </>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AlfrescoImageViewBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AlfrescoImageViewBlock;
