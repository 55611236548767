import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  border: ${({ unbordered, error }) => (unbordered ? 'none' : `1px solid ${error ? '#e54805' : '#888888'}`)};
  border-radius: 4px;
  outline: none;
  display: block;
  padding-right: 3px;
  &:hover {
    background-color: #dedede;
  }
  input {
    outline: none;
    font-size: 16.8px;
    padding-left: 10px;
    font-family: Rawline-Regular, sans-serif;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border-color: transparent;
    border-radius: 4px;

    &:not(:disabled) {
      &:active {
        box-shadow: 0 0 0 3px #e54805;
        border-color: transparent;
      }
      &:focus {
        box-shadow: 0 0 0 3px #e54805;
        border-color: transparent;
      }
      &:hover {
        background-color: #dcdef3;
      }
    }
    &::placeholder {
      color: #666666;
      font-style: italic;
      opacity: 0.75;
      font-weight: lighter;
      font-family: Rawline-Regular, sans-serif;
    }
    &:disabled {
      background-color: #ededed;
      cursor: not-allowed;
    }
  }
  Button {
    width: 40px;
    height: 40px;
    justify-items: center;
    border-radius: 100%;
    border: none;
    background-color: transparent;
    position: absolute;
    margin-left: -40px;
    margin-top: 4px;
    margin-right: 2px;
    float: right;
    cursor: pointer;
    &:not(:disabled) {
      :hover {
        background-color: #dcdef3;
      }
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const PrimaryIcon = styled.i`
  color: #1351b4;
`;
export const Button = styled.button``;
