import React, { useEffect } from 'react';
import { Accordion, Icon, Table } from 'semantic-ui-react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getQueryStringResults } from '@plone/volto/actions/querystringsearch/querystringsearch';
import { CircleButton } from '@package/components/GovBr/button';
import PropTypes from 'prop-types';

const ContactChildTable = ({ content, idForSelector }) => {
  const dispatch = useDispatch();

  const contacts = useSelector((state) => state.querystringsearch.subrequests?.[`contacts-${idForSelector}`]?.items);

  const grouper = _.reduce(
    contacts,
    (retorno, v) => {
      const group = v.grouper?.token ?? v.grouper;
      retorno[group] = typeof retorno[group] === 'object' ? [...retorno[group], v] : [v];
      return retorno;
    },
    [],
  );

  const panels = _.reduce(
    Object.entries(grouper),
    (retorno, row) => {
      const v = row[1][0];

      const group = v.grouper?.token ?? v.grouper;

      retorno.push({
        key: group,
        title: {
          content: group,
          icon: 'angle',
        },
        content: {
          content: (
            <Table celled padded key={v.short_title} className="br-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell singleLine>Unidade / Setor</Table.HeaderCell>
                  <Table.HeaderCell>Telefone</Table.HeaderCell>
                  <Table.HeaderCell>E-mail</Table.HeaderCell>
                  <Table.HeaderCell>Responsáveis</Table.HeaderCell>
                  <Table.HeaderCell>Detalhes</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {_.map(row[1], (r) => (
                  <Table.Row key={r.title}>
                    <Table.Cell>{r.title}</Table.Cell>
                    <Table.Cell>{r.phone_number}</Table.Cell>
                    <Table.Cell>{r.email}</Table.Cell>
                    <Table.Cell>{r.manager}</Table.Cell>
                    <Table.Cell>
                      <CircleButton
                        style={{
                          border: '1px solid #a3a3a3',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          window.location = r['@id'];
                        }}
                      >
                        <Icon
                          name="eye"
                          size="large"
                          style={{
                            right: '10px',
                            marginTop: '4px',
                          }}
                        />
                      </CircleButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ),
        },
      });
      return retorno;
    },
    [],
  );

  useEffect(() => {
    if (content) {
      const mainContactPath = content.physicalPath ?? content.getPath.replace(/\//g, '_');
      dispatch(
        getQueryStringResults(
          '/',
          {
            b_size: 25,
            metadata_fields: ['title', 'short_title', 'grouper', 'email', 'phone_number', 'manager', 'site_url'],
            query: [
              {
                i: 'portal_type',
                o: 'plone.app.querystring.operation.selection.any',
                v: ['contact'],
              },
              {
                i: 'main_contact',
                o: 'plone.app.querystring.operation.selection.any',
                v: mainContactPath,
              },
              {
                i: 'type_contact',
                o: 'plone.app.querystring.operation.selection.any',
                v: 'simple',
              },
            ],
            sort_on: 'sortable_title',
            sort_order: 'ascending',
          },
          `contacts-${idForSelector}`,
        ),
      );
    }
  }, [content, dispatch]);

  if (!content) return null;

  const type_contact = content.type_contact?.token ?? content.type_contact;
  return (
    <>
      {type_contact === 'main' && panels.length > 0 && (
        <div>
          <Accordion
            defaultActiveIndex={[...Array(panels.length).keys()]}
            panels={panels}
            className={'styled-event'}
            exclusive={false}
          />
        </div>
      )}{' '}
    </>
  );
};

ContactChildTable.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    email: PropTypes.string,
    manager: PropTypes.string,
    phone_number: PropTypes.string,
    opening_hours: PropTypes.string,
    location_map: PropTypes.string,
    site_url: PropTypes.string,
    physicalPath: PropTypes.string,
    type_contact: PropTypes.shape({}),
    grouper: PropTypes.shape({}),
    main_contact: PropTypes.shape({}),
    grouper_availables: PropTypes.arrayOf(PropTypes.string),
    complement: PropTypes.string,
    subjects: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ContactChildTable;
