import styled from 'styled-components';

export const MagicButtonStyle = styled.div`
  .br-magic-gov-button {
    --magic-size: var(--magic-medium);
    --magic-small: var(--spacing-scale-4xh);
    --magic-medium: var(--spacing-scale-5xh);
    --magic-large: var(--spacing-scale-6xh);
    --magic-support-size: var(--magic-support-medium);
    --magic-support-small: var(--spacing-scale-7x);
    --magic-support-medium: var(--spacing-scale-8x);
    --magic-support-large: var(--spacing-scale-9x);
    --magic-z-index: var(--z-index-layer-1);
    align-items: center;
    background-color: var(--gray-5);
    border-radius: 100em;
    box-shadow: var(--surface-shadow-md);
    height: var(--magic-support-size);
    padding: calc((var(--magic-support-size) - var(--magic-size)) * 0.5);
  }
  .br-magic-gov-button.small {
    --magic-size: var(--magic-small);
    --magic-support-size: var(--magic-support-small);
  }
  .br-magic-gov-button.medium {
    --magic-size: var(--magic-medium);
    --magic-support-size: var(--magic-support-medium);
  }
  .br-magic-gov-button.large {
    --magic-size: var(--magic-large);
    --magic-support-size: var(--magic-support-large);
  }
  .br-magic-gov-button .svg-inline--fa,
  .br-magic-gov-button .fa,
  .br-magic-gov-button .fab,
  .br-magic-gov-button .fad,
  .br-magic-gov-button .fal,
  .br-magic-gov-button .far,
  .br-magic-gov-button .fas {
    --icon-size: var(--icon-size-lg);
  }
  .br-magic-gov-button .br-button-gov {
    --focus-offset: calc((var(--magic-support-size) - var(--magic-size)) * 0.5 + 4px);
    --button-size: var(--magic-size);
    background-color: var(--interactive-alternative);
    color: var(--color-dark);
    font-size: var(--font-size-scale-up-02);
    font-weight: var(--font-weight-semi-bold);
  }
  .br-magic-gov-button .br-button-gov:not(:disabled):not(:disabled):hover {
    background-image: linear-gradient(
      rgba(var(--color-dark-rgb), var(--hover)),
      rgba(var(--color-dark-rgb), var(--hover))
    );
  }
  .br-magic-gov-button .br-button-gov:not(:disabled):not(:disabled):active {
    background-image: linear-gradient(
      rgba(var(--color-dark-rgb), var(--pressed)),
      rgba(var(--color-dark-rgb), var(--pressed))
    );
  }

  .br-button-gov {
    --button-radius: 100em;
    --button-xsmall: 24px;
    --button-small: 32px;
    --button-medium: 40px;
    --button-large: 48px;
    --button-size: var(--button-medium);
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: var(--button-radius);
    color: var(--interactive);
    cursor: pointer;
    display: inline-flex;
    font-size: var(--font-size-scale-up-01);
    font-weight: var(--font-weight-semi-bold);
    height: var(--button-size);
    justify-content: center;
    overflow: hidden;
    padding: 0 var(--spacing-scale-3x);
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
  }
  .br-button-gov.block {
    width: 100%;
  }
  @media (min-width: 576px) {
    .br-button-gov.block-sm {
      width: 100%;
    }
    .br-button-gov.auto-sm {
      width: auto;
    }
  }
  @media (min-width: 992px) {
    .br-button-gov.block-md {
      width: 100%;
    }
    .br-button-gov.auto-md {
      width: auto;
    }
  }
  @media (min-width: 1280px) {
    .br-button-gov.block-lg {
      width: 100%;
    }
    .br-button-gov.auto-lg {
      width: auto;
    }
  }
  @media (min-width: 1600px) {
    .br-button-gov.block-xl {
      width: 100%;
    }
    .br-button-gov.auto-xl {
      width: auto;
    }
  }
`;
