import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { FormFieldWrapper, TextWidget } from '@plone/volto/components';

import clearSVG from '@plone/volto/icons/clear.svg';
import ImageSizeWidget from './ImageSizeWidget';
import ExploreDocs from './ExploreDocs';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Gallery: {
    id: 'Gallery',
    defaultMessage: 'Gallery',
  },
  SelectedGallery: {
    id: 'SelectedGallery',
    defaultMessage: 'Selected Gallery',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  AltTextHint: {
    id: 'Alt text hint',
    defaultMessage: 'Leave empty if the image is purely decorative.',
  },
  AltTextHintLinkText: {
    id: 'Alt text hint link text',
    defaultMessage: 'Describe the purpose of the image.',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Size',
  },
  linkSettings: {
    id: 'Link settings',
    defaultMessage: 'Link settings',
  },
});

const Sidebar = ({ data, block, onChangeBlock, required = false, intl }) => {
  const onChangeGallery = (value) => {
    onChangeBlock(block, {
      ...data,
      galleryName: value.galleryName,
      slides: value.slides,
    });
  };
  return (
    <Segment.Group raised>
      {(!data.slides || data.slides.length === 0) && (
        <ExploreDocs
          onChangeDocument={onChangeGallery}
          mimeTypes={['image/jpeg', 'image/png', 'image/gif']}
          defaultPath={'d74ba8e9-4ee1-4d03-b9e7-4b8f0f571886'}
        />
      )}
      {data.slides && data.slides.length !== 0 && (
        <>
          <header className="header pulled">
            <h2>
              <FormattedMessage id="Gallery" defaultMessage="Gallery" />
            </h2>
          </header>
          <Segment className="form sidebar-image-data" secondary>
            <TextWidget
              id="gallery"
              title={intl.formatMessage(messages.SelectedGallery)}
              required={false}
              value={data.galleryName}
              icon={clearSVG}
              iconAction={() => {
                onChangeBlock(block, {
                  ...data,
                  galleryName: '',
                  slides: [],
                });
              }}
              onChange={() => {}}
            />
          </Segment>
          <Segment className="form sidebar-image-data">
            <FormFieldWrapper id="image_size" title={intl.formatMessage(messages.size)}>
              <ImageSizeWidget onChangeBlock={onChangeBlock} data={data} block={block} />
            </FormFieldWrapper>
          </Segment>
        </>
      )}
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
