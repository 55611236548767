import React from 'react';
import PropTypes from 'prop-types';
import { cleanTelephone } from '../../../util/generalUtil';
import FloatingWhatsApp from 'react-floating-whatsapp';

const ContactFloatWhatsApp = ({ content }) => {
  if (content?.whatsapp_number && !content?.whatsapp_simple)
    return (
      <FloatingWhatsApp
        accountName={content.short_title || content.title}
        phoneNumber={cleanTelephone(content?.whatsapp_number)}
        statusMessage={content?.whatsapp_status_message}
        placeholder={'Digite sua mensagem...'}
        chatMessage={content?.whatsapp_chat_message || 'Olá, gostaria de mais informações.'}
        notification
        notificationDelay={30000}
        notificationLoop={1}
        allowClickAway
        className={'whatsapp-button'}
        allowEsc
        avatar={
          content?.whatsapp_avatar ||
          'https://docs.uft.edu.br/share/proxy/alfresco-noauth/api/internal/shared/node/7PQSHELoSuCc5vnQQtfG8Q/content/bras%C3%A3oUFT_fundotransparente_RGB.png'
        }
      />
    );
  return null;
};

ContactFloatWhatsApp.propTypes = {
  content: PropTypes.shape({
    email: PropTypes.string,
    title: PropTypes.string,
    observations: PropTypes.string,
    manager: PropTypes.string,
    phone_number: PropTypes.string,
    whatsapp_simple: PropTypes.bool,
    opening_hours: PropTypes.string,
    location_map: PropTypes.string,
    site_url: PropTypes.string,
    grouper: PropTypes.shape({}),
    main_contact: PropTypes.shape({}),
    grouper_availables: PropTypes.arrayOf(PropTypes.string),
    contact_campus: PropTypes.arrayOf(PropTypes.string),
    contact_unit: PropTypes.arrayOf(PropTypes.string),
    contact_address: PropTypes.arrayOf(PropTypes.string),
    complement: PropTypes.string,
    contact_neighborhood: PropTypes.arrayOf(PropTypes.string),
    contact_city: PropTypes.arrayOf(PropTypes.string),
    contact_cep: PropTypes.arrayOf(PropTypes.string),
    subjects: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default ContactFloatWhatsApp;
