import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

const GridTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }
  return (
    <>
      <div className="items-grid">
        {items.map((item) => (
          <ConditionalLink item={item} condition={!isEditMode}>
            <div className="listing-item-grid" key={item['@id']}>
              <div className="listing-image-grid">
                <img
                  src={
                    item.image_scales?.image?.[0]?.scales?.preview?.download
                      ? item.image_scales.image[0].scales.preview.download
                      : DefaultImageSVG
                  }
                  alt={item.title}
                  className="listing-img"
                />
              </div>
              <div className="listing-body-grid">
                <p className="listing-title-grid">{item.title ? item.title : item.id}</p>
              </div>
            </div>
          </ConditionalLink>
        ))}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
GridTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default GridTemplate;
