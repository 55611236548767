import React, { useState, useEffect } from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import './swiper.less';

const SwiperBlockView = (props) => {
  const data = props.data;

  const href_1 = data['href_1'] !== undefined && data['href_1'].length === 1 ? data.href_1[0]['@id'] : '/#';
  const href_2 = data['href_2'] !== undefined && data['href_2'].length === 1 ? data.href_2[0]['@id'] : '/#';
  const href_3 = data['href_3'] !== undefined && data['href_3'].length === 1 ? data.href_3[0]['@id'] : '/#';
  const href_4 = data['href_4'] !== undefined && data['href_4'].length === 1 ? data.href_4[0]['@id'] : '/#';

  const [activeIndex, setActiveIndex] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setActiveIndex(!activeIndex);
  };

  useEffect(() => {}, [data]);

  return (
    <>
      <div className="swiper-uft">
        <div className="swiper-uft-cards">
          <div className="swiper-column-uft">
            <a href={href_1}>
              <div className="swiper-uft-header">
                <span>
                  <i className="fas swiper-icon-fire fa-user-graduate"></i>Quero ser aluno(a)
                </span>
                <div>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            </a>
          </div>

          <div className="swiper-column-uft">
            <a href={href_2} className="swiper-a">
              <div className="swiper-uft-header">
                <span>
                  <i className="fas fa-user-edit swiper-icon-star"></i> Sou aluno(a)
                </span>
                <div>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            </a>
          </div>

          <div className="swiper-column-uft">
            <a href={href_3} className="swiper-a">
              <div className="swiper-uft-header">
                <span>
                  <i className="fas fa-users swiper-icon-users"></i> Sou egresso(a)
                </span>
                <div>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            </a>
          </div>

          <div className="swiper-column-uft">
            <a href={href_4} className="swiper-a">
              <div className="swiper-uft-header">
                <span>
                  <i className="fas fa-user-check swiper-icon-user"></i> Sou servidor(a)
                </span>
                <div>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default withBlockExtensions(SwiperBlockView);
