import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContactOpeningHours from '@package/components/View/Contact/ContactOpeningHours';
import { PrimaryButton, SecondaryButton } from '@package/components/GovBr/button';
import { extractMaptSrc } from '@package/util/generalUtil';
import { Icon } from 'semantic-ui-react';
import { cleanTelephone } from '../../../util/generalUtil';
const ContactBody = ({ content, isBlock = false }) => {
  const [srcMap, setSrcMap] = useState(null);

  return (
    <div>
      {!isBlock && <h1 className="documentFirstHeading">Contatos e endereços</h1>}
      <div className="ui divider"></div>
      <div className="contact-title">
        {`${content.title}`}
        {content.short_title ? ' - ' + content.short_title : ''}
      </div>

      <div className="container-box">
        <div className="container-column" style={{ width: '30%', wordWrap: 'break-word' }}>
          <div>
            <p style={{ fontWeight: 700 }}> ENDEREÇO:</p>
            {content.contact_campus && (
              <p>
                {content.contact_campus} {content.contact_unit && <> - {content.contact_unit}</>}
              </p>
            )}
            {content.contact_address && <p>{content.contact_address}</p>}
            {content.complement && <p>{content.complement}</p>}
            {content.contact_neighborhood && <p>{content.contact_neighborhood}</p>}
            {content.contact_city && (
              <p>
                {content.contact_city}
                {content.contact_cep && <> | {content.contact_cep}</>}
              </p>
            )}
          </div>
        </div>
        <div className="container-column" style={{ marginLeft: '20px', wordWrap: 'break-word' }}>
          <div>
            <p style={{ fontWeight: 700, marginBottom: '0px' }}> CONTATOS:</p>
            {content.email}
            <br />
            {content.phone_number}
            <br />
            <br />
            {content.manager && (
              <>
                <p style={{ fontWeight: 700, marginBottom: '0px' }}>RESPONSÁVEIS:</p>
                {content.manager}
              </>
            )}
          </div>
        </div>

        <div className="container-column">
          <ContactOpeningHours content={content} />
        </div>
        <div className="container-column-sm">
          {content.site_url && (
            <PrimaryButton
              style={{
                width: '100%',
                minWidth: '140px',
                margin: '10px 10px 0 10px',
              }}
              onClick={() => (window.location = content.site_url)}
            >
              Acessar página
            </PrimaryButton>
          )}
          {content.whatsapp_number && content.whatsapp_simple && (
            <SecondaryButton
              style={{
                width: '100%',
                minWidth: '140px',
                margin: '10px 10px 0 10px',
              }}
              className="btn-whatsapp"
              as={'a'}
              href={`https://api.whatsapp.com/send/?phone=${cleanTelephone(
                content?.whatsapp_number,
              )}&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es+sobre+${content?.title}`}
              target="_blank"
            >
              <Icon name="whatsapp" /> Whatsapp
            </SecondaryButton>
          )}
          {content.location_map && srcMap && (
            <SecondaryButton
              style={{
                width: '100%',
                minWidth: '140px',
                margin: '10px 10px 0 10px',
              }}
              onClick={() => setSrcMap(null)}
            >
              Fechar localização
            </SecondaryButton>
          )}
          {content.location_map && !srcMap && (
            <SecondaryButton
              style={{
                width: '100%',
                minWidth: '140px',
                margin: '10px 10px 0 10px',
              }}
              onClick={() => setSrcMap(extractMaptSrc(content.location_map))}
            >
              Ver localização
            </SecondaryButton>
          )}
        </div>
      </div>
      {content.observations && (
        <div className="container-box">
          <div
            className="container-row"
            style={{
              width: '100%',
              border: '1px solid #ccc',
              borderLeft: 'none',
              borderRight: 'none',
              padding: '8px 0 8px 0',
            }}
          >
            <div>
              <p>
                <span style={{ fontWeight: 700 }}>OBSERVAÇÕES :</span> {content.observations}
              </p>
            </div>
          </div>
        </div>
      )}

      {srcMap && (
        <div className="container-map">
          <div className="title-map" style={{ fontWeight: 700, marginTop: '16px' }}>
            {'LOCALIZAÇÃO:'}
          </div>
          <div className="block maps align center" style={{ width: '100%' }}>
            <div className="maps-inner full">
              <iframe title={content.title} src={srcMap} className="google-map" frameBorder="0" allowFullScreen />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ContactBody.propTypes = {
  content: PropTypes.shape({
    email: PropTypes.string,
    title: PropTypes.string,
    observations: PropTypes.string,
    manager: PropTypes.string,
    phone_number: PropTypes.string,
    opening_hours: PropTypes.string,
    location_map: PropTypes.string,
    site_url: PropTypes.string,
    grouper: PropTypes.shape({}),
    main_contact: PropTypes.shape({}),
    grouper_availables: PropTypes.arrayOf(PropTypes.string),
    contact_campus: PropTypes.arrayOf(PropTypes.string),
    contact_unit: PropTypes.arrayOf(PropTypes.string),
    contact_address: PropTypes.arrayOf(PropTypes.string),
    complement: PropTypes.string,
    contact_neighborhood: PropTypes.arrayOf(PropTypes.string),
    contact_city: PropTypes.arrayOf(PropTypes.string),
    contact_cep: PropTypes.arrayOf(PropTypes.string),
    subjects: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default ContactBody;
