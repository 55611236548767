import React, { Component } from 'react';
import ImageCrop from './index';
import { Grid } from 'semantic-ui-react';

class DivCrop extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      openCropper: !!props.value,
      editor: null,
      scaleValue: 10,
    };
  }

  setEditorRef = (editor) => this.setState({ editor });

  onCrop = (e) => {
    e.preventDefault();
    const { editor } = this.state;
    if (editor !== null) {
      const url = editor.getImageScaledToCanvas().toDataURL();
      this.props.onChange(url);
    }
  };

  onScaleChange = (scaleChangeEvent) => {
    const scaleValue = parseFloat(scaleChangeEvent);
    this.setState({ scaleValue });
  };

  DataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  profilePicChange = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0];
    const { type } = file;
    if (!(type.endsWith('jpeg') || type.endsWith('png') || type.endsWith('jpg') || type.endsWith('gif'))) {
    } else {
      this.setState({
        openCropper: true,
        selectedImage: fileChangeEvent.target.files[0],
        fileUploadErrors: [],
      });
    }
  };
  render() {
    return (
      <Grid
        style={{
          marginTop: '2rem',
          marginBottom: '2rem',
          marginLeft: '1px',
          width: '100%',
        }}
      >
        <Grid.Row stretched>
          <Grid.Column width="4">
            <div
              className="wrapper"
              style={{
                borderBottom: 'none',
              }}
            >
              <label htmlFor={`field-avatar`}>{this.props.title}</label>
            </div>
          </Grid.Column>
          <Grid.Column width="8">
            <input
              type="file"
              name="field-avatar"
              style={{
                border: 'none',
              }}
              accept="image/png, image/jpeg"
              onChange={this.profilePicChange}
            />
          </Grid.Column>
        </Grid.Row>
        {this.state.openCropper && (
          <Grid.Row>
            <Grid.Column width="6">
              <ImageCrop
                imageSrc={this.state.selectedImage}
                setEditorRef={this.setEditorRef}
                onCrop={this.onCrop}
                scaleValue={this.state.scaleValue}
                onScaleChange={this.onScaleChange}
              />
            </Grid.Column>
            <Grid.Column width="6">
              {this.props.value && (
                <>
                  <div
                    className="wrapper"
                    style={{
                      borderBottom: 'none',
                    }}
                  >
                    <label htmlFor={`field-preview`}>Pré-visualização</label>
                  </div>
                  <img id="field-preview" src={this.props.value} alt="Profile" />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default DivCrop;
