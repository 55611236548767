/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */
export const ALFRESCO_GET_TICKET = 'ALFRESCO_GET_TICKET';
export const ALFRESCO_GET_NODE = 'ALFRESCO_GET_NODE';
export const ALFRESCO_GET_CONTENT = 'ALFRESCO_GET_CONTENT';
export const ALFRESCO_CREATE_SHARED_LINK = 'ALFRESCO_CREATE_SHARED_LINK';
export const ALFRESCO_GET_SHARED_LINK = 'ALFRESCO_GET_SHARED_LINK';
export const ALFRESCO_CLEAR_SHARED_LINK = 'ALFRESCO_CLEAR_SHARED_LINK';
export const ALFRESCO_CREATE_RENDITION = 'ALFRESCO_CREATE_RENDITION';
export const ALFRESCO_SEARCH = 'ALFRESCO_SEARCH';
export const ALFRESCO_GET_CHILDREN = 'ALFRESCO_GET_CHILDREN';
export const ALFRESCO_GET_NODE_SUBREQUEST = 'ALFRESCO_GET_NODE_SUBREQUEST';

export const GET_MENU = 'GET_MENU';
export const GET_FULL_MENU = 'GET_FULL_MENU';
export const GET_PAGINED_MENU = 'GET_PAGINED_MENU';

export const GET_STRUCTURE = 'GET_STRUCTURE';

export const GOVSERVICE_GET_SERVICE = 'GOVSERVICE_GET_SERVICE';
export const GOVSERVICE_LIST_SERVICES = 'GOVSERVICE_LIST_SERVICES';

export const APIUFT_LIST_DISCIPLINES = 'APIUFT_LIST_DISCIPLINES';
export const APIUFT_PRINT_CERTIFICATE = 'APIUFT_PRINT_CERTIFICATE';

export const LABS_NEWS_PREVIEW = 'LABS_NEWS_PREVIEW';
