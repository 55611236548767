import React, { useCallback, useEffect } from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import './main.less';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import { searchContent, resetSearchContent } from '@plone/volto/actions/search/search';
import { Loader } from 'semantic-ui-react';

const ServicesView = (props) => {
  const selectorKey = 'serviceBlock';
  const services = useSelector((state) => state.search.subrequests?.[`${selectorKey}`]);
  const loaded = services?.loaded ?? false;
  const loading = services?.loading ?? false;
  const dispatch = useDispatch();
  const { data, isEditMode } = props;

  const href = data?.todos_servicos?.[0]?.['@id'] ?? '/#';

  const searchContentCallback = useCallback(
    (data) => {
      const ids = data?.services?.reduce(
        (arr, s) => (s.href?.[0]?.['@id'] ? [...arr, flattenToAppURL(s.href[0]['@id'])] : arr),
        [],
      );

      if (ids.length > 0) {
        dispatch(searchContent('', { path: ids, metadata_fields: ['icon', 'url', 'short_title_sr'] }, selectorKey));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!loading && data?.services?.length !== services?.items_total) {
      searchContentCallback(data);
    }
    return () => {
      dispatch(resetSearchContent(selectorKey));
    };
  }, [data]);

  let sortedServices = [];
  if (data?.ordenar) {
    sortedServices = loaded ? [...services.items].sort((a, b) => a.title.localeCompare(b.title)) : [];
  } else {
    sortedServices = loaded
      ? [...services.items].sort((a, b) => {
          const indexA = data?.services.findIndex((s) => s.href?.[0]?.['@id'].includes(a['@id']));
          const indexB = data?.services.findIndex((s) => s.href?.[0]?.['@id'].includes(b['@id']));
          return indexA - indexB;
        })
      : [];
  }

  return (
    <div>
      <p className="services-title-block">Serviços</p>
      <div>
        {loaded && (
          <div className="service-row">
            {sortedServices.map((service, index) => {
              if (isEditMode) {
                return (
                  <div key={'service_view' + index}>
                    <div className="service-card" key={'service_' + index}>
                      <i className={'service-icon-block fa ' + service.icon}></i>
                      <p className="service-title">{service.short_title_sr}</p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <a href={service?.['@id']} key={'service_link' + index}>
                    <div key={'service_view' + index}>
                      <div className="service-card" key={'service_' + index}>
                        <i className={'service-icon-block fa ' + service.icon}></i>
                        <p className="service-title">{service.short_title_sr}</p>
                      </div>
                    </div>
                  </a>
                );
              }
            })}
          </div>
        )}
        {loading && (
          <div>
            <Loader active inline="centered">
              Carregando serviços...
            </Loader>
          </div>
        )}
        {loaded && (
          <div className="service-row">
            <div className="service-button service-row" key={'todos_servicos_key'}>
              <a className={'br-button secondary'} href={flattenToAppURL(href)} key={'todos_servicos_link_key'}>
                Veja todos os serviços
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withBlockExtensions(ServicesView);
