/**
 * Ticket actions.
 * @module actions/alfresco/ticket
 */

import {
  ALFRESCO_GET_TICKET,
  ALFRESCO_GET_NODE,
  ALFRESCO_GET_CONTENT,
  ALFRESCO_CREATE_SHARED_LINK,
  ALFRESCO_GET_SHARED_LINK,
  ALFRESCO_CLEAR_SHARED_LINK,
  ALFRESCO_CREATE_RENDITION,
  ALFRESCO_GET_CHILDREN,
} from '@package/constants/ActionTypes';

import AlfrescoAuthUtil from '@package/util/AlfrescoAuthUtil';
import { ALFRESCO_GET_NODE_SUBREQUEST, ALFRESCO_SEARCH } from '../../constants/ActionTypes';

/**
 * Request ticket function.
 * @function requestTicker
 * @param {Object|Array} content User data.
 * @returns {Object} Entry ticket.
 */
export function requestTicket(content) {
  return {
    type: ALFRESCO_GET_TICKET,
    request: {
      op: 'get',
      path: `@alfresco_token`,
      body: content,
    },
  };
}

/**
 * Request nodelist function.
 * @function getNode
 * @param {Object|Array} content Alfresco data.
 * @returns {Object} Entry node.
 */
export function getNode(content) {
  return {
    type: ALFRESCO_GET_NODE,
    request: {
      op: 'get',
      path: `@alfresco`,
      params: content
        ? { ...content, token: AlfrescoAuthUtil.getAccessToken() }
        : { token: AlfrescoAuthUtil.getAccessToken() },
    },
  };
}

/**
 * Request nodelist function.
 * @function getNode
 * @param {Object|Array} content Alfresco data.
 * @returns {Object} Entry node.
 */
export function getNodeSubRequest(content, subrequest = null) {
  return {
    type: ALFRESCO_GET_NODE_SUBREQUEST,
    subrequest,
    request: {
      op: 'get',
      path: `@alfresco`,
      params: content
        ? { ...content, token: AlfrescoAuthUtil.getAccessToken() }
        : { token: AlfrescoAuthUtil.getAccessToken() },
    },
  };
}

/**
 * Request content for nodeId function.
 * @function getNodeContent
 * @param {Object|Array} content Alfresco data content from file.
 * @returns {File} File from nodeId.
 */
export function getNodeContent({ nodeId }) {
  return {
    type: ALFRESCO_GET_CONTENT,
    request: {
      op: 'get',
      path: `@alfresco`,
      params: nodeId
        ? {
            path: `${nodeId}/content`,
            token: AlfrescoAuthUtil.getAccessToken(),
          }
        : { token: AlfrescoAuthUtil.getAccessToken() },
    },
  };
}

/**
 * Create shared link for nodeId function.
 * @function createSharedLink
 * @returns {Object} Entry node.
 * @param nodeId
 * */
export function createSharedLink(nodeId) {
  return {
    type: ALFRESCO_CREATE_SHARED_LINK,
    request: {
      op: 'post',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.createSharedlinkPath(),
        token: AlfrescoAuthUtil.getAccessToken(),
        params: JSON.stringify({
          nodeId: nodeId,
          // expiresAt: '2092-05-25T18:26:00.000+0000',
        }),
      },
    },
  };
}

/**
 * Create shared link for nodeId function.
 * @function createSharedLink
 * @returns {Object} Entry node.
 * @param nodeId
 * */
export function createMultipleSharedLink(nodeIds) {
  return {
    type: ALFRESCO_CREATE_SHARED_LINK,
    request: {
      op: 'post',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.createSharedlinkPath(),
        token: AlfrescoAuthUtil.getAccessToken(),
        params: JSON.stringify([...nodeIds]),
      },
    },
  };
}

/**
 * Create rendition for nodeId function.
 * @function createSharedLink
 * @param nodeId
 * @param type
 * */
export function createRendition(nodeId, type = 'imgpreview') {
  return {
    type: ALFRESCO_CREATE_RENDITION,
    request: {
      op: 'post',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.createRendition(nodeId),
        token: AlfrescoAuthUtil.getAccessToken(),
        params: JSON.stringify({ id: type }),
      },
    },
  };
}

/**
 * Get shared link for nodeId function.
 * @function getSharedLink
 * @returns {Object} Entry node.
 * @param sharedLinkId
 * */
export function getSharedLink(sharedLinkId) {
  return {
    type: ALFRESCO_GET_SHARED_LINK,
    request: {
      op: 'get',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.getSharedlinkPath(sharedLinkId),
        token: AlfrescoAuthUtil.getAccessToken(),
        params: JSON.stringify({ sharedLinkId: sharedLinkId }),
      },
    },
  };
}

/**
 * Search in alfresco by query.
 * @function alfrescoSearch
 * @param {String} query Alfresco query.
 * @param {String} language Language of alfresco query.
 * @returns {Object} Entry node.
 * */
export function alfrescoSearch(query, language = 'afts') {
  return {
    type: ALFRESCO_SEARCH,
    request: {
      op: 'post',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.searchPath(),
        token: AlfrescoAuthUtil.getAccessToken(),
        params: JSON.stringify({ query: { query, language } }),
      },
    },
  };
}

/**
 * Get shared link for nodeId function.
 * @function getSharedLink
 * @returns {Object} Entry node.
 * @param sharedLinkId
 * */
export function getSharedLinkEntry(sharedLinkId) {
  return {
    type: ALFRESCO_GET_SHARED_LINK,
    request: {
      op: 'post',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.getSharedlinkPath(sharedLinkId),
        token: AlfrescoAuthUtil.getAccessToken(),
      },
    },
  };
}

/**
 * Get children for nodeId function.
 * @function getChildren
 * @returns {Object} Entry node.
 * @param nodeId
 * */
export function getChildren(nodeId) {
  return {
    type: ALFRESCO_GET_CHILDREN,
    request: {
      op: 'get',
      path: `@alfresco`,
      params: {
        path: AlfrescoAuthUtil.getChildrenPath(nodeId),
        token: AlfrescoAuthUtil.getAccessToken(),
      },
    },
  };
}

export const clearSharedLink = () => ({
  type: ALFRESCO_CLEAR_SHARED_LINK,
});
