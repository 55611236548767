import { defineMessages } from 'react-intl';
// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fab, fas, far);

const messages = defineMessages({
  CardBlock: {
    id: 'Card UFT',
    defaultMessage: 'Card UFT',
  },
  CardLinkInternal: {
    id: 'Link Internal',
    defaultMessage: 'Link interno',
  },
  CardText: {
    id: 'Text',
    defaultMessage: 'Texto',
  },
  CardIcon: {
    id: 'Icon',
    defaultMessage: 'Ícone',
  },
  CardExternalLink: {
    id: 'External Link',
    defaultMessage: 'URL Externa',
  },
  openLinkInNewPage: {
    id: 'Open in a new page',
    defaultMessage: 'Abrir em nova janela',
  },
});

export const CardSchema = (props) => {
  const { intl } = props;

  return {
    title: props.intl.formatMessage(messages.CardBlock),
    fieldsets: [
      {
        id: 'default',
        title: 'default',
        fields: ['text', 'icon_selector', 'href', 'external_link', 'openLinkInNewPage'],
      },
    ],
    properties: {
      text: {
        title: props.intl.formatMessage(messages.CardText),
        required: true,
      },
      // icon: {
      //   title: props.intl.formatMessage(messages.CardIcon),
      //   choices: [
      //     ["fas fa-users", <FontAwesomeIcon icon="users"/>],
      //     ["fas fa-cogs", <FontAwesomeIcon icon="cogs"/>],
      //     ["fas fa-building", <FontAwesomeIcon icon="building"/>],
      //     ["fas fa-lock", <FontAwesomeIcon icon="lock"/>],
      //     ["fas fa-home", <FontAwesomeIcon icon="home"/>],
      //     ["fas fa-school", <FontAwesomeIcon icon="school"/>],
      //     ["fas fa-star", <FontAwesomeIcon icon="star"/>],
      //     ["fas fa-hands", <FontAwesomeIcon icon="hands"/>],
      //   ],
      // },
      icon_selector: {
        title: intl.formatMessage(messages.CardIcon),
        widget: 'icon_selector',
        required: false,
      },
      href: {
        title: props.intl.formatMessage(messages.CardLinkInternal),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['title', 'description', 'image_scales', 'effective'],
        // widgetOptions: {
        //   pattern_options: { selectableTypes: ['News Item', 'Document'] },
        // },
        allowExternals: true,
      },
      external_link: {
        title: props.intl.formatMessage(messages.CardExternalLink),
        description: 'A URL deve começar com http:// ou https://',
      },
      openLinkInNewPage: {
        title: intl.formatMessage(messages.openLinkInNewPage),
        type: 'boolean',
      },
    },
    required: ['text'],
  };
};
