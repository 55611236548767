import React, { useCallback, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { getNode, requestTicket } from '@package/actions/alfresco/alfresco';
import { useDispatch, useSelector } from 'react-redux';
import AlfrescoAuthUtil from '@package/util/AlfrescoAuthUtil';
import { Link } from 'react-router-dom';
import { alfrescoSearch, clearSharedLink, createRendition, createSharedLink } from '../../actions/alfresco/alfresco';
import { Dimmer, Input, Loader } from 'semantic-ui-react';
import './style.less';
import PropTypes from 'prop-types';

/**
 * Component for navigation in alfresco folders
 * @param onChangeDocument
 * @param mimeTypes
 * @param defaultPath
 * @param title
 * @param placeholder
 * @returns {JSX.Element}
 * @constructor
 */
const ExploreDocs = ({ onChangeDocument, mimeTypes, defaultPath, title, placeholder }) => {
  const alfresco = useSelector((state) => state && state.alfresco);
  const dispatch = useDispatch();
  const [history, setHistory] = useState([{ path: defaultPath, name: 'Home' }]);
  const [isSerach, setSearch] = useState(false);
  const inputSearchRef = useRef(null);

  const mountBreadcrumb = (nodeId, name) => {
    let breakCondition = false;
    const newHistory = history.reduce((acc, curr) => {
      if (breakCondition) {
        return acc;
      }
      if (curr.path === nodeId) breakCondition = true;
      return [...acc, curr];
    }, []);
    if (!breakCondition) {
      setHistory([...newHistory, { path: nodeId, name }]);
    } else {
      setHistory(newHistory);
    }
  };
  const retrieveNode = (nodeId, name) => {
    dispatch(
      getNode({
        path: AlfrescoAuthUtil.getNodePath(nodeId),
      }),
    );
    mountBreadcrumb(nodeId, name);
  };

  const checkMimeType = (entry) => {
    if (mimeTypes.length && entry.isFile) {
      return mimeTypes.includes(entry.content.mimeType);
    }
    return true;
  };

  const entryIsImage = (entry) => {
    return entry.content?.mimeType.includes('image');
  };

  const onClickPath = (entry) => {
    if (entry.isFolder) {
      retrieveNode(entry.id, entry.name);
    } else {
      entryIsImage(entry)
        ? dispatch(createRendition(entry.id)).then(() => dispatch(createSharedLink(entry.id)))
        : dispatch(createSharedLink(entry.id));
    }
  };
  const retriveHome = useCallback(() => {
    dispatch(
      getNode({
        path: AlfrescoAuthUtil.getNodePath(defaultPath),
      }),
    );
    setHistory([{ path: defaultPath, name: 'Home' }]);
    setSearch(false);
    inputSearchRef.current.inputRef.current.value = '';
  }, [defaultPath, dispatch]);

  const onSearch = useCallback(() => {
    setSearch(true);
    dispatch(
      alfrescoSearch(AlfrescoAuthUtil.getQueryContent(inputSearchRef.current.inputRef.current.value, mimeTypes)),
    );
  }, [dispatch, mimeTypes]);

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearch();
    }
  };
  useEffect(() => {
    if (alfresco?.token) {
      retriveHome();
    }
  }, [alfresco?.token, retriveHome]);

  useEffect(() => {
    if (alfresco.sharedLink?.entry) {
      const entry = alfresco.sharedLink.entry;

      if (alfresco.rendition) {
        const url = AlfrescoAuthUtil.getPublicSharedlinkRendition(entry.id, entry.name?.split('/').pop());
        const href = AlfrescoAuthUtil.getPublicSharedlink(entry.id, entry.name?.split('/').pop());
        onChangeDocument({
          url,
          href,
          openLinkInNewTab: true,
        });
      } else {
        const url = AlfrescoAuthUtil.getPublicSharedlink(entry.id, entry.name?.split('/').pop());
        onChangeDocument({ url });
      }

      dispatch(clearSharedLink());
    }
  }, [alfresco.sharedLink, alfresco.sharedLink?.entry, dispatch, onChangeDocument, alfresco.rendition]);

  useLayoutEffect(() => {
    dispatch(requestTicket());
  }, [dispatch]);

  return (
    <div>
      <Dimmer active={alfresco.alfresco.loading}>
        <Loader>Carregando</Loader>
      </Dimmer>
      <div className="ui raised segments">
        <header className="header pulled">
          <div className="vertical divider"></div>
          <h2>{title}</h2>
        </header>
        <div className="ui secondary segment search">
          <Input
            ref={inputSearchRef}
            placeholder={placeholder}
            onKeyDown={keyDownHandler}
            icon={{
              name: 'search',
              circular: true,
              link: true,
              size: 'large',
              color: 'blue',
              onClick: onSearch,
            }}
          />

          <div hidden={!isSerach}>
            <i role={'presentation'} className={'fas fa-times search'} onClick={() => retriveHome()} />
          </div>
        </div>
        <div className="ui secondary vertical segment breadcrumbs" hidden={isSerach}>
          {history.map((item) => (
            <Link
              key={`history-${item.name}`}
              to={'#/'}
              onClick={() => retrieveNode(item.path, item.name)}
              className="section"
            >
              {item.path !== defaultPath && <span className="divider"> / </span>}
              {item.path === defaultPath ? <i className="fas fa-home"></i> : item.name}
            </Link>
          ))}
        </div>
        <ul className="ui segment object-listing">
          {alfresco?.list?.entries.map(
            ({ entry }) =>
              checkMimeType(entry) && (
                <li key={`li-${entry.id}`} role="presentation" aria-label="Procurar Images">
                  <span title={entry.name} role="presentation" onClick={() => onClickPath(entry)}>
                    <i className={entry.isFolder ? 'fas fa-folder' : 'fas fa-file'} />
                    {entry.name}
                  </span>
                </li>
              ),
          )}
        </ul>
      </div>
    </div>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
ExploreDocs.propTypes = {
  onChangeDocument: PropTypes.func.isRequired,
  mimeTypes: PropTypes.arrayOf(PropTypes.string),
  defaultPath: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};
/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ExploreDocs.defaultProps = {
  mimeTypes: [],
  defaultPath: '-my-',
  title: 'Escolha uma Imagem',
  placeholder: 'Pesquisar por nome da imagem',
};

export default ExploreDocs;
