import { defineMessages } from 'react-intl';
import { makeInlineElementPlugin } from '@plone/volto-slate/elementEditor';
import AlfrescolinkElement from './AlfrescolinkElement';
import { AlfrescolinkEditorSchema } from './schema';
import { ALFRESCOLINK } from '../constants';
import { withAlfrescolink } from './extensions';
import alfrescoSVG from '@package/icons/alfresco.svg';
import UrlWidget from './Widget/UrlWidget';
import PluginEditor from './PluginEditor';

const messages = defineMessages({
  edit: {
    id: 'Edit alfrescolink',
    defaultMessage: 'Alterar link',
  },
  delete: {
    id: 'Remove alfrescolink',
    defaultMessage: 'Remover link',
  },
});

export default function installAlfrescolinkPlugin(config) {
  const opts = {
    title: 'Alfrescolink',
    pluginId: ALFRESCOLINK,
    elementType: ALFRESCOLINK,
    element: AlfrescolinkElement,
    isInlineElement: true,
    editSchema: AlfrescolinkEditorSchema,
    extensions: [withAlfrescolink],
    hasValue: (formData) => !!formData,
    toolbarButtonIcon: alfrescoSVG,
    pluginEditor: PluginEditor,
    messages,
  };

  config.widgets.widget.alfrescoNav = UrlWidget;

  const [installEditor] = makeInlineElementPlugin(opts);
  config = installEditor(config);
  return config;
}
