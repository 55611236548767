import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { Button, FormGroup, FormField, Form, Label, ButtonGroup } from 'semantic-ui-react';
import { defineMessages, injectIntl, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { canShowFilter, dateFilters } from './filters/constant';
import { reduce } from 'lodash';
import { SearchPortalTypeRadio, SearchDateRangeRadio } from './filters';
import { PrimaryButton, SecondaryButton } from '../../../../../components/GovBr/button';
import { BodyClass } from '../../../../../../omelette/src/helpers';
import cx from 'classnames';
import './SideFilter.css';
import { compose } from 'redux';

const messages = defineMessages({
  advancedSearch: {
    id: 'Advanced Search',
    defaultMessage: 'Advanced Search',
  },
  clearFilters: {
    id: 'Clear Filters',
    defaultMessage: 'Clear Filters',
  },
});

export const ClearFilters = () => {
  const history = useHistory();
  const { search } = useLocation();
  const intl = useIntl();
  const parameters = new URLSearchParams(search);

  const pathSearch = useMemo(() => {
    return parameters.has('SearchableText') ? `?SearchableText=${parameters.get('SearchableText')}` : '';
  }, [search]);

  const clearFilters = useCallback(() => {
    history.push(`/search${pathSearch}`);
  }, [history, pathSearch]);

  if (!parameters.has('advanced_search')) {
    return <></>;
  }

  return (
    <Button name="clear" size="tiny" className="button-sort" onClick={clearFilters}>
      {intl.formatMessage({ id: 'Clear Filters', defaultMessage: 'Clear Filters' })}
    </Button>
  );
};

export const CountFilters = () => {
  const { search } = useLocation();
  const parametros = new URLSearchParams(search);

  if (!parametros.has('advanced_search')) {
    return <></>;
  }
  const uniqueParams = new Set();

  parametros.forEach((value, key) => {
    if (key !== 'advanced_search' && !key.startsWith('sort_')) {
      const prefix = key.split('.')[0];
      uniqueParams.add(prefix);
    }
  });
  const count = uniqueParams.size;

  return (
    <Label circular size={'mini'} color={'blue'} key={'blue'} floating>
      {count}
    </Label>
  );
};

const SidebarFilter = ({ className }) => {
  const { search } = useLocation();
  const intl = useIntl();
  const history = useHistory();
  const [searchableText, setSearchableText] = React.useState('');
  const [searchableTags, setSearchableTags] = React.useState([]);
  const [rangeDate, setRangeDate] = React.useState(null);
  const [selectedPortalType, setSelectedPortalType] = React.useState([]);
  const [errorSearch, setErrorSearch] = React.useState(null);
  const [visible, setVisible] = React.useState(false);

  const sidebarRef = React.useRef(null);
  const onToggleExpanded = () => {
    setVisible(!visible);
  };

  const resetFilters = () => {
    setErrorSearch(null);
    setRangeDate(null);
    setSelectedPortalType([]);
    setSearchableTags([]);
  };

  const clearFilters = () => {
    resetFilters();
    history.push(`/search?SearchableText=${searchableText}`);
    onToggleExpanded();
  };

  const filters = {
    SearchableText: { key: 'SearchableText', value: searchableText, set: setSearchableText },
    portal_type: { key: 'portal_type', value: selectedPortalType, set: setSelectedPortalType },
    Subject: { key: 'Subject', value: searchableTags, set: setSearchableTags },
    effective: { key: 'effective', value: rangeDate, set: setRangeDate },
  };
  const addToQueryPath = (path, terms, queryKey) => {
    if (!canShowFilter(queryKey, selectedPortalType)) {
      return path;
    }
    if (dateFilters.includes(queryKey) && terms?.length > 0) {
      const [startDate, endDate] = terms;
      return `${path}&${queryKey}.query=${startDate}&${queryKey}.query=${endDate}&${queryKey}.range=min:max`;
    }
    if (!Array.isArray(terms) && terms) {
      return `${path}&${queryKey}=${encodeURIComponent(terms)}`;
    }
    return terms ? terms?.reduce((subPath, term) => `${subPath}&${queryKey}=${encodeURIComponent(term)}`, path) : path;
  };
  const onSubmit = (event) => {
    event.preventDefault();
    if (!searchableText) {
      setErrorSearch({
        content: 'Porfavor digite um termo para ser pesquisado',
        pointing: 'below',
      });
      return;
    } else {
      setErrorSearch(null);
    }

    const path = reduce(
      filters,
      (subpath, filter) => {
        return addToQueryPath(subpath, filter.value, filter.key) ?? subpath;
      },
      'advanced_search=1',
    );
    history.push(`/search?${path}`);
    onToggleExpanded();
  };

  React.useEffect(() => {
    const parametros = new URLSearchParams(search);
    const paramsObj = {};
    parametros.forEach((value, key) => {
      if (paramsObj.hasOwnProperty(key)) {
        if (!Array.isArray(paramsObj[key])) {
          paramsObj[key] = [paramsObj[key]];
        }
        paramsObj[key].push(value);
      } else {
        paramsObj[key] = value;
      }
    });

    if (paramsObj.SearchableText) {
      setSearchableText(paramsObj.SearchableText);
    }
    if (paramsObj.portal_type) {
      const types = Array.isArray(paramsObj.portal_type) ? paramsObj.portal_type : [paramsObj.portal_type];
      setSelectedPortalType(types);
    }
    if (paramsObj.Subject) {
      const types = Array.isArray(paramsObj.Subject) ? paramsObj.Subject : [paramsObj.Subject];
      setSearchableTags(types);
    }

    if (paramsObj['effective.query']) {
      setRangeDate(paramsObj['effective.query']);
    }
  }, [search]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !(event.target?.tagName?.toLowerCase() === 'button' && event.target?.name === 'advanced_search')
      ) {
        onToggleExpanded();
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      resetFilters();
    };
  }, []);

  return (
    <>
      <ButtonGroup className={className}>
        <Button
          onClick={onToggleExpanded}
          name="advanced_search"
          size="tiny"
          className={classNames('button-sort', {
            'button-active': visible,
          })}
        >
          {intl.formatMessage(messages.advancedSearch)} <CountFilters />
        </Button>
        <ClearFilters />
      </ButtonGroup>
      {visible && (
        <Fragment>
          <BodyClass className={visible ? 'has-sidebar overlay' : 'has-sidebar-collapsed'} />

          <div
            ref={sidebarRef}
            className={cx('sidebar-container', { collapsed: !visible })}
            style={{ padding: '20px' }}
          >
            <Form onSubmit={onSubmit}>
              <FormGroup widths="equal">
                <FormField
                  label="Termo"
                  control="input"
                  value={searchableText}
                  error={errorSearch}
                  onChange={(e) => setSearchableText(e.target.value)}
                />
              </FormGroup>
              <>
                <FormGroup inline>
                  <SearchPortalTypeRadio
                    value={selectedPortalType}
                    setValue={setSelectedPortalType}
                    horizontal={false}
                  />
                </FormGroup>

                <FormGroup inline>
                  {canShowFilter('effective', selectedPortalType) && (
                    <SearchDateRangeRadio value={rangeDate} setValue={setRangeDate} horizontal={false} />
                  )}
                </FormGroup>
                <FormGroup inline>
                  <FormField width={3}>
                    <SecondaryButton
                      style={{
                        maxWidth: '150px',
                      }}
                      onClick={clearFilters}
                    >
                      Limpar
                    </SecondaryButton>
                  </FormField>
                  <FormField width={3}>
                    <PrimaryButton
                      style={{
                        maxWidth: '150px',
                      }}
                      onClick={onSubmit}
                    >
                      Pesquisar
                    </PrimaryButton>
                  </FormField>
                </FormGroup>
              </>
            </Form>
          </div>
          <div className={visible ? 'pusher expanded' : 'pusher'} />
        </Fragment>
      )}
    </>
  );
};

export default compose(injectIntl)(SidebarFilter);
