import { useTour } from '@reactour/tour';
import React, { useEffect } from 'react';

function Tour() {
  const { setIsOpen } = useTour();

  useEffect(() => {
    const hasRun = localStorage.getItem('JoyrideMenu');
    if (!hasRun) {
      setTimeout(() => {
        setIsOpen(true);
      }, 500);
    }
  }, []);
  return <></>;
}

export default Tour;
