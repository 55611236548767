import styled from 'styled-components';

import {
  hoverColor1,
  primaryColor1,
  secondaryColor2,
  primaryButtonColor,
  primaryButtonHoverColor,
  primaryButtonHoverColor1,
  primaryButtonActiveColor,
  primaryButtonActiveColor1,
  primaryButtonDisabledColor,
  secondaryButtonColor,
  secondaryButtonHoverColor,
  secondaryButtonHoverColor1,
  secondaryButtonActiveColor,
  secondaryButtonActiveColor1,
} from '@package/constants/styleSettings';

export const StyledButton = styled.button`
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const StyledCircleButton = styled(StyledButton)`
  border: none;
  border-radius: 50%;
  background-color: ${secondaryColor2};
  padding: 0;
  color: ${primaryColor1};
  width: ${({ size }) => size || '32px'};
  height: ${({ size }) => size || '32px'};
  :hover {
    background-color: ${hoverColor1};
  }
  .large {
    margin-left: 1.5px;
    margin-bottom: 2.5px;
  }
`;

const RoundEdgesButton = styled(StyledButton)`
  border-radius: 100em;
  font-size: 28px;
  font-weight: 400;
  height: ${(props) => (props.height ? props.height : '64px')};
  width: ${(props) => (props.width ? props.width : '400px')};
  padding: 0 24px;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
`;

export const StyledPrimaryButton = styled(RoundEdgesButton)`
  background-color: ${primaryButtonColor} !important;
  border: 1px solid transparent !important;
  color: ${secondaryButtonColor} !important;
  height: 39px;
  font-size: 16.8px;
  font-weight: 600;
  :hover {
    background-color: ${primaryButtonHoverColor} !important;
  }
  :active {
    background-color: ${primaryButtonActiveColor} !important;
  }

  ${({ brighter }) =>
    brighter &&
    `
    background-color: ${primaryColor1}  !important;
    box-shadow: none;
    :hover {
      background-color: ${primaryButtonHoverColor1} !important
    }
    :active {
      background-color: ${primaryButtonActiveColor1} !important
    }
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
      :hover {
        background-color: ${primaryColor1} !important
      }
      :active {
        background-color: ${primaryColor1} !important
      }
    }
  `}

  ${({ blocked }) =>
    blocked &&
    `
    background-color: ${primaryButtonDisabledColor} !important;
    :disabled {
      opacity: 1.0;
      cursor: not-allowed;
      :hover {
        background-color: ${primaryButtonDisabledColor} !important
      }
      :active {
        background-color: ${primaryButtonDisabledColor} !important
      }
    }
  `}
`;

export const StyledSecondaryButton = styled(RoundEdgesButton)`
  background-color: ${secondaryButtonColor};
  border: 1px solid ${primaryButtonHoverColor};
  color: ${primaryButtonHoverColor};
  height: 39px;
  font-size: 16.8px;
  font-weight: 600;
  :hover {
    background-color: ${secondaryButtonHoverColor};
  }
  :active {
    background-color: ${secondaryButtonActiveColor};
  }
  ${({ brighter }) =>
    brighter &&
    `
    border: 1px solid ${primaryColor1};
    color: ${primaryColor1};
    box-shadow: none;
    :hover {
      background-color: ${secondaryButtonHoverColor1}
    }
    :active {
      background-color: ${secondaryButtonActiveColor1}
    }
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
      :hover {
        background-color: ${secondaryButtonColor}
      }
      :active {
        background-color: ${secondaryButtonColor}
      }
    }
  `}
  ${({ unbordered }) =>
    unbordered &&
    `
    border: none;
  `}
`;

export const StyledDownloadLinkButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #155bcb;
  cursor: pointer;
  font: inherit;
  vertical-align: baseline;
`;
