/**
 * ChoiceMapWidget component.
 * @module components/Widgets/ChoiceMapWidget
 */

import React from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import { compose } from 'redux';
import SelectWidget from '@plone/volto/components/manage/Widgets/SelectWidget';

/**
 * ChoiceMapWidget component class.
 * @function ChoiceMapWidget
 * @returns {React.JSX.Element} Markup of the component.
 */
const ChoiceMapWidget = (props) => {
  const map = props.map;
  const changeHiddenFields = props?.changeHiddenFields;

  function fieldsToHidden(value) {
    let hiddenFields = {};
    for (const [choice, fields] of Object.entries(map)) {
      const fieldValue = choice !== value;
      fields.forEach((field) => {
        hiddenFields[field] = fieldValue;
      });
    }
    return hiddenFields;
  }

  React.useEffect(() => {
    changeHiddenFields && changeHiddenFields(fieldsToHidden(props.value?.token || props.value));
  }, [props.value]);

  return <SelectWidget {...props} />;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ChoiceMapWidget.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  map: PropTypes.shape(),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  changeHiddenFields: PropTypes.func,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
ChoiceMapWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: '',
  map: {},
  onChange: null,
  onEdit: null,
  onDelete: null,
  changeHiddenFields: null,
};

export default compose(injectIntl)(ChoiceMapWidget);
