import externalSVG from '@plone/volto/icons/link.svg';
import internalSVG from '@plone/volto/icons/nav.svg';
import emailSVG from '@plone/volto/icons/email.svg';

export const EmailLinkSchema = {
  title: 'Endereço de email',
  fieldsets: [
    {
      id: 'email',
      title: 'Email',
      fields: ['email_address', 'email_subject'],
    },
  ],
  properties: {
    email_address: {
      title: 'Endereço de email',
    },
    email_subject: {
      title: 'Assunto do email',
      description: 'Opcional',
    },
  },
  required: [],
};

export const InternalLinkSchema = {
  title: 'Link interno',
  fieldsets: [
    {
      id: 'internal',
      title: 'Internal',
      fields: ['internal_link', 'openLinkInNewPage'],
    },
  ],
  properties: {
    internal_link: {
      widget: 'object_browser',
      title: 'Link interno',
      multiple: false,
      mode: 'link',
      selectedItemAttrs: [],
    },
    openLinkInNewPage: {
      title: 'Abrir em nova janela',
      type: 'boolean',
    },
  },
  required: [],
};

export const ExternalLinkSchema = {
  title: 'Link Externo',
  fieldsets: [
    {
      id: 'external',
      title: 'External',
      fields: ['external_link', 'target'],
    },
  ],
  properties: {
    external_link: {
      title: 'URL Externa',
      description: 'A URL pode ser relativa a este site ou absoluta se começar com http:// ou https://',
    },
    target: {
      title: 'Destino',
      choices: [
        ['_self', 'Abrir nesta janela'],
        ['_blank', 'Abrir em nova janela'],
        ['_parent', 'Abrir na janela pai'],
        ['_top', 'Abrir na janela superior'],
      ],
    },
  },
  required: [],
};

const FullLinkEditSchema = {
  title: 'Inserir link',
  fieldsets: [
    {
      id: 'default',
      title: 'Link interno',
      fields: ['link', 'title'],
    },
  ],
  properties: {
    title: {
      title: 'Título do Link',
    },
    link: {
      title: 'Link',
      widget: 'object_by_type',
      schemas: [
        {
          id: 'internal',
          icon: internalSVG,
          schema: InternalLinkSchema,
        },
        {
          id: 'external',
          icon: externalSVG,
          schema: ExternalLinkSchema,
        },
        {
          id: 'email',
          icon: emailSVG,
          schema: EmailLinkSchema,
        },
      ],
    },
  },
  required: [],
};

export default FullLinkEditSchema;
