import React from 'react';
import { withBlockExtensions } from '@plone/volto/helpers';
import { v4 as uuid } from 'uuid';
import View from './View';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';

export const ServicesContext = React.createContext({
  serviceIndex: 0,
  setServiceIndex: () => {},
});

const ServiceEdit = (props) => {
  const { onChangeBlock, block, selected } = props;
  const data = {
    services: [{ '@id': uuid() }],
    ...props.data,
  };
  if (!props.data.services) {
    onChangeBlock(block, data);
  }
  const [serviceIndex, setServiceIndex] = React.useState(0);

  const contextValue = React.useMemo(() => ({ serviceIndex, setServiceIndex }), [serviceIndex]);

  return (
    <ServicesContext.Provider value={contextValue}>
      <View {...props} isEditMode serviceIndex={serviceIndex} setServiceIndex={setServiceIndex} />
      <SidebarPortal selected={selected}>
        <Sidebar {...props} data={data} block={block} onChangeBlock={onChangeBlock} />
      </SidebarPortal>
    </ServicesContext.Provider>
  );
};

export default withBlockExtensions(ServiceEdit);
