import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Accordion, Grid, Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { CheckboxWidget, Icon, FormFieldWrapper, TextWidget } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import AlignBlock from '@plone/volto/components/manage/Sidebar/AlignBlock';

import clearSVG from '@plone/volto/icons/clear.svg';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import ImageSizeWidget from './ImageSizeWidget';
import ExploreDocs from '../../ExploreDocs';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  AltTextHint: {
    id: 'Alt text hint',
    defaultMessage: 'Leave empty if the image is purely decorative.',
  },
  AltTextHintLinkText: {
    id: 'Alt text hint link text',
    defaultMessage: 'Describe the purpose of the image.',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Size',
  },
  linkSettings: {
    id: 'Link settings',
    defaultMessage: 'Link settings',
  },
  LegendText: {
    id: 'Legend',
    defaultMessage: 'Legend',
  },
});

const AlfrescoImageSidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  resetSubmitUrl,
  clearSharedLink,
  intl,
}) => {
  const [activeAccIndex, setActiveAccIndex] = useState(0);

  function handleAccClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeAccIndex === index ? -1 : index;

    setActiveAccIndex(newIndex);
  }

  const onChangeImage = ({ url = data.url, href = data.href, openLinkInNewTab = data.openLinkInNewTab }) => {
    onChangeBlock(block, {
      ...data,
      url,
      href,
      openLinkInNewTab,
    });
  };

  return (
    <Segment.Group raised>
      {!data.url && (
        <ExploreDocs
          onChangeDocument={onChangeImage}
          mimeTypes={['image/jpeg', 'image/png', 'image/gif']}
          defaultPath={'d74ba8e9-4ee1-4d03-b9e7-4b8f0f571886'}
        />
      )}
      {data.url && (
        <>
          <header className="header pulled">
            <h2>
              <FormattedMessage id="Image" defaultMessage="Image" />
            </h2>
          </header>
          <Segment className="sidebar-metadata-container" secondary>
            {data.url.split('/').slice(-1)[0]}
            <img src={data.url} alt={data.alt} style={{ width: '50%' }} />
          </Segment>
          <Segment className="form sidebar-image-data">
            <TextWidget
              id="external"
              title={intl.formatMessage(messages.externalURL)}
              required={false}
              value={data.url}
              icon={clearSVG}
              iconAction={() => {
                resetSubmitUrl();

                onChangeBlock(block, {
                  ...data,
                  url: '',
                  href: '',
                });

                clearSharedLink();
              }}
              onChange={() => {}}
            />
            <TextWidget
              id="legend"
              title={intl.formatMessage(messages.LegendText)}
              required={false}
              value={data.legend || ''}
              icon={data.legend ? clearSVG : null}
              iconAction={() =>
                onChangeBlock(block, {
                  ...data,
                  legend: '',
                })
              }
              onChange={(_name, value) => {
                onChangeBlock(block, {
                  ...data,
                  legend: value,
                });
              }}
            />
            <TextWidget
              id="alt"
              title={intl.formatMessage(messages.AltText)}
              description={
                <>
                  <a
                    href="https://www.w3.org/WAI/tutorials/images/decision-tree/"
                    title={intl.formatMessage(messages.openLinkInNewTab)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage(messages.AltTextHintLinkText)}
                  </a>{' '}
                  {intl.formatMessage(messages.AltTextHint)}
                </>
              }
              required={false}
              value={data.alt || ''}
              icon={data.alt ? clearSVG : null}
              iconAction={() =>
                onChangeBlock(block, {
                  ...data,
                  alt: '',
                })
              }
              onChange={(_name, value) => {
                onChangeBlock(block, {
                  ...data,
                  alt: value,
                });
              }}
            />
            <Form.Field inline required={required}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="4">
                    <div className="wrapper">
                      <label htmlFor="field-align">
                        <FormattedMessage id="Alignment" defaultMessage="Alignment" />
                      </label>
                    </div>
                  </Grid.Column>
                  <Grid.Column width="8" className="align-tools">
                    <AlignBlock
                      align={data.align}
                      onChangeBlock={(block, data) => {
                        onChangeBlock(block, {
                          ...data,
                          size: data.size,
                        });
                      }}
                      data={data}
                      block={block}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Field>
            <FormFieldWrapper id="image_size" title={intl.formatMessage(messages.size)}>
              <ImageSizeWidget onChangeBlock={onChangeBlock} data={data} block={block} />
            </FormFieldWrapper>
          </Segment>
          <Accordion fluid styled className="form">
            <Accordion.Title active={activeAccIndex === 0} index={0} onClick={handleAccClick}>
              {intl.formatMessage(messages.linkSettings)}
              {activeAccIndex === 0 ? <Icon name={upSVG} size="20px" /> : <Icon name={downSVG} size="20px" />}
            </Accordion.Title>
            <Accordion.Content active={activeAccIndex === 0} style={{ margin: '0 0 0 20px' }}>
              <TextWidget
                id="link"
                title={intl.formatMessage(messages.LinkTo)}
                required={false}
                value={flattenToAppURL(data.href)}
                icon={data.href ? clearSVG : navTreeSVG}
                iconAction={
                  data.href
                    ? () => {
                        onChangeBlock(block, {
                          ...data,
                          href: '',
                        });
                      }
                    : () => openObjectBrowser({ mode: 'link' })
                }
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    href: flattenToAppURL(value),
                  });
                }}
              />
              <CheckboxWidget
                id="openLinkInNewTab"
                title={intl.formatMessage(messages.openLinkInNewTab)}
                value={data.openLinkInNewTab ? data.openLinkInNewTab : false}
                onChange={(name, value) => {
                  onChangeBlock(block, {
                    ...data,
                    openLinkInNewTab: value,
                  });
                }}
              />
            </Accordion.Content>
          </Accordion>
        </>
      )}
    </Segment.Group>
  );
};

AlfrescoImageSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
  resetSubmitUrl: PropTypes.func.isRequired,
  clearSharedLink: PropTypes.func.isRequired,
};

export default injectIntl(AlfrescoImageSidebar);
